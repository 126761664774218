/* eslint-disable cleo/no-deep-imports */
import gql from "graphql-tag";

import * as Types from "../../../declarations/graphql/types";

export type DTOQuestionFragment = {
  __typename?: "Question";
  id: string;
  text: string;
  answers?: Array<{ __typename?: "Answer"; id: string; text: string }> | null;
};

export const QuestionFragmentDoc = gql`
  fragment QuestionFragment on Question {
    id
    text
    answers {
      id
      text
    }
  }
`;
