import captureException from "~/utils/logging/captureException";

import { COMPONENT_BY_DIALOG } from "./componentByDialog";
import { useDialogsContext } from "./DialogsContext";

const DialogsManager = (): JSX.Element | null => {
  const { currentDialog, setCurrentDialog } = useDialogsContext();

  if (currentDialog === null) {
    return null;
  }

  const { options } = currentDialog;

  const handleClose = (): void => {
    options?.onClose?.();
    setCurrentDialog(null);
  };

  const DialogComponent = COMPONENT_BY_DIALOG[currentDialog.dialog];

  if (!DialogComponent) {
    captureException(
      `There is no dialog component for ${currentDialog.dialog}`
    );

    return null;
  }

  return (
    <DialogComponent
      open
      onClose={handleClose}
      // To provide options uniformly
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      {...(currentDialog.options as any)}
    />
  );
};

export default DialogsManager;
