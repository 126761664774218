/* eslint-disable @typescript-eslint/no-explicit-any */
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Upload: any;
};

export type DTOAccessToken = {
  __typename?: "AccessToken";
  expiresAt: Scalars["String"];
  grants: Array<Scalars["String"]>;
  value: Scalars["String"];
};

export type DTOActionToken = {
  __typename?: "ActionToken";
  value: Scalars["String"];
};

export type DTOAdvertisement = {
  __typename?: "Advertisement";
  attachImageCount: Scalars["Int"];
  attachVideoCount: Scalars["Int"];
  budget?: Maybe<DTOMoney>;
  createdAt: Scalars["Int"];
  endAt?: Maybe<Scalars["Int"]>;
  files: Array<DTOAttachedFile>;
  id: Scalars["String"];
  isCreator: Scalars["Boolean"];
  myReward?: Maybe<DTOReward>;
  orientation: DTOPostOrientation;
  owner: DTOPerson;
  publishedAt?: Maybe<Scalars["Int"]>;
  questions?: Maybe<Array<DTOQuestion>>;
  reward?: Maybe<DTOPostReward>;
  rewardCount?: Maybe<Scalars["Int"]>;
  rewardType?: Maybe<DTORewardType>;
  richText: DTORichText;
  stats?: Maybe<DTOPostStats>;
  status: DTOPostStatus;
  surveyReward?: Maybe<DTOPostReward>;
};

export type DTOAdvertisementFilesArgs = {
  size?: InputMaybe<Scalars["Int"]>;
};

export type DTOAdvertisementEdge = {
  __typename?: "AdvertisementEdge";
  cursor: Scalars["String"];
  node: DTOAdvertisement;
};

export type DTOAdvertisementList = {
  __typename?: "AdvertisementList";
  edges: Array<DTOAdvertisementEdge>;
  pageInfo: DTOPageInfo;
  totalCount: Scalars["Int"];
};

export type DTOAnswer = {
  __typename?: "Answer";
  id: Scalars["String"];
  questionId: Scalars["String"];
  text: Scalars["String"];
};

export type DTOAnswerRequest = {
  answerId: Scalars["String"];
  questionId: Scalars["String"];
};

export enum DTOAttachTarget {
  Conversation = "conversation",
  Post = "post"
}

export type DTOAttachedFile =
  | DTOAttachmentImage
  | DTOAttachmentPdf
  | DTOAttachmentVideo;

export type DTOAttachmentImage = {
  __typename?: "AttachmentImage";
  blurhash: Scalars["String"];
  fileSize: Scalars["Int"];
  id: Scalars["String"];
  sizes: DTOSizes;
  thumbnails: DTOAttachmentImageThumbnails;
  url: Scalars["String"];
};

export type DTOAttachmentImageThumbnail = {
  __typename?: "AttachmentImageThumbnail";
  sizes: DTOSizes;
  url: Scalars["String"];
};

export type DTOAttachmentImageThumbnails = {
  __typename?: "AttachmentImageThumbnails";
  b960: DTOAttachmentImageThumbnail;
  f1920: DTOAttachmentImageThumbnail;
  m600: DTOAttachmentImageThumbnail;
  s295: DTOAttachmentImageThumbnail;
  w200h200: DTOAttachmentImageThumbnail;
  w300h300: DTOAttachmentImageThumbnail;
  w400h400: DTOAttachmentImageThumbnail;
  w500h500: DTOAttachmentImageThumbnail;
  w600h600: DTOAttachmentImageThumbnail;
  w1200: DTOAttachmentImageThumbnail;
};

export type DTOAttachmentPdf = {
  __typename?: "AttachmentPdf";
  cover?: Maybe<DTOAttachmentImage>;
  fileSize: Scalars["Int"];
  id: Scalars["String"];
  publicUrl: Scalars["String"];
};

export type DTOAttachmentVideo = {
  __typename?: "AttachmentVideo";
  audioTrack?: Maybe<DTOAudioTrack>;
  conversions: Array<DTOVideoConversion>;
  cover?: Maybe<DTOAttachmentImage>;
  duration?: Maybe<Scalars["Int"]>;
  fileSize: Scalars["Int"];
  height: Scalars["Int"];
  id: Scalars["String"];
  /** @deprecated We don't use it */
  name?: Maybe<Scalars["String"]>;
  previewUrl?: Maybe<Scalars["String"]>;
  publicUrl: Scalars["String"];
  /** @deprecated Use cover */
  screenshots?: Maybe<Array<Scalars["String"]>>;
  /** @deprecated We don't use it */
  title?: Maybe<Scalars["String"]>;
  videoId: Scalars["String"];
  width: Scalars["Int"];
};

export type DTOAudioTrack = {
  __typename?: "AudioTrack";
  id: Scalars["String"];
  name: Scalars["String"];
  url: Scalars["String"];
};

export type DTOAuthPasswordResetRequest = {
  newPassword: Scalars["String"];
  oldPassword: Scalars["String"];
};

export type DTOAvatar = {
  __typename?: "Avatar";
  big: Scalars["String"];
  big2x: Scalars["String"];
  blurhash: Scalars["String"];
  little: Scalars["String"];
  little2x: Scalars["String"];
  middle: Scalars["String"];
  middle2x: Scalars["String"];
};

export type DTOBackground = {
  __typename?: "Background";
  big: Scalars["String"];
  big2x: Scalars["String"];
  blurhash: Scalars["String"];
  little: Scalars["String"];
  little2x: Scalars["String"];
  middle: Scalars["String"];
  middle2x: Scalars["String"];
  settings: DTOBackgroundSettings;
  url: Scalars["String"];
};

export type DTOBackgroundSettings = {
  __typename?: "BackgroundSettings";
  offsetY: Scalars["Float"];
  zoom: Scalars["Float"];
};

export type DTOBoughtNft = {
  __typename?: "BoughtNft";
  id: Scalars["String"];
  nft: DTONft;
};

export type DTOBoughtNftStats = {
  __typename?: "BoughtNftStats";
  count: Scalars["Int"];
  nft: DTONft;
  /** @deprecated use count */
  processedCount: Scalars["Int"];
};

export type DTOBoughtNftStatsEdge = {
  __typename?: "BoughtNftStatsEdge";
  cursor: Scalars["String"];
  node: DTOBoughtNftStats;
};

export type DTOBoughtNftStatsList = {
  __typename?: "BoughtNftStatsList";
  edges: Array<DTOBoughtNftStatsEdge>;
  pageInfo: DTOPageInfo;
  totalCount: Scalars["Int"];
};

export enum DTOCacheControlScope {
  Private = "PRIVATE",
  Public = "PUBLIC"
}

export type DTOCheckPasswordStrengthResponse = {
  __typename?: "CheckPasswordStrengthResponse";
  status: DTOPasswordStrength;
};

export type DTOCheckRegistrationCodeResponse = {
  __typename?: "CheckRegistrationCodeResponse";
  status: DTOCheckRegistrationCodeStatus;
};

export enum DTOCheckRegistrationCodeStatus {
  Allow = "allow",
  NotActive = "notActive",
  NotFound = "notFound",
  Used = "used"
}

export enum DTOCheckingResponse {
  Allow = "allow",
  Deny = "deny"
}

export type DTOClipFrames = {
  length?: InputMaybe<Scalars["Int"]>;
  start?: InputMaybe<Scalars["Int"]>;
};

export type DTOCollection = {
  __typename?: "Collection";
  background?: Maybe<DTOAttachmentImage>;
  banner?: Maybe<DTOAttachmentImage>;
  categories: Array<DTOCollectionCategory>;
  concernMe: DTOCollectionConcernMe;
  createdAt: Scalars["Int"];
  description: DTORichText;
  featured: DTOAttachmentImage;
  id: Scalars["Int"];
  image: DTOAttachmentImage;
  itemsCount: Scalars["Int"];
  logo: DTOAttachmentImage;
  minPriceNft?: Maybe<DTONft>;
  name: Scalars["String"];
  publishAt?: Maybe<Scalars["Int"]>;
  shortDescription: DTORichText;
  status: DTOCollectionStatus;
  system: Scalars["Boolean"];
  tradable?: Maybe<DTOCollectionTradable>;
};

export enum DTOCollectionCategory {
  AffordableAndCleanEnergy = "affordableAndCleanEnergy",
  CleanWaterAndSanitation = "cleanWaterAndSanitation",
  ClimateAction = "climateAction",
  DecentWorkAndEconomicGrowth = "decentWorkAndEconomicGrowth",
  GenderEquality = "genderEquality",
  GoodHealthAndWellBeing = "goodHealthAndWellBeing",
  IndustryInnovationAndInfrastructure = "industryInnovationAndInfrastructure",
  LifeBelowWater = "lifeBelowWater",
  LifeOnLand = "lifeOnLand",
  NoPoverty = "noPoverty",
  PartnershipForTheGoals = "partnershipForTheGoals",
  PeaceJusticeAndStrongInstitutions = "peaceJusticeAndStrongInstitutions",
  QualityEducation = "qualityEducation",
  ReducedInequalities = "reducedInequalities",
  ResponsibleConsumptionAndProduction = "responsibleConsumptionAndProduction",
  SustainableCitiesAndEconomies = "sustainableCitiesAndEconomies",
  ZeroHunger = "zeroHunger"
}

export type DTOCollectionCommercialRequest = {
  shares: Array<DTOShareRequest>;
};

export type DTOCollectionConcernMe = {
  __typename?: "CollectionConcernMe";
  isCreator: Scalars["Boolean"];
};

export type DTOCollectionEdge = {
  __typename?: "CollectionEdge";
  cursor: Scalars["String"];
  node: DTOCollection;
};

export type DTOCollectionFilter = {
  type: DTOCollectionTypeFilter;
};

export type DTOCollectionList = {
  __typename?: "CollectionList";
  edges: Array<DTOCollectionEdge>;
  pageInfo: DTOPageInfo;
  totalCount: Scalars["Int"];
};

export enum DTOCollectionStatus {
  Draft = "draft",
  Published = "published"
}

export type DTOCollectionTradable = {
  __typename?: "CollectionTradable";
  shares: Array<DTOShare>;
};

export enum DTOCollectionTypeFilter {
  All = "all",
  Other = "other",
  Own = "own"
}

export type DTOCompletedAnswer = {
  __typename?: "CompletedAnswer";
  id: Scalars["String"];
  questionId: Scalars["String"];
  text: Scalars["String"];
};

export enum DTOContentOrientation {
  Horizontal = "horizontal",
  Square = "square",
  Vertical = "vertical"
}

export type DTOCreateCollectionRequest = {
  bannerImage?: InputMaybe<Scalars["Upload"]>;
  categories: Array<DTOCollectionCategory>;
  description: Scalars["String"];
  featuredImage: Scalars["Upload"];
  logoImage: Scalars["Upload"];
  name: Scalars["String"];
  publishAt?: InputMaybe<Scalars["Int"]>;
  shortDescription: Scalars["String"];
  tradable?: InputMaybe<DTOCollectionCommercialRequest>;
};

export type DTOCreateNftRequest = {
  asset: DTONftAssetRequest;
  collectionId: Scalars["Int"];
  cost: Scalars["Float"];
  description: Scalars["String"];
  featuredImage: Scalars["Upload"];
  name: Scalars["String"];
  properties?: InputMaybe<Array<DTONftPropertyRequest>>;
  quantity?: InputMaybe<Scalars["Int"]>;
  shortDescription: Scalars["String"];
};

export enum DTOCurrency {
  Cleo = "cleo",
  Usd = "usd"
}

export type DTOEmailNotificationSettings = {
  __typename?: "EmailNotificationSettings";
  announcement: DTONotificationSingleSwitcher;
};

export type DTOEventPostPublished = {
  __typename?: "EventPostPublished";
  advId: Scalars["String"];
  video?: Maybe<DTOAttachmentVideo>;
  videos?: Maybe<Array<DTOAttachmentVideo>>;
};

export type DTOGenerateNicknameRequest = {
  email: Scalars["String"];
  firstName?: InputMaybe<Scalars["String"]>;
  lastName?: InputMaybe<Scalars["String"]>;
};

export type DTOHashtagPart = {
  __typename?: "HashtagPart";
  hashtag: Scalars["String"];
  text: Scalars["String"];
};

export type DTOHello = {
  __typename?: "Hello";
  name?: Maybe<Scalars["String"]>;
};

export type DTOImportRewardRequest = {
  advId: Scalars["String"];
  approve?: InputMaybe<Scalars["Boolean"]>;
  email: Scalars["String"];
  name: Scalars["String"];
  nftId?: InputMaybe<Scalars["Int"]>;
};

export type DTOLoginRequest = {
  credential: Scalars["String"];
  identity: Scalars["String"];
};

export type DTOMentionPart = {
  __typename?: "MentionPart";
  id: Scalars["String"];
  nickname: Scalars["String"];
  text: Scalars["String"];
};

export type DTOMoney = {
  __typename?: "Money";
  cleoValue: Scalars["Float"];
  fiatValue: Scalars["Float"];
  rateUpdatedAt: Scalars["Int"];
};

export type DTOMultiBalance = {
  __typename?: "MultiBalance";
  cleo: Scalars["Float"];
  usd: Scalars["Float"];
};

export type DTOMutation = {
  __typename?: "Mutation";
  approveReward: DTORewardRequest;
  attachCoverForVideo: DTOAttachmentImage;
  attachImageToAdvertisement: DTOAttachmentImage;
  attachShortVideoToAdvertisement: DTOAttachmentVideo;
  buyNFT: DTOBoughtNft;
  changeEmailWithVerification: DTOPerson;
  claimReward: DTOAdvertisement;
  collectionIsDeployed: DTOSuccessResponse;
  completeAdvertisement: DTOAdvertisement;
  createCollection: DTOCollection;
  /** Create a draft for the short video */
  createDraft?: Maybe<DTOAdvertisement>;
  createNft: DTONft;
  deleteAdvertisement: DTOAdvertisement;
  /** Delete current user */
  deleteMe: Scalars["Boolean"];
  depositAddressIsCreated: DTOSuccessResponse;
  generateRewards: Array<DTOWaitingReward>;
  giveWaitingReward: DTOWaitingReward;
  login: DTOTokenResponse;
  logout?: Maybe<Scalars["Boolean"]>;
  nftIsMinted: DTOSuccessResponse;
  orderAttachments: Scalars["Boolean"];
  ping: Scalars["String"];
  /** Create a draft for the short video */
  publishDraft?: Maybe<DTOAdvertisement>;
  refreshToken: DTOTokenResponse;
  registerSocial: DTORegistrationSocialResponse;
  registerWithVerification: DTOPerson;
  /** Remove an image or a video. Use an id of an attachment to remove it */
  removeAttachment?: Maybe<Scalars["Boolean"]>;
  removeAvatar: DTOPerson;
  /** When authenticated user resets own password */
  resetPassword: Scalars["Boolean"];
  /** Second step. When you already has confirmed you email address via reset link clicking */
  resetPasswordWithEmail: Scalars["Boolean"];
  /** Send 6 digits to email. If email is empty - it is sent to current user email */
  sendVerificationCode: DTOVerificationCode;
  setThemeSettings: Scalars["Boolean"];
  /** Send auth code to the server to login or sign up */
  socialLogin: DTOSocialLoginResponse;
  startImageUploading: DTOUploadImageMetaInfo;
  startVideoUploading: DTOUploadMetaInfo;
  updateAdvertisement: DTOAdvertisement;
  updatePerson: DTOPerson;
  /** Checks the 6-digit code sent to the email */
  verifyCode: DTOActionToken;
};

export type DTOMutationApproveRewardArgs = {
  rewardId: Scalars["String"];
  survey?: InputMaybe<Scalars["Boolean"]>;
};

export type DTOMutationAttachCoverForVideoArgs = {
  advId: Scalars["String"];
  imageId: Scalars["String"];
  videoId: Scalars["String"];
};

export type DTOMutationAttachImageToAdvertisementArgs = {
  advId: Scalars["String"];
  imageId: Scalars["String"];
};

export type DTOMutationAttachShortVideoToAdvertisementArgs = {
  advId: Scalars["String"];
  videoId: Scalars["String"];
};

export type DTOMutationBuyNftArgs = {
  count: Scalars["Int"];
  nftID: Scalars["Int"];
};

export type DTOMutationChangeEmailWithVerificationArgs = {
  actionToken: Scalars["String"];
  email: Scalars["String"];
};

export type DTOMutationClaimRewardArgs = {
  advId: Scalars["String"];
  answers?: InputMaybe<Array<DTOAnswerRequest>>;
};

export type DTOMutationCollectionIsDeployedArgs = {
  address: Scalars["String"];
  deployId: Scalars["Int"];
  signature: Scalars["String"];
};

export type DTOMutationCompleteAdvertisementArgs = {
  advId: Scalars["String"];
};

export type DTOMutationCreateCollectionArgs = {
  request: DTOCreateCollectionRequest;
};

export type DTOMutationCreateNftArgs = {
  request: DTOCreateNftRequest;
};

export type DTOMutationDeleteAdvertisementArgs = {
  id: Scalars["String"];
};

export type DTOMutationDeleteMeArgs = {
  actionToken?: InputMaybe<Scalars["String"]>;
};

export type DTOMutationDepositAddressIsCreatedArgs = {
  address: Scalars["String"];
  chain: Scalars["String"];
  createdAt: Scalars["Int"];
  relayId: Scalars["Int"];
  signature: Scalars["String"];
};

export type DTOMutationGenerateRewardsArgs = {
  rewardsForImport: Array<DTOImportRewardRequest>;
};

export type DTOMutationGiveWaitingRewardArgs = {
  advId: Scalars["String"];
  email: Scalars["String"];
};

export type DTOMutationLoginArgs = {
  request?: InputMaybe<DTOLoginRequest>;
};

export type DTOMutationNftIsMintedArgs = {
  nftMintId: Scalars["Int"];
  signature: Scalars["String"];
};

export type DTOMutationOrderAttachmentsArgs = {
  orderedIds: Array<Scalars["String"]>;
  postId: Scalars["String"];
};

export type DTOMutationPublishDraftArgs = {
  id: Scalars["String"];
};

export type DTOMutationRefreshTokenArgs = {
  refreshToken?: InputMaybe<Scalars["String"]>;
};

export type DTOMutationRegisterSocialArgs = {
  actionToken?: InputMaybe<Scalars["String"]>;
  request: DTORegistrationSocialRequest;
};

export type DTOMutationRegisterWithVerificationArgs = {
  actionToken: Scalars["String"];
  referralNickname?: InputMaybe<Scalars["String"]>;
  registrationCode?: InputMaybe<Scalars["String"]>;
  request: DTORegistrationRequest;
};

export type DTOMutationRemoveAttachmentArgs = {
  advId: Scalars["String"];
  attachmentId: Scalars["String"];
};

export type DTOMutationResetPasswordArgs = {
  request: DTOAuthPasswordResetRequest;
};

export type DTOMutationResetPasswordWithEmailArgs = {
  request: DTONonAuthPasswordResetWithEmailRequest;
};

export type DTOMutationSendVerificationCodeArgs = {
  action: DTOVerificationAction;
  email?: InputMaybe<Scalars["String"]>;
  password?: InputMaybe<Scalars["String"]>;
};

export type DTOMutationSetThemeSettingsArgs = {
  theme: DTOTheme;
};

export type DTOMutationSocialLoginArgs = {
  code: Scalars["String"];
  data?: InputMaybe<DTOSocialLoginData>;
  platform?: InputMaybe<DTOPlatform>;
  type: DTOSocialType;
};

export type DTOMutationStartImageUploadingArgs = {
  request: DTOStartImageUploadingRequest;
};

export type DTOMutationStartVideoUploadingArgs = {
  request: DTOStartVideoUploadingRequest;
};

export type DTOMutationUpdateAdvertisementArgs = {
  request: DTOUpdateAdvertisementRequest;
};

export type DTOMutationUpdatePersonArgs = {
  request: DTOUpdatePersonRequest;
};

export type DTOMutationVerifyCodeArgs = {
  action: DTOVerificationAction;
  code: DTOVerificationCodeInput;
};

export type DTOMyNftsFilter = {
  type: DTOMyNftsTypeFilter;
};

export enum DTOMyNftsTypeFilter {
  All = "all",
  Cleo = "cleo",
  Other = "other"
}

export type DTOMyPostsEvent = {
  __typename?: "MyPostsEvent";
  events: Array<DTOMyPostsEvents>;
  offset: Scalars["String"];
};

export type DTOMyPostsEvents = DTOEventPostPublished;

export type DTOMyWaitingReward = {
  __typename?: "MyWaitingReward";
  post: DTOAdvertisement;
  status: DTOMyWaitingRewardStatus;
};

export enum DTOMyWaitingRewardStatus {
  Active = "active",
  Expired = "expired"
}

export type DTONft = {
  __typename?: "Nft";
  asset: DTOAttachedFile;
  branded: Scalars["Boolean"];
  collection: DTOCollection;
  concernMe: DTONftConcernMe;
  cost: Scalars["Float"];
  costMoney: DTOMoney;
  createdAt: Scalars["Int"];
  description: DTORichText;
  featured: DTOAttachmentImage;
  id: Scalars["Int"];
  image?: Maybe<DTOAttachmentImage>;
  name: Scalars["String"];
  properties: Array<DTONftProperty>;
  quantity?: Maybe<Scalars["Int"]>;
  rearImage?: Maybe<DTOAttachmentImage>;
  shortDescription: DTORichText;
  status: DTONftStatus;
  totalCount?: Maybe<Scalars["Int"]>;
};

export type DTONftAssetRequest = {
  file: Scalars["Upload"];
  type: DTONftAssetType;
};

export enum DTONftAssetType {
  Image = "image",
  Pdf = "pdf",
  Video = "video"
}

export type DTONftConcernMe = {
  __typename?: "NftConcernMe";
  isCreator: Scalars["Boolean"];
  isOwner: Scalars["Boolean"];
};

export type DTONftFilter = {
  collectionId?: InputMaybe<Scalars["Int"]>;
};

export type DTONftPostReward = {
  __typename?: "NftPostReward";
  nft: DTONft;
};

export type DTONftProperty = {
  __typename?: "NftProperty";
  name: Scalars["String"];
  value: Scalars["String"];
};

export type DTONftPropertyRequest = {
  name: Scalars["String"];
  value: Scalars["String"];
};

export enum DTONftStatus {
  Draft = "draft",
  Processing = "processing",
  Published = "published"
}

export type DTONonAuthPasswordResetWithEmailRequest = {
  password: Scalars["String"];
  repeatPassword: Scalars["String"];
  token: Scalars["String"];
};

export enum DTONotificationSingleSwitcher {
  Off = "off",
  On = "on"
}

export enum DTOOrderDirection {
  Asc = "asc",
  Desc = "desc"
}

export type DTOPageInfo = {
  __typename?: "PageInfo";
  endCursor?: Maybe<Scalars["String"]>;
  hasNextPage: Scalars["Boolean"];
  hasPreviousPage: Scalars["Boolean"];
  startCursor?: Maybe<Scalars["String"]>;
};

export enum DTOPasswordStrength {
  Low = "low",
  Middle = "middle",
  Strong = "strong"
}

export type DTOPerson = {
  __typename?: "Person";
  aboutRichText?: Maybe<DTORichText>;
  avatar?: Maybe<DTOAvatar>;
  background?: Maybe<DTOBackground>;
  /** @deprecated use multiBalance field */
  balance: Scalars["Float"];
  businessName: Scalars["String"];
  email: Scalars["String"];
  filledInfo: DTOPersonFilledInfo;
  hasPassword: Scalars["Boolean"];
  id: Scalars["String"];
  isAdmin: Scalars["Boolean"];
  isAnonymous: Scalars["Boolean"];
  isCreator: Scalars["Boolean"];
  isMe: Scalars["Boolean"];
  jobTitle: Scalars["String"];
  location: Scalars["String"];
  multiBalance: DTOMultiBalance;
  name: Scalars["String"];
  nickname: Scalars["String"];
  profileUrl: Scalars["String"];
  source?: Maybe<DTOPersonSource>;
  status: DTOPersonStatus;
  waitingRewards?: Maybe<Array<DTOMyWaitingReward>>;
};

export type DTOPersonFilledInfo = {
  __typename?: "PersonFilledInfo";
  isFillBio: Scalars["Boolean"];
  isLoadAvatar: Scalars["Boolean"];
  isSelectGender: Scalars["Boolean"];
  isSelectLocation: Scalars["Boolean"];
};

export type DTOPersonSettings = {
  __typename?: "PersonSettings";
  background?: Maybe<DTOBackgroundSettings>;
  mail: DTOEmailNotificationSettings;
  theme: DTOTheme;
};

export enum DTOPersonSource {
  Linkedin = "linkedin"
}

export enum DTOPersonStatus {
  Active = "active",
  Deleted = "deleted"
}

export type DTOPlainPart = {
  __typename?: "PlainPart";
  text: Scalars["String"];
};

export enum DTOPlatform {
  Android = "android",
  Ios = "ios",
  Web = "web"
}

export type DTOPostCropParams = {
  __typename?: "PostCropParams";
  height: Scalars["Float"];
  width: Scalars["Float"];
  x: Scalars["Float"];
  y: Scalars["Float"];
};

export type DTOPostCropParamsRequest = {
  height: Scalars["Float"];
  width: Scalars["Float"];
  x: Scalars["Float"];
  y: Scalars["Float"];
};

export enum DTOPostMediaType {
  Attachment = "attachment",
  Image = "image",
  Text = "text",
  Video = "video"
}

export type DTOPostOrientation = {
  __typename?: "PostOrientation";
  contentOrientation?: Maybe<DTOContentOrientation>;
  cropParams?: Maybe<DTOPostCropParams>;
};

export type DTOPostOrientationRequest = {
  contentOrientation: DTOContentOrientation;
  cropParams?: InputMaybe<DTOPostCropParamsRequest>;
};

export type DTOPostReward = DTONftPostReward | DTOTokenPostReward;

export type DTOPostStats = {
  __typename?: "PostStats";
  completedCount: Scalars["Int"];
  completedSurveyCount: Scalars["Int"];
  rewards?: Maybe<Array<DTORewardStats>>;
  spentAmount: Scalars["Float"];
};

export enum DTOPostStatus {
  Completed = "completed",
  Deleted = "deleted",
  Draft = "draft",
  Published = "published",
  WaitForVideo = "waitForVideo"
}

export enum DTOPostType {
  Long = "long",
  Short = "short"
}

export type DTOQuery = {
  __typename?: "Query";
  advertisement: DTOAdvertisement;
  /** Returns published posts from users(without current user and followings) profiles and specific category */
  advertisements: DTOAdvertisementList;
  checkAlreadyRegistered: Scalars["Boolean"];
  /** Checking an existence of a nickname in our database */
  checkNickname: DTOCheckingResponse;
  /** Check exists password for authorized user */
  checkPassword: DTOCheckingResponse;
  /** Check strength password */
  checkPasswordStrength: DTOCheckPasswordStrengthResponse;
  collection: DTOCollection;
  collections: DTOCollectionList;
  convertMoney: Scalars["String"];
  forGood: Array<DTOPerson>;
  /** Generate nickname by email, first and last name */
  generateNickname?: Maybe<Array<Scalars["String"]>>;
  /** Generate url for login or sign up with social network */
  getSocialLoginUrl: Scalars["String"];
  /** A query to ping our GraphQL gateway */
  hello?: Maybe<DTOHello>;
  /** Returns current user's profile data */
  me: DTOPerson;
  myAdvertisements: DTOAdvertisementList;
  myNfts: DTOBoughtNftStatsList;
  nft: DTONft;
  nfts: Array<DTONft>;
  person: DTOPerson;
  ping: Scalars["String"];
  /** First step. Reset password using only email. Sending request with link due submitting */
  resetRequestViaEmail: Scalars["Boolean"];
  /** Returns current user's settings */
  settings: DTOPersonSettings;
  /** Validate email */
  validateEmail: DTOValidationResponse;
  /** Validate name */
  validateName: DTOValidationResponse;
  /** Validate nickname */
  validateNickname: DTOValidationResponse;
  waitApproveReward: DTORewardRequestList;
  waitPublishingAdvertisement: DTOAdvertisementList;
  walletCredentials: DTOWalletCredentials;
};

export type DTOQueryAdvertisementArgs = {
  id: Scalars["String"];
};

export type DTOQueryAdvertisementsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
};

export type DTOQueryCheckAlreadyRegisteredArgs = {
  token: Scalars["String"];
};

export type DTOQueryCheckNicknameArgs = {
  nickname: Scalars["String"];
};

export type DTOQueryCheckPasswordArgs = {
  password?: InputMaybe<Scalars["String"]>;
};

export type DTOQueryCheckPasswordStrengthArgs = {
  password: Scalars["String"];
};

export type DTOQueryCollectionArgs = {
  id: Scalars["Int"];
};

export type DTOQueryCollectionsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  filter?: InputMaybe<DTOCollectionFilter>;
  first?: InputMaybe<Scalars["Int"]>;
};

export type DTOQueryConvertMoneyArgs = {
  from: DTOCurrency;
  to: DTOCurrency;
  value: Scalars["String"];
};

export type DTOQueryGenerateNicknameArgs = {
  request: DTOGenerateNicknameRequest;
};

export type DTOQueryGetSocialLoginUrlArgs = {
  platform?: InputMaybe<DTOPlatform>;
  state?: InputMaybe<Scalars["String"]>;
  type: DTOSocialType;
};

export type DTOQueryHelloArgs = {
  name: Scalars["String"];
};

export type DTOQueryMyAdvertisementsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
};

export type DTOQueryMyNftsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  filter?: InputMaybe<DTOMyNftsFilter>;
  first?: InputMaybe<Scalars["Int"]>;
};

export type DTOQueryNftArgs = {
  id: Scalars["Int"];
};

export type DTOQueryNftsArgs = {
  nftFilter?: InputMaybe<DTONftFilter>;
};

export type DTOQueryPersonArgs = {
  idOrNickname: Scalars["String"];
};

export type DTOQueryResetRequestViaEmailArgs = {
  email: Scalars["String"];
  place: DTOResetPlace;
};

export type DTOQueryValidateEmailArgs = {
  checkIfExists?: InputMaybe<Scalars["Boolean"]>;
  email: Scalars["String"];
};

export type DTOQueryValidateNameArgs = {
  name: Scalars["String"];
};

export type DTOQueryValidateNicknameArgs = {
  checkIfExists?: InputMaybe<Scalars["Boolean"]>;
  nickname: Scalars["String"];
};

export type DTOQueryWaitApproveRewardArgs = {
  after?: InputMaybe<Scalars["String"]>;
  filter?: InputMaybe<DTOWaitApproveRewardFilter>;
  first?: InputMaybe<Scalars["Int"]>;
  order?: InputMaybe<DTOWaitApproveRewardOrder>;
};

export type DTOQueryWaitPublishingAdvertisementArgs = {
  after?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
};

export type DTOQuestion = {
  __typename?: "Question";
  answers?: Maybe<Array<DTOAnswer>>;
  id: Scalars["String"];
  text: Scalars["String"];
};

export type DTORawQuestion = {
  answers?: InputMaybe<Array<Scalars["String"]>>;
  question: Scalars["String"];
};

export type DTORefreshToken = {
  __typename?: "RefreshToken";
  expiresAt: Scalars["String"];
  value: Scalars["String"];
};

export type DTORegistrationRequest = {
  businessName: Scalars["String"];
  email: Scalars["String"];
  jobTitle: Scalars["String"];
  location: Scalars["String"];
  name: Scalars["String"];
  nickname: Scalars["String"];
  password: Scalars["String"];
  profileUrl: Scalars["String"];
};

export type DTORegistrationSocialRequest = {
  businessName: Scalars["String"];
  jobTitle: Scalars["String"];
  location: Scalars["String"];
  name?: InputMaybe<Scalars["String"]>;
  nickname?: InputMaybe<Scalars["String"]>;
  profileUrl: Scalars["String"];
  socialId: Scalars["String"];
};

export type DTORegistrationSocialResponse = {
  __typename?: "RegistrationSocialResponse";
  profile: DTOPerson;
  token: DTOTokenResponse;
};

export enum DTOResetPlace {
  AccountSettings = "accountSettings",
  Login = "login",
  SecuritySettings = "securitySettings"
}

export type DTOReward = {
  __typename?: "Reward";
  approvedAt?: Maybe<Scalars["Int"]>;
  createdAt: Scalars["Int"];
  personId: Scalars["String"];
  postId: Scalars["String"];
  status: DTORewardStatus;
};

export enum DTORewardNftType {
  CarbonOffsetting25kg = "carbonOffsetting25kg",
  CarbonOffsetting50kg = "carbonOffsetting50kg",
  CarbonOffsetting75kg = "carbonOffsetting75kg",
  CarbonOffsetting100kg = "carbonOffsetting100kg",
  CarbonOffsetting150kg = "carbonOffsetting150kg",
  CarbonOffsetting200kg = "carbonOffsetting200kg",
  CarbonOffsetting250kg = "carbonOffsetting250kg",
  CarbonOffsetting300kg = "carbonOffsetting300kg",
  CarbonOffsetting400kg = "carbonOffsetting400kg",
  CarbonOffsetting500kg = "carbonOffsetting500kg",
  CarbonOffsetting600kg = "carbonOffsetting600kg",
  CarbonOffsetting700kg = "carbonOffsetting700kg",
  CarbonOffsetting750kg = "carbonOffsetting750kg",
  CarbonOffsetting800kg = "carbonOffsetting800kg",
  CarbonOffsetting900kg = "carbonOffsetting900kg",
  CarbonOffsetting1000kg = "carbonOffsetting1000kg",
  ExpandiHpItalyTreePlanting25trees = "expandiHpItalyTreePlanting25trees",
  ExpandiHpItalyTreePlanting50trees = "expandiHpItalyTreePlanting50trees",
  ExpandiHpItalyTreePlanting100trees = "expandiHpItalyTreePlanting100trees",
  LenovoCarbonOffsetting25kg = "lenovoCarbonOffsetting25kg",
  LenovoCarbonOffsetting250kg = "lenovoCarbonOffsetting250kg",
  RemovingPlasticFromTheOcean0_5kg = "removingPlasticFromTheOcean0_5kg",
  RemovingPlasticFromTheOcean1_5kg = "removingPlasticFromTheOcean1_5kg",
  RemovingPlasticFromTheOcean1kg = "removingPlasticFromTheOcean1kg",
  RemovingPlasticFromTheOcean2_5kg = "removingPlasticFromTheOcean2_5kg",
  RemovingPlasticFromTheOcean2kg = "removingPlasticFromTheOcean2kg",
  RemovingPlasticFromTheOcean3_5kg = "removingPlasticFromTheOcean3_5kg",
  RemovingPlasticFromTheOcean3kg = "removingPlasticFromTheOcean3kg",
  RemovingPlasticFromTheOcean4kg = "removingPlasticFromTheOcean4kg",
  RemovingPlasticFromTheOcean5kg = "removingPlasticFromTheOcean5kg",
  RemovingPlasticFromTheOcean10kg = "removingPlasticFromTheOcean10kg",
  RemovingPlasticFromTheOcean15kg = "removingPlasticFromTheOcean15kg",
  RemovingPlasticFromTheOcean20kg = "removingPlasticFromTheOcean20kg",
  RemovingPlasticFromTheOcean25kg = "removingPlasticFromTheOcean25kg",
  RemovingPlasticFromTheOcean30kg = "removingPlasticFromTheOcean30kg",
  RemovingPlasticFromTheOcean35kg = "removingPlasticFromTheOcean35kg",
  RemovingPlasticFromTheOcean40kg = "removingPlasticFromTheOcean40kg",
  TreePlanting1tree = "treePlanting1tree",
  TreePlanting5trees = "treePlanting5trees",
  TreePlanting10trees = "treePlanting10trees",
  TreePlanting15trees = "treePlanting15trees",
  TreePlanting20trees = "treePlanting20trees",
  TreePlanting25trees = "treePlanting25trees",
  TreePlanting30trees = "treePlanting30trees",
  TreePlanting35trees = "treePlanting35trees",
  TreePlanting40trees = "treePlanting40trees",
  TreePlanting45trees = "treePlanting45trees",
  TreePlanting50trees = "treePlanting50trees",
  TreePlanting75trees = "treePlanting75trees",
  TreePlanting100trees = "treePlanting100trees",
  TreePlanting150trees = "treePlanting150trees",
  TreePlanting200trees = "treePlanting200trees",
  TreePlanting250trees = "treePlanting250trees"
}

export type DTORewardRequest = {
  __typename?: "RewardRequest";
  address: Scalars["String"];
  answers?: Maybe<Array<DTOCompletedAnswer>>;
  approvedAt?: Maybe<Scalars["Int"]>;
  createdAt: Scalars["Int"];
  id: Scalars["String"];
  person: DTOPerson;
  post: DTOAdvertisement;
  status: DTORewardStatus;
};

export type DTORewardRequestEdge = {
  __typename?: "RewardRequestEdge";
  cursor: Scalars["String"];
  node: DTORewardRequest;
};

export type DTORewardRequestList = {
  __typename?: "RewardRequestList";
  edges: Array<DTORewardRequestEdge>;
  pageInfo: DTOPageInfo;
  totalCount: Scalars["Int"];
};

export type DTORewardStats = {
  __typename?: "RewardStats";
  completedCount: Scalars["Int"];
  reward: DTOPostReward;
  spentAmount: Scalars["Float"];
};

export enum DTORewardStatus {
  Approved = "approved",
  ApprovedWithBonus = "approvedWithBonus",
  Pending = "pending"
}

export enum DTORewardType {
  Nft = "nft",
  Token = "token"
}

export type DTORichText = {
  __typename?: "RichText";
  parts: Array<DTORichTextPart>;
  text: Scalars["String"];
};

export type DTORichTextPart =
  | DTOHashtagPart
  | DTOMentionPart
  | DTOPlainPart
  | DTOUrlPart;

export type DTOShare = {
  __typename?: "Share";
  rate: Scalars["Int"];
  receiver: DTOPerson;
  type: DTOShareType;
};

export type DTOShareRequest = {
  rate: Scalars["Int"];
  receiverId: Scalars["String"];
  type: DTOShareType;
};

export enum DTOShareType {
  Creator = "creator",
  Good = "good",
  Platform = "platform"
}

export type DTOSizes = {
  __typename?: "Sizes";
  height?: Maybe<Scalars["Int"]>;
  width?: Maybe<Scalars["Int"]>;
};

export type DTOSocialLoginData = {
  firstName?: InputMaybe<Scalars["String"]>;
  idToken: Scalars["String"];
  lastName?: InputMaybe<Scalars["String"]>;
};

export type DTOSocialLoginResponse = {
  __typename?: "SocialLoginResponse";
  auth?: Maybe<DTOTokenResponse>;
  signUp?: Maybe<DTOSocialSignUp>;
};

export type DTOSocialSignUp = {
  __typename?: "SocialSignUp";
  email: Scalars["String"];
  firstName: Scalars["String"];
  id: Scalars["String"];
  lastName: Scalars["String"];
};

export enum DTOSocialType {
  Linkedin = "linkedin"
}

export type DTOStartImageUploadingRequest = {
  name: Scalars["String"];
  targetId: Scalars["String"];
  targetType: DTOAttachTarget;
};

export type DTOStartVideoUploadingRequest = {
  advId: Scalars["String"];
  clip?: InputMaybe<DTOClipFrames>;
  crop?: InputMaybe<DTOPostOrientationRequest>;
  mimeType: Scalars["String"];
  name: Scalars["String"];
  size: Scalars["Int"];
  videoType: DTOVideoType;
};

export type DTOSubscription = {
  __typename?: "Subscription";
  myPostsEvents: DTOMyPostsEvent;
  ping: Scalars["String"];
};

export type DTOSubscriptionMyPostsEventsArgs = {
  offset?: InputMaybe<Scalars["String"]>;
};

export type DTOSuccessResponse = {
  __typename?: "SuccessResponse";
  success: Scalars["Boolean"];
};

export enum DTOTheme {
  Dark = "dark",
  Light = "light",
  SystemDefault = "systemDefault"
}

export type DTOTokenPostReward = {
  __typename?: "TokenPostReward";
  price: DTOMoney;
};

export type DTOTokenResponse = {
  __typename?: "TokenResponse";
  accessToken: DTOAccessToken;
  refreshToken: DTORefreshToken;
};

export type DTOUpdateAdvertisementRequest = {
  advId: Scalars["String"];
  budget?: InputMaybe<Scalars["Float"]>;
  content?: InputMaybe<Scalars["String"]>;
  endAt?: InputMaybe<Scalars["Int"]>;
  orientation?: InputMaybe<DTOPostOrientationRequest>;
  price?: InputMaybe<Scalars["Float"]>;
  questions?: InputMaybe<Array<DTORawQuestion>>;
  rewardNftCount?: InputMaybe<Scalars["Int"]>;
  rewardNftId?: InputMaybe<Scalars["Int"]>;
  rewardType?: InputMaybe<DTORewardType>;
  surveyRewardNftId?: InputMaybe<Scalars["Int"]>;
  surveyRewardPrice?: InputMaybe<Scalars["Float"]>;
  surveyRewardType?: InputMaybe<DTORewardType>;
  title?: InputMaybe<Scalars["String"]>;
};

export type DTOUpdatePersonRequest = {
  avatar?: InputMaybe<Scalars["Upload"]>;
  businessName?: InputMaybe<Scalars["String"]>;
  jobTitle?: InputMaybe<Scalars["String"]>;
  location?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
  nickname?: InputMaybe<Scalars["String"]>;
  profileUrl?: InputMaybe<Scalars["String"]>;
};

export type DTOUploadImageMetaInfo = {
  __typename?: "UploadImageMetaInfo";
  bucket: Scalars["String"];
  imageId: Scalars["String"];
  key: Scalars["String"];
};

export type DTOUploadMetaInfo = {
  __typename?: "UploadMetaInfo";
  bucket: Scalars["String"];
  key: Scalars["String"];
  videoId: Scalars["String"];
};

export type DTOUrlPart = {
  __typename?: "UrlPart";
  path: Scalars["String"];
  text: Scalars["String"];
  urlType: DTOUrlPartType;
};

export enum DTOUrlPartType {
  Email = "email",
  External = "external",
  Internal = "internal"
}

export type DTOValidationResponse = {
  __typename?: "ValidationResponse";
  status: DTOValidationStatus;
};

export enum DTOValidationStatus {
  Allow = "allow",
  Deny = "deny"
}

export enum DTOVerificationAction {
  DeleteMe = "deleteMe",
  EmailChange = "emailChange",
  SignUp = "signUp"
}

export type DTOVerificationCode = {
  __typename?: "VerificationCode";
  /** Action type */
  action: DTOVerificationAction;
  /** Code id to verify */
  id: Scalars["String"];
  /** Token lifetime in seconds */
  timeout: Scalars["Int"];
  /** Token expire datetime */
  timeoutAt: Scalars["Int"];
};

export type DTOVerificationCodeInput = {
  /** 6 digits */
  code: Scalars["String"];
  /** id from VerificationCode object */
  id: Scalars["String"];
};

export type DTOVideoConversion = {
  __typename?: "VideoConversion";
  duration: Scalars["Int"];
  height: Scalars["Int"];
  id: Scalars["String"];
  type: Scalars["String"];
  url: Scalars["String"];
  width: Scalars["Int"];
};

export enum DTOVideoType {
  Long = "long",
  Preview = "preview",
  Short = "short"
}

export type DTOWaitApproveRewardFilter = {
  advId?: InputMaybe<Scalars["String"]>;
};

export type DTOWaitApproveRewardOrder = {
  direction: DTOOrderDirection;
  field: DTOWaitApproveRewardOrderField;
};

export enum DTOWaitApproveRewardOrderField {
  ClaimedAt = "claimedAt"
}

export type DTOWaitingReward = {
  __typename?: "WaitingReward";
  createdAt: Scalars["Int"];
  email: Scalars["String"];
  id: Scalars["String"];
  name: Scalars["String"];
  post?: Maybe<DTOAdvertisement>;
  receiveLink?: Maybe<Scalars["String"]>;
  receivedAt?: Maybe<Scalars["Int"]>;
  receivedBy?: Maybe<DTOPerson>;
};

export type DTOWalletCredentials = {
  __typename?: "WalletCredentials";
  address: Scalars["String"];
  mnemonic: Scalars["String"];
  privateKey: Scalars["String"];
};
