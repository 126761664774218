export enum Events {
  mousedown = "mousedown",
  mousemove = "mousemove",
  mouseenter = "mouseenter",
  mouseleave = "mouseleave",
  mouseup = "mouseup",
  wheel = "wheel",
  focus = "focus",
  touchmove = "touchmove",
  touchstart = "touchstart",
  touchend = "touchend",
  resize = "resize",
  TouchEvent = "TouchEvent",
  scroll = "scroll",
  keyup = "keyup",
  onloadeddata = "onloadeddata",
  keydown = "keydown",
  paste = "paste",
  localStorageChange = "storage",
  load = "load",
  unload = "unload",
  beforeunload = "beforeunload"
}

export enum VideoElementEvent {
  canplay = "canplay",
  play = "play",
  pause = "pause",
  loadedmetadata = "loadedmetadata",
  timeupdate = "timeupdate",
  progress = "progress",
  ended = "ended",
  error = "error",
  seeked = "seeked",
  loadeddata = "loadeddata"
}

export enum DocumentEvents {
  visibilitychange = "visibilitychange",
  focus = "focus"
}

export enum RouterEvents {
  routeChangeStart = "routeChangeStart",
  routeChangeComplete = "routeChangeComplete",
  routeChangeError = "routeChangeError",
  hashChangeStart = "hashChangeStart",
  beforeunload = "beforeunload"
}
