import { createContext } from "react";

import { AuthenticationStatus } from "~/components/providers/AuthenticationContextProvider/declarations";
import createUseContext from "~/utils/createUseContext";

export type AuthenticationContextState = {
  authenticationStatus: AuthenticationStatus;
  setAuthenticated: (accessToken: string) => void;
};

export const AuthenticationContext = createContext<
  AuthenticationContextState | undefined
>(undefined);

export const useAuthenticationContext = createUseContext(
  AuthenticationContext,
  "AuthenticationContext"
);
