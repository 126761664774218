import { ThemeMode } from "~/theme/declarations";
import { isServerSide } from "~/utils/common";

export const getThemeModeFromBrowser = (): ThemeMode | null => {
  if (isServerSide()) {
    return null;
  }

  if (process.env.NEXT_PUBLIC_ENABLE_SETTINGS_PERSONALIZATION !== "true") {
    return ThemeMode.Light;
  }

  return window.matchMedia("(prefers-color-scheme: light)").matches
    ? ThemeMode.Light
    : ThemeMode.Dark;
};
