import { SSRData } from "next-urql";
import { ReactNode, useCallback, useMemo } from "react";
import { Provider } from "urql";

import {
  SubscriptionConnectionEvent,
  SubscriptionsConnectionEventsHandler
} from "~/components/providers/UrqlProvider/declarations";
import useUrqlClient from "~/components/providers/UrqlProvider/useUrqlClient";
import useObserver from "~/utils/observer/useObserver";

import { UrqlContext, UrqlContextState } from "./UrqlContext";

type Props = {
  ssrData?: SSRData;
  children: ReactNode;
};

const UrqlProvider = ({ ssrData, children }: Props): JSX.Element => {
  const {
    subscribe: subscribeToConnectionEvents,
    unsubscribe: unsubscribeFromConnectionEvents,
    notify
  } = useObserver<SubscriptionsConnectionEventsHandler>();

  const handleSubscriptionsDisconnected = useCallback(() => {
    notify(false, SubscriptionConnectionEvent.disconnection);
  }, [notify]);

  const handleSubscriptionsReconnected = useCallback(() => {
    notify(false, SubscriptionConnectionEvent.reconnection);
  }, [notify]);

  const { urqlClient, recreateClient } = useUrqlClient({
    ssrData,
    onSubscriptionDisconnected: handleSubscriptionsDisconnected,
    onSubscriptionReconnected: handleSubscriptionsReconnected
  });

  const value: UrqlContextState = useMemo(
    () => ({
      recreateClient,
      subscribeToConnectionEvents,
      unsubscribeFromConnectionEvents
    }),
    [
      recreateClient,
      subscribeToConnectionEvents,
      unsubscribeFromConnectionEvents
    ]
  );

  return (
    <UrqlContext.Provider value={value}>
      <Provider value={urqlClient}>{children}</Provider>
    </UrqlContext.Provider>
  );
};

export default UrqlProvider;
