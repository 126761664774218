var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"2449e0f3f6c4f648ff1793798d13060197e49404_6f9a7859097edc7e2a1ac56ecc3d9c90"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/
import * as Sentry from "@sentry/nextjs";

import { sentryConfig } from "./sentry.config";

Sentry.init({
  ...sentryConfig,
  autoSessionTracking: process.env.NEXT_PUBLIC_ENVIRONMENT === "production"
});
