import { AnyVariables, CombinedError } from "urql";
import { UseQueryArgs, UseQueryResponse } from "urql/dist/types/hooks";

import { RequestState } from "~/constants/requestState";
import { GraphQLQueryState } from "~/declarations/graphql/queryState";

type UseQueryOptions<Variables extends AnyVariables> = Omit<
  UseQueryArgs<Variables>,
  "query"
>;

export type UseQueryHook<
  Data = Record<string, unknown>,
  Variables extends AnyVariables = AnyVariables
> = (options: UseQueryOptions<Variables>) => UseQueryResponse<Data, Variables>;

export default function useGraphQLQuery<
  QueryData extends Record<string, unknown>,
  Key extends keyof QueryData,
  QueryDataMapped extends
    | Record<string, unknown>
    | Record<string, unknown>[]
    | undefined,
  QueryVariables extends AnyVariables = AnyVariables
>({
  queryKey,
  useQuery,
  options,
  requestMapper
}: {
  queryKey: Key;
  useQuery: UseQueryHook<QueryData, QueryVariables>;
  options?: UseQueryOptions<QueryVariables>;
  requestMapper?: (data: QueryData[Key]) => QueryDataMapped;
}): GraphQLQueryState<QueryData[Key], QueryDataMapped> {
  const [{ fetching, error, data }, refetchRequest] = useQuery(
    (options ?? {}) as UseQueryOptions<QueryVariables>
  );

  const refetch = (): void => refetchRequest();

  if (fetching) {
    return {
      type: RequestState.Loading,
      refetch
    };
  }

  if (options?.pause) {
    return {
      type: RequestState.Paused,
      data: null,
      refetch
    };
  }

  if (error || !data) {
    return {
      type: RequestState.Error,
      error: error
        ? error
        : new CombinedError({
            networkError: new Error("No error object")
          }),
      refetch
    };
  }

  const dataByKey = data[queryKey];

  return {
    data: dataByKey,
    type: RequestState.Success,
    refetch,
    dataMapped: requestMapper?.(dataByKey) as QueryDataMapped
  };
}
