import { Cache } from "@urql/exchange-graphcache";

import {
  DTOApproveRewardMutation,
  DTOApproveRewardMutationVariables
} from "~/components/rewardsList/RewardsList/cells/RewardsListApproveCell/graphql/approveRewardMutation.generated";
import {
  DTOWaitApproveRewardQuery,
  DTOWaitApproveRewardQueryVariables,
  WaitApproveRewardQueryDocument
} from "~/components/rewardsList/RewardsList/graphql/waitApproveRewardQuery.generated";

const approveReward = (
  result: DTOApproveRewardMutation,
  variables: DTOApproveRewardMutationVariables,
  cache: Cache
): void => {
  const approvedPostId = result.approveReward.id;

  cache
    .inspectFields("Query")
    .filter(field => ["waitApproveReward"].includes(field.fieldName))
    .forEach(field => {
      cache.updateQuery<
        DTOWaitApproveRewardQuery,
        DTOWaitApproveRewardQueryVariables
      >(
        {
          query: WaitApproveRewardQueryDocument,
          variables: field.arguments ?? {}
        },
        data => {
          if (!data) {
            return data;
          }

          const newEdges = data?.waitApproveReward.edges;

          if (newEdges) {
            const deletedIndex = newEdges.findIndex(
              ({ node }) => node.id === approvedPostId
            );

            if (deletedIndex >= 0) {
              newEdges.splice(deletedIndex, 1);
            }
          }
          return data;
        }
      );
    });
};

export default approveReward;
