import { IconVariant } from "~/components/core/Icon/declarations";

export enum PersonMenuOption {
  wallet = "wallet",
  myNft = "myNft",
  myCollections = "myCollections",
  settings = "settings",
  rewardsList = "rewardsList"
}

export enum UserRole {
  casual = "casual",
  admin = "admin",
  creator = "creator"
}

export type PersonMenuOptionDescription = {
  type: PersonMenuOption;
  icon: IconVariant;
  labelTranslationKey: string;
  roles?: UserRole[];
  href?: string;
};
