import { SSRData } from "next-urql";
import { useCallback, useEffect, useState } from "react";
import { Client } from "urql";

import initUrqlClient from "./initUrqlClient";

interface UseUrqlClient {
  urqlClient: Client;
  recreateClient: () => void;
}

interface CreateUrqlClientBySSRDataOptions {
  forceRecreate?: boolean;
  onSubscriptionDisconnected?: () => void;
  onSubscriptionReconnected?: () => void;
}

interface UseUrqlClientProps {
  ssrData?: SSRData;
  onSubscriptionDisconnected: () => void;
  onSubscriptionReconnected: () => void;
}

interface UseUrqlClientState {
  urqlClient: Client;
  ssrData?: SSRData;
}

const createUrqlClientBySSRData = (
  ssrData?: SSRData,
  {
    forceRecreate,
    onSubscriptionDisconnected,
    onSubscriptionReconnected
  }: CreateUrqlClientBySSRDataOptions = {}
): Client =>
  initUrqlClient({
    initialState: ssrData,
    forceRecreate,
    onSubscriptionDisconnected,
    onSubscriptionReconnected
  }).urqlClient;

export default function useUrqlClient({
  ssrData,
  onSubscriptionDisconnected,
  onSubscriptionReconnected
}: UseUrqlClientProps): UseUrqlClient {
  const [state, setState] = useState<UseUrqlClientState>(() => ({
    urqlClient: createUrqlClientBySSRData(ssrData, {
      onSubscriptionDisconnected,
      onSubscriptionReconnected
    }),
    ssrData
  }));

  useEffect(() => {
    if (ssrData === state.ssrData) {
      return;
    }

    const urqlClient = createUrqlClientBySSRData(ssrData);

    setState({ urqlClient, ssrData });
  }, [ssrData, state.ssrData]);

  const recreateClient = useCallback(() => {
    const newUrqlClient = createUrqlClientBySSRData(
      {},
      {
        forceRecreate: true
      }
    );

    setState({
      urqlClient: newUrqlClient,
      ssrData
    });
  }, [ssrData]);

  return { urqlClient: state.urqlClient, recreateClient };
}
