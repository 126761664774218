import { AuthConfig } from "@urql/exchange-auth";
import { NextPageContext } from "next";
import { AnyVariables, makeOperation, Operation } from "urql";

import { LogoutMutationDocument } from "~/components/auth/logout/useLogout/graphql/logoutMutation.generated";
import { NETWORK_CODES } from "~/constants/apiInteraction";
import AuthService from "~/services/AuthService";
import { isServerSide } from "~/utils/common";
import getGraphQLErrorCode from "~/utils/graphql/errors/getGraphQLErrorCode";
import notEmpty from "~/utils/notEmpty";

import { RefreshTokenMutationDocument } from "./graphql/tokenRefreshMutation.generated";

interface AuthState {
  token: string;
}

export default (ctx?: NextPageContext): AuthConfig<AuthState> => ({
  getAuth: async ({ authState, mutate }) => {
    // for initial launch, fetch the auth state
    // depending on side (server/client)
    if (!authState) {
      const token = isServerSide()
        ? AuthService.getAccessTokenFromPageContext(ctx)
        : AuthService.getAccessToken();

      if (token) {
        return { token };
      }

      return null;
    }

    // don't refresh tokens on backend side because we can't forward
    //  http-only cookie on the client side after success refreshing.
    if (isServerSide()) {
      return null;
    }

    /**
     * the following code gets executed when an auth error has occurred
     * we should refresh the token if possible and return a new auth state
     * If refresh fails, we should log out
     **/
    const result = await mutate(RefreshTokenMutationDocument);

    if (result.data?.refreshToken) {
      const newAccessToken = result.data.refreshToken.accessToken.value;

      AuthService.setAccessToken(newAccessToken);

      // return the new token to AuthState
      return {
        token: newAccessToken
      };
    }

    const logoutResult = await mutate(LogoutMutationDocument);
    const hasLogoutError = Boolean(logoutResult.error);
    const logoutErrorStatus = getGraphQLErrorCode(logoutResult.error);
    const isLogoutErrorUnauthorized = notEmpty(logoutErrorStatus)
      ? [NETWORK_CODES.forbidden, NETWORK_CODES.unauthorized].includes(
          logoutErrorStatus
        )
      : false;

    // reset client resources only if logout was successful or is we've got authorized error
    //  (it means that we already logout)
    if (!hasLogoutError || isLogoutErrorUnauthorized) {
      AuthService.resetClientOnLogout();
    }

    return null;
  },
  addAuthToOperation: ({
    authState,
    operation
  }: {
    authState?: AuthState | null;
    operation: Operation<unknown, AnyVariables>;
  }) => {
    // the token isn't in the auth state, return the operation without changes
    if (!authState || !authState.token) {
      return operation;
    }

    // fetchOptions can be a function (See Client API) but you can simplify this based on usage
    const fetchOptions =
      typeof operation.context.fetchOptions === "function"
        ? operation.context.fetchOptions()
        : operation.context.fetchOptions || {};

    return makeOperation(operation.kind, operation, {
      ...operation.context,
      fetchOptions: {
        ...fetchOptions,
        headers: {
          ...fetchOptions.headers,
          ...AuthService.getAuthorizationHeader(authState.token)
        }
      }
    });
  },
  // operations that not require authorization will just be forwarded
  willAuthError: ({ authState }) => !authState,
  didAuthError: ({ error }) =>
    error.graphQLErrors.some(e =>
      [NETWORK_CODES.forbidden, NETWORK_CODES.unauthorized].includes(
        e.extensions?.errorCode as number
      )
    )
});
