import dynamic, { DynamicOptions, LoaderComponent } from "next/dynamic";

import { LoggingTagFlow } from "~/constants/logging";
import captureException from "~/utils/logging/captureException";

import { isServerSide } from "../common";

const RELOAD_ATTEMPT_MARK = "dynamicImportReloadAttempt";

type Loader<P> = () => LoaderComponent<P>;

// The function checked the necessity of reloading page for specific error, containing the file name.
//  If we have got an error for first time (local storage doesn't include mark for this error) - add mark to LS and return positiveT flag (need reload);
//  If we have got an error for second time (local storage includes mark for this error) - clear this mark from LS and return negative flag (without reloading);
function checkReloadingNecessityAndSetOrClearLSMark(
  errorMessage: string
): boolean {
  let needReload = false;

  try {
    const errorIdentifier = window.btoa(errorMessage);
    const reloadAttemptMarks = JSON.parse(
      localStorage.getItem(RELOAD_ATTEMPT_MARK) || "[]"
    );

    if (!reloadAttemptMarks.includes(errorIdentifier)) {
      needReload = true;
      reloadAttemptMarks.push(errorIdentifier);

      localStorage.setItem(
        RELOAD_ATTEMPT_MARK,
        JSON.stringify(reloadAttemptMarks)
      );
    } else {
      captureException(
        new Error("Dynamic import loading error"),
        LoggingTagFlow.DynamicImport
      );

      reloadAttemptMarks.splice(reloadAttemptMarks.indexOf(errorIdentifier), 1);

      localStorage.setItem(
        RELOAD_ATTEMPT_MARK,
        JSON.stringify(reloadAttemptMarks)
      );
    }
  } catch (error) {
    captureException(error, LoggingTagFlow.DynamicImport);
  }

  return needReload;
}

export const safeDynamicImport = <P>(
  loader: Loader<P>,
  options?: DynamicOptions<P>
): React.ComponentType<P> =>
  dynamic<P>(
    // @ts-expect-error No need to return component
    () =>
      loader().catch(error => {
        if (!isServerSide()) {
          if (checkReloadingNecessityAndSetOrClearLSMark(error.message)) {
            location.reload();
          }
        }

        captureException(error, LoggingTagFlow.DynamicImport);
      }),
    { ...options }
  );

type ModuleNotFound = Error & {
  code: string;
};

const MODULE_NOT_FOUND_ERROR_CODE = "MODULE_NOT_FOUND";

export const isDynamicModuleNotFoundError = (error: unknown): boolean =>
  error instanceof Error &&
  (error as ModuleNotFound).code === MODULE_NOT_FOUND_ERROR_CODE;

export const logAndReloadIfDynamicModuleNotFound = (
  error: unknown
): boolean => {
  if (!isDynamicModuleNotFoundError(error)) {
    return false;
  }

  captureException(error, LoggingTagFlow.DynamicImport);

  if (!isServerSide()) {
    window.location.reload();
  }

  return true;
};
