import { AppDialog } from "./constants";
import { DialogOptions } from "./declarations";
import { useDialogsContext } from "./DialogsContext";

type DialogOptionsOnOpen<Extra extends object> = Partial<DialogOptions<Extra>>;

type UseDialogResult<Extra extends object> = {
  open: (optionsOnOpen?: DialogOptionsOnOpen<Extra>) => void;
  close: () => void;
};

export const useAppDialog = <Extra extends object>(
  dialog: AppDialog,
  options?: DialogOptions<Extra>
): UseDialogResult<Extra> => {
  const { setCurrentDialog } = useDialogsContext();

  return {
    open: (optionsOnOpen?: DialogOptionsOnOpen<Extra>) =>
      setCurrentDialog({ dialog, options: { ...options, ...optionsOnOpen } }),
    close: () => setCurrentDialog(null)
  };
};
