/* eslint-disable cleo/no-deep-imports */
import gql from "graphql-tag";

import * as Types from "../../../../declarations/graphql/types";
import {
  AttachmentImageFragmentDoc,
  DTOAttachmentImageFragment
} from "./attachmentImageFragment.generated";

export type DTOAttachmentVideoFragment = {
  __typename: "AttachmentVideo";
  id: string;
  publicUrl: string;
  videoId: string;
  duration?: number | null;
  height: number;
  width: number;
  cover?:
    | ({ __typename?: "AttachmentImage" } & DTOAttachmentImageFragment)
    | null;
};

export const AttachmentVideoFragmentDoc = gql`
  fragment AttachmentVideoFragment on AttachmentVideo {
    __typename
    id
    publicUrl
    videoId
    cover {
      ...AttachmentImageFragment
    }
    duration
    height
    width
  }
`;
