import { CustomBlurEffects, CustomShadows } from "~/declarations/theme";

export const shadows = (): CustomShadows => ({
  colorful: "0px 20px 24px -8px rgba(71, 173, 81, 0.24)",
  main: {
    down: "0px 32px 82px -8px rgba(93, 102, 109, 0.16)",
    left: "-32px 0px 82px -8px rgba(93, 102, 109, 0.16)",
    right: "32px 0px 82px -8px rgba(93, 102, 109, 0.16)",
    up: "0px -32px 82px -8px rgba(93, 102, 109, 0.16)"
  },
  secondary: "0px 73px 82px -4px rgba(93, 102, 109, 0.24)"
});

/* TODO: clarify requirements */
export const blur: CustomBlurEffects = {
  main: "blur(4px)",
  secondary: "blur(4px)"
};
