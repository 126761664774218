const { captureException } = require("@sentry/nextjs");
const { Router } = require("next/router");
const ROUTES = require("./constants/routes");

const hoistNonReactStatics = require("hoist-non-react-statics");

const COMMON_NAMESPACES = [
  "common",
  "meta",
  "advertisement",
  "form",
  "person",
  "wallet",
  "validation"
];
const AUTH_NAMESPACES = [...COMMON_NAMESPACES, "auth"];
const SETTINGS_NAMESPACES = ["settings"];
const LANDING_CLEO_NAMESPACES = ["landing-cleo", "person", "media"];
const NFT_INSTANCE_NAMESPACES = ["nft"];
const COLLECTION_INSTANCE_NAMESPACES = ["nft", "collections"];
const MY_ITEMS_NAMESPACES = ["my-items"];
const INDEX_INSTANCE_NAMESPACES = ["index"];
const REWARDS_LIST_NAMESPACES = [
  ...COMMON_NAMESPACES,
  "advertisement",
  "rewardsList"
];

const CHUNK_LOAD_ERROR_NAME = "ChunkLoadError";

module.exports = {
  logBuild: false,
  locales: ["en", "zh"],
  defaultLocale: "en",
  staticsHoc: hoistNonReactStatics,
  loadLocaleFrom: (language, namespace) =>
    import(`./public/locales/${language}/${namespace}.json`)
      .then(m => m.default)
      .catch(error => {
        if (error.name !== CHUNK_LOAD_ERROR_NAME) {
          throw error;
        }

        captureException(
          new Error(
            `Absent translation. Namespace: ${namespace}. Language: ${language}`
          ),
          {
            tags: {
              flow: "Translation fetching"
            }
          }
        );

        if (typeof "window" !== undefined) {
          const handleRouteChangeComplete = () => {
            Router.events.off("routeChangeComplete", handleRouteChangeComplete);

            window.location.reload();
          };

          Router.events.on("routeChangeComplete", handleRouteChangeComplete);
        }

        return {};
      }),
  pages: {
    "*": COMMON_NAMESPACES,
    [`rgx:^${ROUTES.auth.signup}`]: AUTH_NAMESPACES,
    [ROUTES.auth.login]: AUTH_NAMESPACES,
    [ROUTES.auth.resetPassword]: AUTH_NAMESPACES,
    [ROUTES.store]: COLLECTION_INSTANCE_NAMESPACES,
    [ROUTES.landing.Cleo]: LANDING_CLEO_NAMESPACES,
    [ROUTES.collections.create]: NFT_INSTANCE_NAMESPACES,
    [ROUTES.items.instance]: NFT_INSTANCE_NAMESPACES,
    [ROUTES.items.index]: MY_ITEMS_NAMESPACES,
    [ROUTES.index]: INDEX_INSTANCE_NAMESPACES,
    [ROUTES.rewardsList]: REWARDS_LIST_NAMESPACES,
    [ROUTES.collections.instance]: COLLECTION_INSTANCE_NAMESPACES,
    [ROUTES.collections.index]: COLLECTION_INSTANCE_NAMESPACES,
    [`rgx:^${ROUTES.settings.index}*`]: SETTINGS_NAMESPACES
  }
};
