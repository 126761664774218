/* eslint-disable cleo/no-deep-imports */
import gql from "graphql-tag";
import * as Urql from "urql";

import * as Types from "../../../../declarations/graphql/types";

export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type DTORefreshTokenMutationVariables = Types.Exact<{
  [key: string]: never;
}>;

export type DTORefreshTokenMutation = {
  __typename?: "Mutation";
  refreshToken: {
    __typename?: "TokenResponse";
    accessToken: { __typename?: "AccessToken"; value: string };
  };
};

export const RefreshTokenMutationDocument = gql`
  mutation RefreshTokenMutation {
    refreshToken {
      accessToken {
        value
      }
    }
  }
`;

export function useRefreshTokenMutation() {
  return Urql.useMutation<
    DTORefreshTokenMutation,
    DTORefreshTokenMutationVariables
  >(RefreshTokenMutationDocument);
}
