import { createTheme } from "@mui/material/styles";

import { CustomTheme } from "~/declarations/theme";

import breakpoints from "./breakpoints";
import componentOverrides from "./componentOverrides";
import createPalette from "./createPalette";
import { ThemeMode } from "./declarations";
import { blur, shadows } from "./effects";
import typography from "./typography";

export const THEME_SPACING = 4;

export default (mode: ThemeMode): CustomTheme =>
  createTheme({
    spacing: THEME_SPACING,
    palette: createPalette(mode),
    components: componentOverrides(mode),
    shadows: shadows(),
    breakpoints,
    blur,
    typography
  });
