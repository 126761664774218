import { POINT_SEPARATOR } from "~/constants/common";
import { BrandColor } from "~/declarations/theme";
import { BRAND_PREFIX } from "~/theme/createPalette";

import { COLOR_MAP } from "./constants";
import { IconButtonColor, IconButtonColors } from "./declarations";

export const getIconButtonColors = (
  color: IconButtonColor
): IconButtonColors => {
  if (color === "contrast" || color === "transparent" || color === "grey") {
    return COLOR_MAP[color];
  }

  if (color.startsWith(BRAND_PREFIX) && color.includes(POINT_SEPARATOR)) {
    const [rawColor, shade] = color.split(POINT_SEPARATOR);
    const brandColor = rawColor as BrandColor;

    if (shade === "main") {
      return {
        background: {
          active: `${brandColor}.main`,
          regular: `${brandColor}.main`,
          disabled: "background.quinary",
          hover: `${brandColor}.dark`
        },
        icon: {
          regular: "background.primary",
          disabled: "background.tertiary"
        }
      };
    } else if (shade === "light") {
      return {
        background: {
          active: `${brandColor}.lightMax`,
          regular: `${brandColor}.lightMax`,
          disabled: `${brandColor}.lightMax`,
          hover: "brandGreen.main"
        },
        icon: {
          regular: "background.primary",
          disabled: "background.tertiary"
        }
      };
    }
  }

  throw new Error(`Incorrect color value: ${color}`);
};
