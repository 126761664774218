import styled from "@emotion/styled";

import AppLayoutHeaderActions from "~/components/layouts/AppLayout/AppLayoutHeader/AppLayoutHeaderActions";
import AppLayoutHeaderLogo from "~/components/layouts/AppLayout/AppLayoutHeader/AppLayoutHeaderLogo";
import { APP_LAYOUT_GEOMETRY } from "~/components/layouts/AppLayout/constants";
import { THEME_SPACING } from "~/theme/theme";

const AppLayoutHeader = (): JSX.Element => (
  <Header>
    <AppLayoutHeaderLogo />
    <AppLayoutHeaderActions />
  </Header>
);

export default AppLayoutHeader;

const Header = styled.header`
  align-items: center;
  backdrop-filter: blur(6px);
  background-color: ${({ theme }) => theme.palette.transparent.inverse[4]};
  border-radius: 60px;
  display: flex;
  gap: ${({ theme }) => theme.spacing(2)};
  justify-content: space-between;
  left: 0;
  margin: ${({ theme }) =>
    theme.spacing(4, APP_LAYOUT_GEOMETRY.paddings.xxs, 2)};
  max-width: ${APP_LAYOUT_GEOMETRY.maxWidth}px;
  padding: ${({ theme }) => theme.spacing(2, 2, 2, 4)};
  position: fixed;
  right: calc(-100vw + 100%);
  top: 0;
  z-index: ${({ theme }) => theme.zIndex.drawer + 1};

  ${({ theme }) => theme.breakpoints.up("sm")} {
    margin: ${({ theme }) =>
      theme.spacing(5, APP_LAYOUT_GEOMETRY.paddings.sm, 2)};
    padding: ${({ theme }) => theme.spacing(2, 2, 2, 5)};
  }

  ${({ theme }) => theme.breakpoints.up("md")} {
    margin: ${({ theme }) =>
      theme.spacing(5, APP_LAYOUT_GEOMETRY.paddings.md, 2)};
  }

  ${({ theme }) =>
    theme.breakpoints.up(
      APP_LAYOUT_GEOMETRY.maxWidth +
        APP_LAYOUT_GEOMETRY.paddings.md * 2 * THEME_SPACING
    )} {
    margin: ${({ theme }) => theme.spacing(5, "auto", 2)};
  }
`;
