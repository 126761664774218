import { useState } from "react";

import CurrentPersonMenuButton from "./CurrentPersonMenuButton";
import CurrentPersonMenuPopup from "./CurrentPersonMenuPopup";

type Props = {
  className?: string;
};

const CurrentPersonMenu = ({ className }: Props): JSX.Element => {
  const [open, setOpen] = useState(false);
  const [anchorElement, setAnchorElement] = useState<HTMLElement | null>(null);

  const handleToggleMenu = (): void => {
    setOpen(open => !open);
  };

  return (
    <>
      <CurrentPersonMenuButton
        className={className}
        ref={setAnchorElement}
        open={open}
        onClick={handleToggleMenu}
      />
      <CurrentPersonMenuPopup
        open={open}
        anchorElement={anchorElement}
        onClose={handleToggleMenu}
      />
    </>
  );
};

export default CurrentPersonMenu;
