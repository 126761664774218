import { NETWORK_CODES } from "~/constants/apiInteraction";
import AuthService from "~/services/AuthService";
import getGraphQLErrorCode from "~/utils/graphql/errors/getGraphQLErrorCode";
import notEmpty from "~/utils/notEmpty";

import { useLogoutMutation } from "./graphql/logoutMutation.generated";

type UseLogoutResult = () => Promise<void>;

export default function useLogout(): UseLogoutResult {
  const [, logoutMutation] = useLogoutMutation();

  return async (): Promise<void> => {
    const { error } = await logoutMutation({});
    const hasLogoutError = Boolean(error);
    const logoutErrorStatus = getGraphQLErrorCode(error);
    const isLogoutErrorUnauthorized = notEmpty(logoutErrorStatus)
      ? [NETWORK_CODES.forbidden, NETWORK_CODES.unauthorized].includes(
          logoutErrorStatus
        )
      : false;

    /**
     * Reset client resources only if logout were successful, or we've got authorized error
     * It means that we already unauthenticated
     */
    if (!hasLogoutError || isLogoutErrorUnauthorized) {
      AuthService.resetClientOnLogout();
    }
  };
}
