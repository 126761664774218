import { ElementType, Join } from "~/declarations/common";
import { CustomPaletteOptions } from "~/declarations/theme";

import { SHADES_GRADE } from "./createPalette";

export type ShadesGrade = Record<ElementType<typeof SHADES_GRADE>, string>;

export enum CustomBreakpoint {
  xxs = "xxs",
  xs = "xs",
  sm = "sm",
  md = "md",
  lg = "lg",
  xl = "xl",
  xxl = "xxl"
}

export enum ThemeMode {
  Light = "light",
  Dark = "dark"
}

export type WithoutGradient<T> = Exclude<T, "gradient">;

export type PickFlatColorsFromPalette<T extends keyof CustomPaletteOptions> =
  Join<T, WithoutGradient<keyof CustomPaletteOptions[T]>>;
