import { createContext } from "react";

import { DTOTheme } from "~/declarations/graphql/types";
import createUseContext from "~/utils/createUseContext";

export type ThemeToggleHandler = (value: DTOTheme) => void;

type AppThemeContextState = {
  setThemeOption: ThemeToggleHandler;
  themeOption: DTOTheme | null;
};

export const AppThemeContext = createContext<AppThemeContextState | undefined>(
  undefined
);

export const useAppThemeContext = createUseContext(
  AppThemeContext,
  "AppThemeContext"
);
