import styled from "@emotion/styled";

import AppLayoutHeaderActionsButtonsUnauthenticated from "../AppLayoutHeaderActionsButtons/AppLayoutHeaderActionsButtonsUnauthenticated";
import AppLayoutHeaderActionsMenuLinks from "./AppLayoutHeaderActionsMenuLinks";

type Props = {
  onClose: () => void;
};

const AppLayoutHeaderActionsMenuUnauthorized = ({
  onClose
}: Props): JSX.Element => (
  <>
    <MenuLinks onClose={onClose} />
    <ActionWrapper>
      <AppLayoutHeaderActionsButtonsUnauthenticated
        onClose={onClose}
        buttonSize="large"
      />
    </ActionWrapper>
  </>
);

export default AppLayoutHeaderActionsMenuUnauthorized;

const MenuLinks = styled(AppLayoutHeaderActionsMenuLinks)`
  flex: 1;
  justify-content: center;
`;

const ActionWrapper = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing(1)};

  & > * {
    flex: 1;
  }
`;
