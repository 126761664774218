export default function notEmpty<TValue>(
  value: TValue | null | undefined
): value is TValue {
  if (value === null || value === undefined) {
    return false;
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const testDummy: TValue = value;

  return true;
}
