import { cacheExchange } from "@urql/exchange-graphcache";
import { IntrospectionQuery } from "graphql";

import resolvers from "./resolvers";
import schema from "./schema.json";
import mutations from "./updates/mutations";

export default cacheExchange({
  /* Type inference for the JSON doesn't work well */
  schema: schema as unknown as IntrospectionQuery,
  updates: {
    Mutation: mutations
  },
  optimistic: {},
  resolvers,
  keys: {
    AttachmentImage: () => null,
    AttachmentImageThumbnail: () => null,
    AttachmentImageThumbnails: () => null
  }
});
