import { CombinedError } from "urql";

import { NETWORK_CODES } from "~/constants/apiInteraction";

const ERROR_CODES_TO_RETRY = [NETWORK_CODES.internalServerError];

export default {
  initialDelayMs: 1000,
  maxDelayMs: 15000,
  randomDelay: true,
  maxNumberAttempts: 2,
  retryIf: (error: CombinedError) =>
    error.graphQLErrors.some(err =>
      ERROR_CODES_TO_RETRY.includes(err.extensions?.errorCode as number)
    )
};
