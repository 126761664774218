import styled from "@emotion/styled";
import Button from "@mui/material/Button";
import ListItemIcon from "@mui/material/ListItemIcon";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import NextLink from "next/link";
import { MouseEventHandler, ReactNode } from "react";

import { IconVariant } from "~/components/core/Icon/declarations";
import Icon from "~/components/core/Icon/Icon";

type Props = {
  href?: string;
  icon?: IconVariant;
  label?: ReactNode;
  className?: string;
  onClick?: MouseEventHandler;
};

const CurrentPersonMenuItem = ({
  href,
  icon,
  label,
  className,
  onClick
}: Props): JSX.Element => (
  <MenuItem
    className={className}
    component={StyledButton}
    href={href}
    onClick={onClick}
  >
    {icon && (
      <MenuIcon>
        <Icon variant={icon} size={24} />
      </MenuIcon>
    )}
    <Typography variant="titleTertiary">{label}</Typography>
  </MenuItem>
);

export default CurrentPersonMenuItem;

const MenuIcon = styled(ListItemIcon)`
  &&& {
    min-width: unset;
  }
`;

const StyledButton = styled(Button)`
  && {
    border-radius: 8px;
    gap: ${({ theme }) => theme.spacing(3)};
    padding: ${({ theme }) => theme.spacing(2)};
  }
`;
StyledButton.defaultProps = {
  variant: "text",
  LinkComponent: NextLink
};
