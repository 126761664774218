/* eslint-disable cleo/no-deep-imports */
import gql from "graphql-tag";
import * as Urql from "urql";

import * as Types from "../../../../declarations/graphql/types";
import {
  AvatarFragmentDoc,
  DTOAvatarFragment
} from "../../../../graphql/avatarFragment.generated";
import {
  DTOPersonBaseFragment,
  PersonBaseFragmentDoc
} from "../../../../graphql/personBaseFragment.generated";

export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type DTOMeQueryVariables = Types.Exact<{ [key: string]: never }>;

export type DTOMeQuery = {
  __typename?: "Query";
  me: {
    __typename?: "Person";
    id: string;
    isCreator: boolean;
    isAdmin: boolean;
    source?: Types.DTOPersonSource | null;
    email: string;
  } & DTOPersonBaseFragment;
};

export const MeQueryDocument = gql`
  query MeQuery {
    me {
      ...PersonBaseFragment
      id
      isCreator
      isAdmin
      source
      email
    }
  }
  ${PersonBaseFragmentDoc}
  ${AvatarFragmentDoc}
`;

export function useMeQuery(
  options?: Omit<Urql.UseQueryArgs<DTOMeQueryVariables>, "query">
) {
  return Urql.useQuery<DTOMeQuery, DTOMeQueryVariables>({
    query: MeQueryDocument,
    ...options
  });
}
