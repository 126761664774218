import { IconSize } from "~/components/core/Icon/declarations";

import {
  IconButtonBrandColor,
  IconButtonColor,
  IconButtonColors,
  IconButtonSize
} from "./declarations";

export const ICON_SIZE_MAP: Record<IconButtonSize, IconSize> = {
  "72": 24,
  "56": 24,
  "48": 20,
  "40": 20,
  "32": 20,
  "28": 20,
  "24": 16
};

export const COLOR_MAP: Record<
  Exclude<IconButtonColor, IconButtonBrandColor>,
  IconButtonColors
> = {
  grey: {
    background: {
      active: "background.quinary",
      regular: "background.septenary",
      disabled: "background.quinary",
      hover: "background.senary"
    },
    icon: {
      regular: "background.primary",
      disabled: "background.tertiary"
    }
  },
  contrast: {
    background: {
      active: "background.primary",
      regular: "background.primary",
      disabled: "background.quinary",
      hover: "background.secondary"
    },
    icon: {
      regular: "brandGreen.lightMax",
      disabled: "background.tertiary"
    }
  },
  transparent: {
    background: {
      active: "transparent.direct.0",
      regular: "transparent.direct.0",
      disabled: "transparent.direct.0",
      hover: "brandGreen.lightMax"
    },
    icon: {
      regular: "background.primary",
      disabled: "background.tertiary"
    }
  }
};
