import NoSsr from "@mui/material/NoSsr";

import AppLayoutHeaderActionsButtons from "./AppLayoutHeaderActionsButtons";
import AppLayoutHeaderActionsMenu from "./AppLayoutHeaderActionsMenu";

const AppLayoutHeaderActions = (): JSX.Element => (
  <>
    <NoSsr>
      <AppLayoutHeaderActionsButtons />
      <AppLayoutHeaderActionsMenu />
    </NoSsr>
  </>
);

export default AppLayoutHeaderActions;
