import { css, Global, SerializedStyles, Theme } from "@emotion/react";

import { TEXT_COLORS } from "~/theme/createPalette";

const globalStyles =
  (fontFamily: string) =>
  (theme: Theme): SerializedStyles =>
    css`
      html,
      body,
      #__next {
        height: 100%;
        font-family: ${fontFamily};
      }

      body {
        margin-right: calc(-1 * (100vw - 100%));
      }

      main {
        min-height: 100%;
      }

      * {
        scrollbar-color: ${theme.palette.background.senary}
          ${theme.palette.background.octonary};
        scrollbar-width: thin;
      }

      *::-webkit-scrollbar {
        width: 7px;
      }

      *::-webkit-scrollbar-track {
        background-color: ${theme.palette.background.octonary};
        border-radius: 3px;
      }

      *::-webkit-scrollbar-thumb {
        background-color: ${theme.palette.background.senary};
        border-radius: 3px;
      }
      // To fix styles of web3-react popups
      html > body {
        background-color: ${theme.palette.background.octonary}!important;
        min-width: 360px;
        scrollbar-color: ${theme.palette.background.senary}
          ${theme.palette.background.octonary};
        scrollbar-width: thin;

        overflow-anchor: none;
        overflow-x: hidden;
        // TODO: 1655644759 Investigate the problem of scrollbars overlapping some content
        margin-right: calc(-1 * (100vw - 100%)) !important;

        /* Prevent material to break scroll hack */
        padding: 0 !important;

        &::-webkit-scrollbar {
          width: 10px;
        }

        &::-webkit-scrollbar-track {
          background-color: ${theme.palette.background.octonary};
          border-radius: 5px;
        }

        &::-webkit-scrollbar-thumb {
          background-color: ${theme.palette.background.senary};
          border-radius: 5px;
        }
      }
      // fixes color of toggles in wallet connect popup of web3-react package.
      .walletconnect-modal__mobile__toggle a {
        color: ${TEXT_COLORS.dark.quaternary};
      }
      svg {
        fill: currentColor;
      }
      a {
        all: unset;
        cursor: pointer;
      }
      input:-webkit-autofill {
        -webkit-text-fill-color: ${theme.palette.text.primary};
        transition-delay: 50000s;
        transition-property: background-color;
      }
    `;

const GlobalStyle = ({ fontFamily }: { fontFamily: string }): JSX.Element => (
  <Global styles={globalStyles(fontFamily)} />
);

export default GlobalStyle;
