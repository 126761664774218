import nookies from "nookies";
import {
  MutableRefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";

import { ThemeToggleHandler } from "~/components/providers/AppStylesProvider/AppThemeContext";
import useChangeThemeAfterFirstRender from "~/components/providers/AppStylesProvider/useChangeThemeAfterFirstRender";
import { useSystemThemeDetector } from "~/components/providers/AppStylesProvider/useSystemThemeDetector";
import { THEME_MODE_COOKIE } from "~/constants/common";
import { DTOTheme } from "~/declarations/graphql/types";
import { CustomTheme } from "~/declarations/theme";
import { COOKIE_OPTIONS } from "~/services/AuthService/constants";
import { ThemeMode } from "~/theme/declarations";
import createTheme from "~/theme/theme";

const THEME_MODE_BY_THEME_OPTION: {
  [key in Exclude<DTOTheme, DTOTheme.SystemDefault>]: ThemeMode;
} = {
  [DTOTheme.Light]: ThemeMode.Light,
  [DTOTheme.Dark]: ThemeMode.Dark
};

const getInitialThemeNoConsiderSystemTheme = (
  isStaticPage: boolean,
  themeOption: DTOTheme | null,
  themeModeCookie?: ThemeMode
): ThemeMode => {
  if (isStaticPage) {
    return ThemeMode.Light;
  }

  if (themeOption && themeOption !== DTOTheme.SystemDefault) {
    return THEME_MODE_BY_THEME_OPTION[themeOption];
  }

  return themeModeCookie ?? ThemeMode.Light;
};

const getCorrectInitialTheme = (
  isStaticPage: boolean,
  themeOption: DTOTheme | null,
  systemTheme: ThemeMode | null,
  themeModeCookieClient?: MutableRefObject<ThemeMode | undefined>
): ThemeMode => {
  if (isStaticPage) {
    const themeModeCookieValue = themeModeCookieClient?.current;

    return themeModeCookieValue ?? systemTheme ?? ThemeMode.Light;
  }

  if (themeOption && themeOption !== DTOTheme.SystemDefault) {
    const selectedTheme = THEME_MODE_BY_THEME_OPTION[themeOption];
    if (selectedTheme !== undefined) {
      return selectedTheme;
    }
  }

  return systemTheme ?? ThemeMode.Light;
};

type Props = {
  themeModeCookie?: ThemeMode;
  initialThemeOption?: DTOTheme;
};

type UseAppThemeResult = {
  theme: CustomTheme;
  themeOption: DTOTheme | null;
  setThemeOption: ThemeToggleHandler;
};

const useAppTheme = ({
  themeModeCookie,
  initialThemeOption
}: Props): UseAppThemeResult => {
  const systemTheme = useSystemThemeDetector();
  const [themeOption, setThemeOption] = useState<DTOTheme | null>(
    initialThemeOption ?? null
  );
  const isStaticPage = !themeModeCookie && !initialThemeOption;
  const [themeMode, setThemeMode] = useState<ThemeMode>(
    getInitialThemeNoConsiderSystemTheme(
      isStaticPage,
      themeOption,
      themeModeCookie
    )
  );
  const themeModeCookieClient = useRef<ThemeMode | undefined>(
    process.env.NEXT_PUBLIC_ENABLE_SETTINGS_PERSONALIZATION === "true"
      ? (nookies.get()[THEME_MODE_COOKIE] as ThemeMode | undefined)
      : ThemeMode.Light
  );

  const needChangeThemeAfterFirstRender = useChangeThemeAfterFirstRender(
    getInitialThemeNoConsiderSystemTheme(
      isStaticPage,
      themeOption,
      themeModeCookie
    ),
    getCorrectInitialTheme(
      isStaticPage,
      themeOption,
      systemTheme,
      themeModeCookieClient
    )
  );

  const theme = useMemo(() => createTheme(themeMode), [themeMode]);

  const getThemeModeByOption = useCallback(
    (themeOption: DTOTheme | null, systemTheme: ThemeMode | null): void => {
      if (isStaticPage || themeOption === null) {
        return;
      }

      let selectedThemeMode;

      if (themeOption === DTOTheme.SystemDefault) {
        selectedThemeMode =
          process.env.NEXT_PUBLIC_ENABLE_SETTINGS_PERSONALIZATION === "true"
            ? systemTheme ?? ThemeMode.Light
            : ThemeMode.Light;
      } else {
        selectedThemeMode = THEME_MODE_BY_THEME_OPTION[themeOption];
      }

      setThemeMode(selectedThemeMode);
    },
    [isStaticPage]
  );

  useEffect(() => {
    if (needChangeThemeAfterFirstRender) {
      setThemeMode(
        getCorrectInitialTheme(
          isStaticPage,
          themeOption,
          systemTheme,
          themeModeCookieClient
        )
      );
    }
  }, [needChangeThemeAfterFirstRender, isStaticPage, themeOption, systemTheme]);

  // For scenario when first page was without pageProps.themeOption, then you navigate to page with pageProps.themeOption
  useEffect(() => {
    setThemeOption(initialThemeOption ?? null);
  }, [initialThemeOption]);

  useEffect(() => {
    nookies.set(null, THEME_MODE_COOKIE, themeMode, COOKIE_OPTIONS);
  }, [themeMode]);

  useEffect(() => {
    getThemeModeByOption(themeOption, systemTheme);
  }, [themeOption, systemTheme, getThemeModeByOption]);

  return {
    theme,
    themeOption,
    setThemeOption
  };
};

export default useAppTheme;
