import { useTheme } from "@emotion/react";
import Head from "next/head";

import AppleTouchIcon from "~/public/assets/meta/apple-touch-icon.png";
import FaviconIco from "~/public/assets/meta/favicon.ico";
import Favicon16 from "~/public/assets/meta/favicon-16x16.png";
import Favicon32 from "~/public/assets/meta/favicon-32x32.png";

const AppHead = (): JSX.Element => {
  const theme = useTheme();

  return (
    <Head>
      <meta name="robots" content="noodp,noydir" />
      <meta name="google" content="notranslate" />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"
      />
      <link rel="apple-touch-icon" sizes="180x180" href={AppleTouchIcon.src} />
      <link rel="icon" type="image/png" sizes="32x32" href={Favicon32.src} />
      <link rel="icon" type="image/png" sizes="16x16" href={Favicon16.src} />
      <link rel="manifest" href="/manifest.json" />
      <link rel="shortcut icon" href={FaviconIco.src} />
      <meta
        name="msapplication-TileColor"
        content={theme.palette.common.white}
      />
      <meta
        name="msapplication-config"
        content="/assets/meta/browserconfig.xml"
      />
      <meta name="theme-color" content={theme.palette.background.octonary} />
    </Head>
  );
};

export default AppHead;
