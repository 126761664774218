import { devtoolsExchange } from "@urql/devtools";
import { authExchange } from "@urql/exchange-auth";
import { multipartFetchExchange } from "@urql/exchange-multipart-fetch";
import { requestPolicyExchange } from "@urql/exchange-request-policy";
import { retryExchange } from "@urql/exchange-retry";
import { NextPageContext } from "next/types";
import { SSRData, SSRExchange } from "next-urql";
import {
  Client,
  createClient,
  dedupExchange,
  errorExchange,
  Exchange,
  ssrExchange
} from "urql";

import {
  SubscriptionDisconnectedHandler,
  SubscriptionReconnectedHandler
} from "~/components/providers/UrqlProvider/declarations";
import { createSubscription } from "~/components/providers/UrqlProvider/subscriptionExchange";
import { isServerSide } from "~/utils/common";

import authExchangeConfig from "./authExchange.config";
import cacheExchange from "./cacheExchange";
import retryExchangeConfig from "./retryExchange.config";

let urqlClient: Client | null = null;
let ssrCache: SSRExchange | null = null;

interface CreateUrqlClient {
  ctx?: NextPageContext;
  initialState?: SSRData;
  suspense?: boolean;
  forceRecreate?: boolean;
  onSubscriptionReconnected?: SubscriptionReconnectedHandler;
  onSubscriptionDisconnected?: SubscriptionDisconnectedHandler;
}

interface CreateExchangesOptions {
  ctx: NextPageContext | undefined;
  ssrExchange: SSRExchange;
  onSubscriptionReconnected?: SubscriptionReconnectedHandler;
  onSubscriptionDisconnected?: SubscriptionDisconnectedHandler;
}

const ENVIRONMENTS_TO_ENABLE_DEBUG = ["development", "local"];

export default function initUrqlClient({
  ctx,
  suspense,
  initialState,
  forceRecreate,
  onSubscriptionReconnected,
  onSubscriptionDisconnected
}: CreateUrqlClient): { urqlClient: Client; ssrCache: SSRExchange | null } {
  if (isServerSide() || !ssrCache || forceRecreate) {
    ssrCache = ssrExchange({ isClient: !isServerSide(), initialState });
  }

  if (isServerSide() || !urqlClient || forceRecreate) {
    const exchanges = createExchanges({
      ssrExchange: ssrCache,
      ctx,
      onSubscriptionReconnected,
      onSubscriptionDisconnected
    });

    urqlClient = createClient({
      url: process.env.NEXT_PUBLIC_API as string,
      fetchOptions: {
        credentials: "include"
      },
      suspense,
      exchanges
    });
  }

  return { urqlClient, ssrCache };
}

const createExchanges = ({
  ctx,
  ssrExchange,
  onSubscriptionReconnected,
  onSubscriptionDisconnected
}: CreateExchangesOptions): Exchange[] => {
  const exchanges: Exchange[] = [
    dedupExchange,
    cacheExchange,
    retryExchange(retryExchangeConfig),
    requestPolicyExchange({}),
    authExchange(authExchangeConfig(ctx)),
    errorExchange({
      onError(error) {
        // TODO: Make error logging;
        error;
      }
    }),
    ssrExchange,
    multipartFetchExchange
  ];

  if (
    process.env.NEXT_PUBLIC_ENVIRONMENT &&
    ENVIRONMENTS_TO_ENABLE_DEBUG.includes(process.env.NEXT_PUBLIC_ENVIRONMENT)
  ) {
    exchanges.unshift(devtoolsExchange);
  }

  if (!isServerSide()) {
    const { exchange } = createSubscription({
      onSubscriptionDisconnected,
      onSubscriptionReconnected
    });

    exchanges.push(exchange);
  }

  return exchanges;
};
