import styled from "@emotion/styled";

import BalanceButton from "~/components/payments/balance/BalanceButton";
import CurrentPersonMenu from "~/components/person/CurrentPersonMenu/CurrentPersonMenu";

const AppLayoutHeaderActionsButtonsAuthenticated = (): JSX.Element => (
  <>
    <BalanceButton />
    <PersonMenu />
  </>
);

export default AppLayoutHeaderActionsButtonsAuthenticated;

const PersonMenu = styled(CurrentPersonMenu)`
  margin-left: ${({ theme }) => theme.spacing(1)};
`;
