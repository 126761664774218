import { css, useTheme } from "@emotion/react";
import styled from "@emotion/styled";

import ROUTES from "~/constants/routes";
import { safeDynamicImport } from "~/utils/import/dynamic";

import WithLink from "../WithLink";

const LogoImage = safeDynamicImport(
  () => import("~/public/assets/images/Logo.svg"),
  {
    ssr: false
  }
);

const LogoMonochromeLightImage = safeDynamicImport(
  () => import("~/public/assets/images/LogoMonochromeLight.svg"),
  {
    ssr: false
  }
);

type LogoFullSizeStyles = {
  width: number;
  height: number;
};

type LogoSize = "xxl" | "xl" | "l" | "m" | "s";

const LOGO_FULL_SIZES: Record<LogoSize, LogoFullSizeStyles> = {
  xxl: {
    width: 104,
    height: 64
  },
  xl: {
    width: 79,
    height: 48
  },
  l: {
    width: 65,
    height: 40
  },
  m: {
    width: 59,
    height: 36
  },
  s: {
    width: 39,
    height: 24
  }
};

interface LogoFullProps {
  withLink?: boolean;
  size?: LogoSize;
  className?: string;
  variant?: "monochromeLight" | "color";
  onClick?: () => void;
}

const Logo = ({
  className,
  onClick,
  withLink = true,
  size = "m",
  variant = "color"
}: LogoFullProps): JSX.Element => {
  const theme = useTheme();
  theme.palette.mode;

  return (
    <WithLink
      href={withLink ? ROUTES.index : undefined}
      className={className}
      onClick={onClick}
    >
      <Wrapper size={size}>
        {variant === "color" ? <LogoImage /> : <LogoMonochromeLightImage />}
      </Wrapper>
    </WithLink>
  );
};

const Wrapper = styled.div<{ size: LogoSize }>`
  align-items: center;
  display: flex;
  font-size: 0;

  & > svg {
    width: 100%;
    height: 100%;
  }

  ${({ size }) => {
    const sizeDimensions = LOGO_FULL_SIZES[size];

    return css`
      height: ${sizeDimensions.height}px;
      width: ${sizeDimensions.width}px;
    `;
  }})}
`;

export default Logo;
