import { autocompleteClasses } from "@mui/material/Autocomplete";
import { backdropClasses } from "@mui/material/Backdrop";
import { buttonClasses } from "@mui/material/Button";
import {
  buttonBaseClasses,
  touchRippleClasses
} from "@mui/material/ButtonBase";
import { checkboxClasses } from "@mui/material/Checkbox";
import { inputClasses } from "@mui/material/Input";
import { inputAdornmentClasses } from "@mui/material/InputAdornment";
import { inputBaseClasses } from "@mui/material/InputBase";
import { inputLabelClasses } from "@mui/material/InputLabel";
import { outlinedInputClasses } from "@mui/material/OutlinedInput";
import { radioClasses } from "@mui/material/Radio";
import { Components } from "@mui/material/styles";
import { switchClasses } from "@mui/material/Switch";
import { tabClasses } from "@mui/material/Tab";
import { tooltipClasses } from "@mui/material/Tooltip";
import { pickersDayClasses } from "@mui/x-date-pickers/PickersDay";
import type {} from "@mui/x-date-pickers/themeAugmentation";
import { pickersYearClasses } from "@mui/x-date-pickers/YearPicker";

import { CustomPaletteOptions } from "~/declarations/theme";
import { CUSTOM_BREAKPOINTS } from "~/theme/breakpoints";
import { hexToRgba } from "~/utils/style";

import createPalette from "./createPalette";
import { ThemeMode } from "./declarations";
import { shadows } from "./effects";
import typography from "./typography";

const TextFieldOverrides = (palette: CustomPaletteOptions): Components => ({
  MuiOutlinedInput: {
    styleOverrides: {
      root: {
        borderRadius: 16,
        gap: 4,
        backgroundColor: palette.background.octonary,
        minHeight: 56,
        padding: "0 20px 0",

        [`& .${outlinedInputClasses.notchedOutline}`]: {
          top: 0,
          border: `1px solid ${palette.background.quinary}`
        },

        [`&.${inputLabelClasses.disabled}`]: {
          background: palette.background.senary
        },

        [`&:hover:not(.${outlinedInputClasses.disabled}):not(.${outlinedInputClasses.focused}):not(.${outlinedInputClasses.error}) .${outlinedInputClasses.notchedOutline}`]:
          {
            borderColor: palette.background.quaternary
          },

        [`&.${outlinedInputClasses.disabled} .${outlinedInputClasses.notchedOutline}`]:
          {
            borderColor: palette.background.senary
          },

        [`&.${outlinedInputClasses.disabled} .${inputAdornmentClasses.root} svg`]:
          {
            color: palette.background.tertiary,
            "--color-primary": `${palette.background.tertiary}`
          },

        [`& .${outlinedInputClasses.notchedOutline} legend`]: {
          display: "none"
        },

        [`& .${inputAdornmentClasses.positionStart}`]: {
          marginRight: 12
        },

        [`& .${inputAdornmentClasses.positionEnd}`]: {
          marginLeft: 12
        },

        [`&.${outlinedInputClasses.sizeSmall}`]: {
          padding: "0 16px 0",
          borderRadius: 12,

          [`& .${inputAdornmentClasses.positionStart}`]: {
            marginRight: 8
          },

          [`& .${inputAdornmentClasses.positionEnd}`]: {
            marginLeft: 8
          }
        }
      },
      sizeSmall: {
        minHeight: 48
      },
      inputSizeSmall: {
        lineHeight: "20px"
      },
      input: {
        ...typography.titleTertiary,
        color: palette.text.primary,
        padding: 0,
        height: "100%",

        "&:disabled": {
          color: palette.text.tertiary
        },

        "&::placeholder": {
          color: palette.text.tertiary,
          caretColor: palette.text.tertiary,
          opacity: 1
        }
      }
    }
  },
  MuiInputLabel: {
    styleOverrides: {
      root: {
        ...typography.paragraphQuaternary,
        color: palette.text.primary,
        transform: "translate(0, 0) scale(1)",

        [`&.${inputLabelClasses.focused}`]: {
          color: palette.text.primary
        },

        [`+.${inputClasses.root}`]: {
          marginTop: 0
        },

        [`&.${inputLabelClasses.disabled}`]: {
          color: palette.text.tertiary
        },

        [`&.${inputLabelClasses.error}`]: {
          color: palette.text.primary
        }
      },
      outlined: {
        position: "static",
        paddingBottom: 4
      }
    }
  }
});

const AutocompleteOverrides = (): Components => ({
  MuiAutocomplete: {
    styleOverrides: {
      root: {
        [`& .${autocompleteClasses.inputRoot}.${inputBaseClasses.root}`]: {
          paddingRight: 0
        },
        [`& .${inputClasses.root} .${inputClasses.input}`]: {
          padding: "28px 0 16px"
        }
      }
    }
  }
});

const DividerOverrides = (palette: CustomPaletteOptions): Components => ({
  MuiDivider: {
    styleOverrides: {
      root: {
        borderColor: palette.background.septenary
      }
    }
  }
});

const PaperProps = (palette: CustomPaletteOptions): Components => ({
  MuiPaper: {
    defaultProps: {
      elevation: 2
    },
    styleOverrides: {
      root: {
        background: palette.background.octonary
      }
    }
  }
});

const DialogOverrides = (): Components => ({
  MuiDialog: {
    styleOverrides: {
      root: {
        [`& .${backdropClasses.root}`]: {
          backgroundColor: "rgba(0, 0, 0, 0.72)"
        }
      },
      paper: {
        margin: 0,
        borderRadius: 0,
        [CUSTOM_BREAKPOINTS.up("sm")]: {
          borderRadius: 32
        }
      }
    }
  }
});

const PopoverOverrides = (palette: CustomPaletteOptions): Components => ({
  MuiPopover: {
    styleOverrides: {
      paper: {
        padding: "10px 20px",
        marginTop: 4,
        boxShadow: shadows().main.down,
        background: palette.background.octonary,
        borderRadius: 24
      }
    }
  }
});

const SwitchOverrides = (
  palette: CustomPaletteOptions
): Pick<Components, "MuiSwitch"> => ({
  MuiSwitch: {
    defaultProps: {
      color: "brandPurple"
    },
    styleOverrides: {
      root: {
        width: 36,
        height: 20,
        padding: 0,

        [`.${switchClasses.thumb}`]: {
          width: 16,
          height: 16,
          background: palette.background.octonary
        },

        [`.${switchClasses.track}`]: {
          borderRadius: 14,
          backgroundColor: palette.background.senary,
          opacity: 1
        },

        [`.${switchClasses.switchBase}`]: {
          width: 16,
          height: 16,
          padding: 0,
          margin: 2,

          [`&.${switchClasses.checked}`]: {
            transform: `translateX(16px)`,

            [` + .${switchClasses.track}`]: {
              backgroundColor: palette.brandGreen.main,
              opacity: 1
            }
          },

          [`&.${switchClasses.disabled}`]: {
            [`.${switchClasses.thumb}`]: {
              background: palette.background.quinary
            },
            [`+ .${switchClasses.track}`]: {
              background: palette.background.septenary
            }
          }
        }
      }
    },
    variants: [
      {
        props: { color: "brandPurple" },
        style: {
          ":hover": {
            [`.${switchClasses.thumb}`]: {
              backgroundColor: palette.brandPurple.main
            }
          },
          [`.${switchClasses.checked}`]: {
            [`.${switchClasses.thumb}`]: {
              backgroundColor: palette.brandPurple.main
            }
          }
        }
      },
      {
        props: { color: "brandGreen" },
        style: {
          ":hover": {
            [`.${switchClasses.thumb}`]: {
              backgroundColor: palette.brandGreen.main
            }
          },
          [`.${switchClasses.switchBase}.${switchClasses.checked}`]: {
            [`.${switchClasses.thumb}`]: {
              backgroundColor: palette.brandGreen.main
            },

            [`+ .${switchClasses.track}`]: {
              backgroundColor: palette.brandPurple.main
            }
          }
        }
      },
      {
        props: { color: "brandBlue" },
        style: {
          ":hover": {
            [`.${switchClasses.thumb}`]: {
              backgroundColor: palette.brandBlue.main
            }
          },
          [`.${switchClasses.checked}`]: {
            [`.${switchClasses.thumb}`]: {
              backgroundColor: palette.brandBlue.main
            }
          }
        }
      },
      {
        props: { color: "brandRed" },
        style: {
          ":hover": {
            [`.${switchClasses.thumb}`]: {
              backgroundColor: palette.brandRed.main
            }
          },
          [`.${switchClasses.checked}`]: {
            [`.${switchClasses.thumb}`]: {
              backgroundColor: palette.brandRed.main
            }
          }
        }
      },
      {
        props: { color: "brandYellow" },
        style: {
          ":hover": {
            [`.${switchClasses.thumb}`]: {
              backgroundColor: palette.brandYellow.main
            }
          },
          [`.${switchClasses.checked}`]: {
            [`.${switchClasses.thumb}`]: {
              backgroundColor: palette.brandYellow.main
            }
          }
        }
      }
    ]
  }
});

const SkeletonOverrides = (): Components => ({
  MuiSkeleton: {
    defaultProps: {
      variant: "rectangular",
      animation: "wave"
    }
  }
});

const DrawerOverrides = (palette: CustomPaletteOptions): Components => ({
  MuiDrawer: {
    styleOverrides: {
      paper: {
        padding: 16,
        background: palette.background.septenary,
        overflowX: "hidden"
      }
    }
  }
});

const DatePickerOverride = (palette: CustomPaletteOptions): Components => ({
  MuiCalendarPicker: {
    styleOverrides: {
      root: {
        backgroundColor: palette.background.septenary,
        scrollbarGutter: "stable",
        paddingLeft: 7,
        boxSizing: "border-box",
        maxHeight: "initial",
        height: 340
      },
      viewTransitionContainer: {
        backgroundColor: palette.background.septenary
      }
    }
  },
  MuiPickersCalendarHeader: {
    styleOverrides: {
      root: {
        margin: "24px 0",
        position: "relative",
        width: "100%",
        boxSizing: "border-box"
      },
      labelContainer: {
        margin: "0 auto",
        zIndex: 1
      },
      label: {
        ...typography.titleSecondary
      }
    }
  },
  MuiPickersArrowSwitcher: {
    styleOverrides: {
      root: {
        position: "absolute",
        justifyContent: "space-between",
        width: 260,
        left: "50%",
        transform: "translateX(-50%)"
      },
      button: {
        width: 20,
        height: 20,
        padding: 0,
        border: `1.5px solid ${palette.background.primary}`,

        "& svg": {
          width: 18,
          height: 18
        }
      }
    }
  },
  MuiMonthPicker: {
    styleOverrides: {
      root: {
        width: "100%"
      }
    }
  },
  MuiDayPicker: {
    styleOverrides: {
      weekDayLabel: {
        marginBottom: 4,
        ...typography.paragraphSecondary
      },
      weekContainer: {
        marginBottom: 4,

        [`& .${pickersDayClasses.root}`]: {
          background: "initial",
          position: "relative",
          ...typography.paragraphSecondary
        },

        [`& .${pickersDayClasses.today}`]: {
          "&:after": {
            content: "''",
            position: "absolute",
            bottom: 0,
            width: 4,
            height: 4,
            backgroundColor: palette.background.primary,
            borderRadius: "50%"
          }
        },

        [`& .${pickersDayClasses.root}.${pickersDayClasses.today}`]: {
          border: "none",
          background: "initial"
        }
      },
      slideTransition: {
        minHeight: 210
      }
    }
  },
  MuiCalendarOrClockPicker: {
    styleOverrides: {
      root: {
        [`& > div`]: {
          width: "initial",
          maxHeight: "initial",
          height: 340
        }
      }
    }
  },
  MuiYearPicker: {
    styleOverrides: {
      root: {
        maxHeight: 262,

        "&::-webkit-scrollbar-track": {
          backgroundColor: palette.background.septenary
        },

        "&::-webkit-scrollbar-thumb": {
          backgroundColor: palette.background.senary
        },

        [`& .${pickersYearClasses.root}`]: {
          flexBasis: "33.33%"
        }
      }
    }
  }
});

const AccordionOverrides = (): Components => ({
  MuiAccordion: {
    styleOverrides: {
      root: {
        boxShadow: "unset",

        "&::before": {
          display: "none"
        }
      }
    }
  }
});

const TooltipOverrides = (
  palette: CustomPaletteOptions
): Pick<Components, "MuiTooltip"> => ({
  MuiTooltip: {
    defaultProps: {
      // Timeout is needed every time to prevent scroll bug when tooltip stays visible in a list on quick scroll
      enterNextDelay: 100,
      arrow: true
    },
    styleOverrides: {
      tooltip: {
        maxWidth: "unset",
        backgroundColor: palette.background.primary,
        borderRadius: 8,
        padding: "8px 16px",

        [`&.${tooltipClasses.tooltipPlacementBottom} .${tooltipClasses.arrow}, &.${tooltipClasses.tooltipPlacementTop} .${tooltipClasses.arrow}`]:
          {
            width: 12,
            height: 4,
            marginTop: -4,
            marginBottom: -4,
            ["&::before"]: {
              transform: "scaleX(2.1) scaleY(1.4) rotate(45deg);"
            }
          },

        [`&.${tooltipClasses.tooltipPlacementLeft} .${tooltipClasses.arrow}, &.${tooltipClasses.tooltipPlacementRight} .${tooltipClasses.arrow}`]:
          {
            width: 4,
            height: 12,
            marginRight: -4,
            marginLeft: -4,
            ["&::before"]: {
              transform: "scaleY(2.1) scaleX(1.4) rotate(45deg);"
            }
          }
      },
      arrow: {
        color: palette.background.primary
      }
    }
  }
});

const ButtonOverrides = (
  palette: CustomPaletteOptions
): Pick<Components, "MuiButton"> => ({
  MuiButton: {
    defaultProps: {
      variant: "contained",
      disableFocusRipple: true
    },
    styleOverrides: {
      root: {
        ...typography.buttonTertiary,
        borderRadius: 40
      }
    },
    variants: [
      {
        props: { size: "small" },
        style: {
          padding: "10px 16px"
        }
      },
      {
        props: { size: "medium" },
        style: {
          padding: "14px 24px"
        }
      },
      {
        props: { size: "large" },
        style: {
          padding: "18px 32px",
          [`.${buttonClasses.startIcon}`]: {
            marginRight: 12
          },
          [`.${buttonClasses.endIcon}`]: {
            marginLeft: 12
          }
        }
      },
      {
        props: { size: "extraLarge" },
        style: {
          padding: "22px 32px",
          [`.${buttonClasses.startIcon}`]: {
            marginRight: 12
          },
          [`.${buttonClasses.endIcon}`]: {
            marginLeft: 12
          }
        }
      },

      // CONTAINED
      {
        props: { variant: "contained" },
        style: {
          color: palette.text.quaternary,

          ":disabled": {
            backgroundColor: palette.background.quinary,
            color: palette.text.tertiary,

            [`.${buttonClasses.startIcon} svg, .${buttonClasses.endIcon} svg`]:
              {
                "--color-primary": palette.text.tertiary,
                color: palette.text.tertiary
              }
          },

          [`.${buttonClasses.startIcon} svg, .${buttonClasses.endIcon} svg`]: {
            width: 20,
            height: 20,
            "--color-primary": palette.text.quaternary,
            color: palette.text.quaternary
          }
        }
      },
      {
        props: { variant: "contained", color: "primary" },
        style: {
          backgroundColor: palette.background.primary,

          "not(:disabled):hover": {
            backgroundColor: palette.background.secondary
          }
        }
      },
      {
        props: { variant: "contained", color: "secondary" },
        style: {
          backgroundColor: palette.background.senary,
          color: palette.text.primary,

          "not(:disabled):hover": {
            backgroundColor: palette.background.quinary
          },

          ":disabled": {
            backgroundColor: palette.background.septenary
          },

          [`.${buttonClasses.startIcon} svg, .${buttonClasses.endIcon} svg`]: {
            width: 20,
            height: 20,
            "--color-primary": palette.text.primary,
            color: palette.text.primary
          }
        }
      },
      {
        props: { variant: "contained", color: "brandGreen" },
        style: {
          backgroundColor: palette.brandGreen.main,

          "not(:disabled):hover": {
            backgroundColor: palette.brandGreen.dark
          }
        }
      },
      {
        props: { variant: "contained", color: "brandBlue" },
        style: {
          backgroundColor: palette.brandBlue.main,

          "not(:disabled):hover": {
            backgroundColor: palette.brandBlue.dark
          }
        }
      },
      {
        props: { variant: "contained", color: "brandPurple" },
        style: {
          backgroundColor: palette.brandPurple.main,

          "not(:disabled):hover": {
            backgroundColor: palette.brandPurple.dark
          }
        }
      },
      {
        props: { variant: "contained", color: "brandYellow" },
        style: {
          backgroundColor: palette.brandYellow.main,

          "not(:disabled):hover": {
            backgroundColor: palette.brandYellow.dark
          }
        }
      },
      {
        props: { variant: "contained", color: "brandRed" },
        style: {
          backgroundColor: palette.brandRed.main,

          "not(:disabled):hover": {
            backgroundColor: palette.brandRed.dark
          }
        }
      },
      // OUTLINED
      {
        props: { variant: "outlined" },
        style: {
          color: palette.text.primary,

          "not(:disabled):hover": {
            backgroundColor: "unset"
          },
          ":disabled": {
            backgroundColor: palette.background.septenary,
            color: palette.text.tertiary,
            border: "unset"
          },

          [`.${touchRippleClasses.root}`]: {
            display: "none"
          }
        }
      },
      {
        props: { variant: "outlined", color: "primary" },
        style: {
          borderColor: palette.background.senary,

          "not(:disabled):hover": {
            borderColor: palette.background.quinary
          }
        }
      },
      {
        props: { variant: "outlined", color: "secondary" },
        style: {
          borderColor: palette.background.septenary,

          "not(:disabled):hover": {
            borderColor: palette.background.tertiary
          }
        }
      },
      {
        props: { variant: "outlined", color: "brandGreen" },
        style: {
          borderColor: palette.brandGreen.light,

          "not(:disabled):hover": {
            borderColor: palette.brandGreen.main
          }
        }
      },
      {
        props: { variant: "outlined", color: "brandBlue" },
        style: {
          borderColor: palette.brandBlue.light,

          "not(:disabled):hover": {
            borderColor: palette.brandBlue.main
          }
        }
      },
      {
        props: { variant: "outlined", color: "brandPurple" },
        style: {
          borderColor: palette.brandPurple.light,

          "not(:disabled):hover": {
            borderColor: palette.brandPurple.main
          }
        }
      },
      {
        props: { variant: "outlined", color: "brandYellow" },
        style: {
          borderColor: palette.brandYellow.light,

          "not(:disabled):hover": {
            borderColor: palette.brandYellow.main
          }
        }
      },
      {
        props: { variant: "outlined", color: "brandRed" },
        style: {
          borderColor: palette.brandRed.light,

          "not(:disabled):hover": {
            borderColor: palette.brandRed.main
          }
        }
      },
      // TEXT
      {
        props: { variant: "text" },
        style: {
          padding: 0,
          minWidth: "initial",
          "not(:disabled):hover": {
            background: "none"
          },

          ":disabled": {
            color: palette.background.tertiary
          },

          [`.${touchRippleClasses.root}`]: {
            display: "none"
          }
        }
      },
      {
        props: { variant: "text", size: "small" },
        style: {
          ...typography.buttonQuaternary
        }
      },
      {
        props: { variant: "text", size: "large" },
        style: {
          [`.${buttonClasses.startIcon}`]: {
            marginRight: 8
          },
          [`.${buttonClasses.endIcon}`]: {
            marginLeft: 8
          }
        }
      },
      {
        props: { variant: "text", size: "extraLarge" },
        style: {
          [`.${buttonClasses.startIcon}`]: {
            marginRight: 8
          },
          [`.${buttonClasses.endIcon}`]: {
            marginLeft: 8
          }
        }
      },
      {
        props: { variant: "text", color: "primary" },
        style: {
          color: palette.text.primary,

          "not(:disabled):hover": {
            color: palette.text.secondary
          },

          [`.${buttonClasses.startIcon} svg, .${buttonClasses.endIcon} svg`]: {
            width: 20,
            height: 20,
            "--color-primary": palette.text.secondary,
            color: palette.text.secondary
          }
        }
      },
      {
        props: { variant: "text", color: "secondary" },
        style: {
          color: palette.text.secondary,

          "not(:disabled):hover": {
            color: palette.text.tertiary
          },

          [`.${buttonClasses.startIcon} svg, .${buttonClasses.endIcon} svg`]: {
            width: 20,
            height: 20,
            "--color-primary": palette.text.secondary,
            color: palette.text.secondary
          }
        }
      },
      {
        props: { variant: "text", color: "brandGreen" },
        style: {
          color: palette.brandGreen.main,

          "not(:disabled):hover": {
            color: palette.brandGreen.dark
          },

          [`.${buttonClasses.startIcon} svg, .${buttonClasses.endIcon} svg`]: {
            width: 20,
            height: 20,
            "--color-primary": palette.brandGreen.main,
            color: palette.brandGreen.main
          }
        }
      },
      {
        props: { variant: "text", color: "brandBlue" },
        style: {
          color: palette.brandBlue.main,

          "not(:disabled):hover": {
            color: palette.brandBlue.dark
          },

          [`.${buttonClasses.startIcon} svg, .${buttonClasses.endIcon} svg`]: {
            width: 20,
            height: 20,
            "--color-primary": palette.brandBlue.dark,
            color: palette.brandBlue.dark
          }
        }
      },
      {
        props: { variant: "text", color: "brandPurple" },
        style: {
          color: palette.brandPurple.main,

          "not(:disabled):hover": {
            color: palette.brandPurple.dark
          },

          [`.${buttonClasses.startIcon} svg, .${buttonClasses.endIcon} svg`]: {
            width: 20,
            height: 20,
            "--color-primary": palette.brandPurple.dark,
            color: palette.brandPurple.dark
          }
        }
      },
      {
        props: { variant: "text", color: "brandYellow" },
        style: {
          color: palette.brandYellow.main,

          "not(:disabled):hover": {
            color: palette.brandYellow.dark
          },

          [`.${buttonClasses.startIcon} svg, .${buttonClasses.endIcon} svg`]: {
            width: 20,
            height: 20,
            "--color-primary": palette.brandYellow.dark,
            color: palette.brandYellow.dark
          }
        }
      },
      {
        props: { variant: "text", color: "brandRed" },
        style: {
          color: palette.brandRed.main,

          "not(:disabled):hover": {
            color: palette.brandRed.dark
          },

          [`.${buttonClasses.startIcon} svg, .${buttonClasses.endIcon} svg`]: {
            width: 20,
            height: 20,
            "--color-primary": palette.brandRed.dark,
            color: palette.brandRed.dark
          }
        }
      }
    ]
  }
});

const CheckboxOverrides = (palette: CustomPaletteOptions): Components => ({
  MuiCheckbox: {
    styleOverrides: {
      root: {
        border: `1px solid ${palette.background.quinary}`,
        borderRadius: 8,
        padding: 0,
        [`& .${buttonBaseClasses.focusVisible}`]: {
          boxShadow: `0 0 0 4px ${hexToRgba(palette.brandPurple.main, 0.04)}`
        },
        [`&.${checkboxClasses.checked}`]: {
          backgroundColor: palette.brandPurple.main,
          border: `1px solid ${palette.brandPurple.main}`,

          [`&.${checkboxClasses.disabled}`]: {
            borderColor: palette.background.quaternary,
            background: palette.background.senary
          }
        },
        [`&:not(.${checkboxClasses.checked})`]: {
          [`&.${checkboxClasses.disabled}`]: {
            borderColor: palette.background.quinary,
            background: palette.background.senary
          },
          "&:hover": {
            borderColor: palette.brandPurple.main
          }
        }
      }
    }
  }
});

const RadioButtonsOverrides = (palette: CustomPaletteOptions): Components => ({
  MuiRadio: {
    styleOverrides: {
      root: {
        padding: 0,
        ["& .RadioIcon"]: {
          alignItems: "center",
          borderRadius: "50%",
          boxSizing: "border-box",
          display: "flex",
          justifyContent: "center",
          height: 24,
          width: 24
        },
        [`.${buttonBaseClasses.focusVisible}`]: {
          border: `1px solid ${palette.brandPurple.main}`,
          boxShadow: `0 0 0 4px ${hexToRgba(palette.brandPurple.main, 0.04)}`
        },
        [`&.${radioClasses.checked}`]: {
          ["& .RadioIcon"]: {
            background: palette.brandPurple.main,
            border: `1px solid ${palette.brandPurple.main}`
          },
          [`&.${radioClasses.disabled}`]: {
            ["& .RadioIcon"]: {
              background: palette.background.senary,
              borderColor: palette.background.quaternary
            }
          }
        },

        [`&:not(.${radioClasses.checked})`]: {
          ["& .RadioIcon"]: {
            background: palette.background.octonary,
            border: `1px solid ${palette.background.quaternary}`
          },
          [`&.${radioClasses.disabled}`]: {
            color: palette.background.quinary
          },

          [`&.${radioClasses.disabled}`]: {
            ["& .RadioIcon"]: {
              background: palette.background.senary,
              borderColor: palette.background.quaternary
            }
          },

          ":hover": {
            ["& .RadioIcon"]: {
              borderColor: palette.brandPurple.main
            }
          }
        }
      }
    }
  }
});

const TabsOverrides = (palette: CustomPaletteOptions): Components => ({
  MuiTabs: {
    styleOverrides: {
      root: {},
      indicator: {
        display: "none"
      }
    }
  },
  MuiTab: {
    styleOverrides: {
      root: {
        ...typography.buttonTertiary,
        background: palette.static.background.light.senary,
        color: palette.static.text.light.primary,
        borderRadius: 48,

        [`&.${tabClasses.selected}`]: {
          background: palette.brandPurple.main,
          color: palette.static.text.light.quaternary
        }
      }
    }
  }
});

export default (mode: ThemeMode): Components => {
  const palette = createPalette(mode);

  return {
    ...AutocompleteOverrides(),
    ...AccordionOverrides(),
    ...TextFieldOverrides(palette),
    ...DividerOverrides(palette),
    ...DialogOverrides(),
    ...PaperProps(palette),
    ...SwitchOverrides(palette),
    ...PopoverOverrides(palette),
    ...SkeletonOverrides(),
    ...DrawerOverrides(palette),
    ...DatePickerOverride(palette),
    ...TooltipOverrides(palette),
    ...ButtonOverrides(palette),
    ...CheckboxOverrides(palette),
    ...RadioButtonsOverrides(palette),
    ...TabsOverrides(palette)
  };
};
