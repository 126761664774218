import { ReactNode, useState } from "react";

import { DialogsContext, DialogWithOptions } from "./DialogsContext";
import DialogsManager from "./DialogsManager";

type Props = {
  children?: ReactNode;
};

const DialogsProvider = ({ children }: Props): JSX.Element => {
  const [currentDialog, setCurrentDialog] = useState<DialogWithOptions | null>(
    null
  );

  return (
    <DialogsContext.Provider value={{ currentDialog, setCurrentDialog }}>
      <DialogsManager />
      {children}
    </DialogsContext.Provider>
  );
};

export default DialogsProvider;
