export enum LoggingTagFlow {
  SignUp = "Sign up",
  PageGuarding = "Page guarding",
  Mapping = "Mapping",
  Analytics = "Analytics",
  PasswordReset = "Password reset",
  Audio = "Audio",
  FeatureCheck = "Feature check",
  DynamicImport = "Dynamic import",
  CheckingBreakpoint = "Checking breakpoint",
  CopyingToClipboard = "Copying to clipboard"
}
