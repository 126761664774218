import { NextPageContext } from "next";
import nookies from "nookies";
import qs from "query-string";

import { NETWORK_CODES } from "~/constants/apiInteraction";
import { Events } from "~/constants/events";
import { isServerSide } from "~/utils/common";
import { redirect } from "~/utils/redirect";

import {
  ACCESS_TOKEN_NAME,
  COOKIE_OPTIONS,
  FOREVER_COOKIE_LIFETIME_MILLISECONDS,
  LOCAL_STORAGE_LOGIN_AT_KEY,
  LOCAL_STORAGE_LOGOUT_AT_KEY,
  LOCAL_STORAGE_RELOAD_AFTER_LOGOUT_DELAY
} from "./constants";

let accessToken: string | null = null;
let logoutAt: string | null = null;
let loginAt: string | null = null;

const hasLogoutMark = (): boolean =>
  Boolean(logoutAt && localStorage.getItem(LOCAL_STORAGE_LOGOUT_AT_KEY));

const hasLoginMark = (): boolean =>
  Boolean(loginAt && localStorage.getItem(LOCAL_STORAGE_LOGIN_AT_KEY));

const isAuthorizedClient = (): boolean => Boolean(accessToken);

const isAuthorizedServer = (context: NextPageContext): boolean =>
  Boolean(getAccessTokenFromPageContext(context));

const getAccessTokenFromPageContext = (context?: NextPageContext): string =>
  context ? nookies.get(context)[ACCESS_TOKEN_NAME] : "";

const getAccessToken = (): string => accessToken ?? "";

const setAccessToken = (newAccessToken: string | null): void => {
  accessToken = newAccessToken;
  clearLogoutMark();

  if (!isServerSide() && newAccessToken) {
    const expires = new Date(Date.now() + FOREVER_COOKIE_LIFETIME_MILLISECONDS);

    nookies.set(null, ACCESS_TOKEN_NAME, newAccessToken, {
      ...COOKIE_OPTIONS,
      expires
    });
  }
};

const getAuthorizationHeader = (
  accessTokenForRequest = accessToken
): { authorization: string } => ({
  authorization: `Bearer ${accessTokenForRequest}`
});

const readAccessToken = (): void => {
  try {
    accessToken = nookies.get(null)[ACCESS_TOKEN_NAME];
  } catch (error) {
    /* TODO: Add logic to distinguish between a normal flow and a flow with an error */
    console.error(`JWT reading error: ${error}`);
  }
};

const clearAccessToken = (): void => {
  nookies.destroy(null, ACCESS_TOKEN_NAME, COOKIE_OPTIONS);
  accessToken = null;
};

const clearLoginMark = (): void => {
  if (hasLoginMark()) {
    return;
  }

  loginAt = null;
  localStorage.removeItem(LOCAL_STORAGE_LOGIN_AT_KEY);
};

const clearLogoutMark = (): void => {
  if (!hasLogoutMark()) {
    return;
  }

  logoutAt = null;
  localStorage.removeItem(LOCAL_STORAGE_LOGOUT_AT_KEY);
};

const setLogoutMark = (): void => {
  if (hasLogoutMark()) {
    return;
  }

  logoutAt = Date.now().toString();
  localStorage.setItem(LOCAL_STORAGE_LOGOUT_AT_KEY, logoutAt);
};

const setLoginMark = (): void => {
  if (isServerSide() || hasLoginMark()) {
    return;
  }
  loginAt = Date.now().toString();
  localStorage.setItem(LOCAL_STORAGE_LOGIN_AT_KEY, loginAt);
};

const resetClientOnLogout = (): void => {
  clearAccessToken();
  setLogoutMark();
  window.location.reload();
};

const authorizePage = async ({
  context,
  redirectForAuthorized,
  redirectForUnauthorized
}: {
  context: NextPageContext;
  redirectForAuthorized?: string | null;
  redirectForUnauthorized?: string | null;
}): Promise<boolean> => {
  const authorized = isServerSide()
    ? await isAuthorizedServer(context)
    : await isAuthorizedClient();

  if (authorized) {
    if (redirectForAuthorized) {
      redirect(context, redirectForAuthorized, NETWORK_CODES.found);
    }
    return true;
  }

  if (redirectForUnauthorized) {
    const parsedURL = qs.parseUrl(redirectForUnauthorized, {
      parseFragmentIdentifier: true
    });

    const nextURL = context.asPath ?? context.pathname;
    if (nextURL) {
      parsedURL.query.next = nextURL;
    }

    redirect(context, qs.stringifyUrl(parsedURL), NETWORK_CODES.found);
  }

  return false;
};

const init = (): void => {
  readAccessToken();

  const handleChangeLocalStorage = ({ key, newValue }: StorageEvent): void => {
    if (!newValue) {
      return;
    }

    const logoutInAnotherTab =
      key === LOCAL_STORAGE_LOGOUT_AT_KEY && logoutAt !== newValue;

    const loginInAnotherTab =
      key === LOCAL_STORAGE_LOGIN_AT_KEY && loginAt !== newValue;

    if (logoutInAnotherTab) {
      clearAccessToken();
      clearLoginMark();
    }

    if (loginInAnotherTab) {
      clearLogoutMark();
    }

    if (logoutInAnotherTab || loginInAnotherTab) {
      window.setTimeout(() => {
        window.location.reload();
      }, LOCAL_STORAGE_RELOAD_AFTER_LOGOUT_DELAY);
    }
  };

  if (!isServerSide()) {
    window.addEventListener(
      Events.localStorageChange,
      handleChangeLocalStorage,
      false
    );
  }
};

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const AuthService = () => {
  init();

  return {
    getAccessTokenFromPageContext,
    getAccessToken,
    setAccessToken,
    getAuthorizationHeader,
    isAuthorizedClient,
    isAuthorizedServer,
    setLogoutMark,
    setLoginMark,
    clearLoginMark,
    clearLogoutMark,
    resetClientOnLogout,
    authorizePage
  };
};

export default AuthService();
