import { useEffect, useMemo, useState } from "react";

import { ClaimWaitingRewardDialogPayload } from "~/components/advertisement/claim/ClaimWaitingRewardDialog/description";
import { useWaitingRewardsQuery } from "~/components/advertisement/claim/useWaitingApproveReward/graphql/waitingRewardsQuery.generated";
import { AppDialog } from "~/components/providers/DialogsProvider/constants";
import { useAppDialog } from "~/components/providers/DialogsProvider/useAppDialog";
import { RequestState } from "~/constants/requestState";
import { DTOMyWaitingRewardStatus } from "~/declarations/graphql/types";
import { isEmpty } from "~/utils/common";
import useGraphQLQuery from "~/utils/graphql/useGraphQLQuery/useGraphQLQuery";

const useWaitingApprovedReward = (): void => {
  const [shown, setShown] = useState(false);
  const { open: openClaimWaitingRewardDialog } =
    useAppDialog<ClaimWaitingRewardDialogPayload>(AppDialog.ClaimWaitingReward);

  const waitingRewardsQuery = useGraphQLQuery({
    useQuery: useWaitingRewardsQuery,
    queryKey: "me"
  });

  const firstAdvertisementWithApprovedReward = useMemo(() => {
    if (
      waitingRewardsQuery.type === RequestState.Success &&
      !isEmpty(waitingRewardsQuery.data.waitingRewards)
    ) {
      return waitingRewardsQuery.data.waitingRewards?.find(
        ({ status }) => status === DTOMyWaitingRewardStatus.Active
      );
    }
  }, [waitingRewardsQuery]);

  useEffect(() => {
    if (!shown && firstAdvertisementWithApprovedReward) {
      openClaimWaitingRewardDialog({
        advertisementId: firstAdvertisementWithApprovedReward.post.id
      });

      setShown(true);
    }
  }, [
    firstAdvertisementWithApprovedReward,
    openClaimWaitingRewardDialog,
    shown
  ]);
};

export default useWaitingApprovedReward;
