import styled from "@emotion/styled";
import { forwardRef } from "react";

import Icon from "~/components/core/Icon";

import CurrentPersonMenuTriggerWrapper from "./CurrentPersonMenuTriggerWrapper";

type Props = {
  className?: string;
  open: boolean;
  onClick?: () => void;
};

const CurrentPersonMenuButton = forwardRef<HTMLButtonElement, Props>(
  ({ className, open, onClick }: Props, ref): JSX.Element => (
    <CurrentPersonMenuTriggerWrapper
      ref={ref}
      className={className}
      onClick={onClick}
      endIcon={<OpenIcon variant="chevronDown" open={open} />}
    />
  )
);

export default CurrentPersonMenuButton;

const OpenIcon = styled(Icon, {
  shouldForwardProp: prop => prop !== "open"
})<{
  open: boolean;
}>`
  svg {
    transform: rotate(${({ open }) => (open ? 180 : 0)}deg);
  }
`;
OpenIcon.defaultProps = {
  variant: "chevronUp",
  size: 24
};
