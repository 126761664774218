/* eslint-disable cleo/no-deep-imports */
import gql from "graphql-tag";
import * as Urql from "urql";

import * as Types from "../../../../declarations/graphql/types";

export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type DTOMeQueryVariables = Types.Exact<{ [key: string]: never }>;

export type DTOMeQuery = {
  __typename?: "Query";
  me: {
    __typename?: "Person";
    id: string;
    isCreator: boolean;
    nickname: string;
    avatar?: { __typename?: "Avatar"; middle2x: string } | null;
    multiBalance: { __typename?: "MultiBalance"; cleo: number };
  };
};

export const MeQueryDocument = gql`
  query MeQuery {
    me {
      id
      isCreator
      nickname
      avatar {
        middle2x
      }
      multiBalance {
        cleo
      }
    }
  }
`;

export function useMeQuery(
  options?: Omit<Urql.UseQueryArgs<DTOMeQueryVariables>, "query">
) {
  return Urql.useQuery<DTOMeQuery, DTOMeQueryVariables>({
    query: MeQueryDocument,
    ...options
  });
}
