import { Exchange, subscriptionExchange } from "urql";

import {
  SubscriptionDisconnectedHandler,
  SubscriptionReconnectedHandler
} from "~/components/providers/UrqlProvider/declarations";
import { createRestartableWSClient } from "~/components/providers/UrqlProvider/restartableWSClient";
import { MILLISECONDS_IN_SECOND } from "~/constants/date";
import AuthService from "~/services/AuthService";

export type CreateSubscriptionOptions = {
  onSubscriptionReconnected?: SubscriptionReconnectedHandler;
  onSubscriptionDisconnected?: SubscriptionDisconnectedHandler;
};

export type CreateSubscriptionResult = {
  exchange: Exchange;
  restartSubscription: () => void;
};

const WEB_SOCKET_KEEP_ALIVE_TIMEOUT = 10 * MILLISECONDS_IN_SECOND;

export const createSubscription = ({
  onSubscriptionReconnected,
  onSubscriptionDisconnected
}: CreateSubscriptionOptions = {}): CreateSubscriptionResult => {
  const restartableWSClient = createRestartableWSClient({
    url: process.env.NEXT_PUBLIC_SUBSCRIPTION_API ?? "",
    keepAlive: WEB_SOCKET_KEEP_ALIVE_TIMEOUT,
    lazy: true,
    shouldRetry: () => true,
    retryAttempts: Infinity,
    connectionParams: () => ({
      Authorization: AuthService.getAuthorizationHeader().authorization
    }),
    /* Try to reconnect after all possible errors */
    isFatalConnectionProblem: () => false,
    onReconnected: onSubscriptionReconnected,
    onDisconnected: onSubscriptionDisconnected
  });

  const exchange = subscriptionExchange({
    forwardSubscription: operation => ({
      subscribe: sink => ({
        unsubscribe: restartableWSClient.subscribe(operation, sink)
      })
    })
  });

  const restartSubscription = (): void => {
    restartableWSClient.restart();
  };

  return {
    exchange,
    restartSubscription
  };
};
