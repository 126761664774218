export const REGULAR_FONT_FAMILY =
  'var(--font-poppins), "Roboto", "Helvetica", "Arial", sans-serif';

export default {
  fontFamily: REGULAR_FONT_FAMILY,
  button: {
    textTransform: "none"
  },
  h1: {
    fontSize: 56,
    fontWeight: 600,
    lineHeight: "56px",
    letterSpacing: -0.22
  },
  h2: {
    fontSize: 44,
    fontWeight: 600,
    lineHeight: "44px",
    letterSpacing: -0.22
  },
  h3: {
    fontSize: 32,
    fontWeight: 600,
    lineHeight: "32px",
    letterSpacing: -0.09
  },
  h4: {
    fontSize: 24,
    fontWeight: 600,
    lineHeight: "28px",
    letterSpacing: -0.48
  },
  h5: {
    fontSize: 18,
    fontWeight: 600,
    lineHeight: "24px",
    letterSpacing: -0.09
  },
  h6: {
    fontSize: 16,
    fontWeight: 600,
    lineHeight: "24px",
    letterSpacing: -0.08
  },
  titleMain: {
    fontSize: 18,
    fontWeight: 600,
    lineHeight: "24px",
    letterSpacing: -0.18
  },
  titleSecondary: {
    fontSize: 16,
    fontWeight: 600,
    lineHeight: "22px",
    letterSpacing: -0.16
  },
  titleTertiary: {
    fontSize: 14,
    fontWeight: 600,
    lineHeight: "20px",
    letterSpacing: -0.14
  },
  titleQuaternary: {
    fontSize: 12,
    fontWeight: 600,
    lineHeight: "20px",
    letterSpacing: -0.12
  },
  titleSmallest: {
    fontSize: 10,
    fontWeight: 600,
    lineHeight: "16px",
    letterSpacing: -0.1
  },
  buttonMain: {
    fontSize: 18,
    fontWeight: 500,
    lineHeight: "24px",
    letterSpacing: -0.18
  },
  buttonSecondary: {
    fontSize: 16,
    fontWeight: 500,
    lineHeight: "22px",
    letterSpacing: -0.16
  },
  buttonTertiary: {
    fontSize: 14,
    fontWeight: 500,
    lineHeight: "20px",
    letterSpacing: -0.14
  },
  buttonQuaternary: {
    fontSize: 12,
    fontWeight: 500,
    lineHeight: "20px",
    letterSpacing: -0.12
  },
  buttonSmallest: {
    fontSize: 10,
    fontWeight: 500,
    lineHeight: "16px",
    letterSpacing: -0.1
  },
  paragraphMain: {
    fontSize: 18,
    fontWeight: 400,
    lineHeight: "32px",
    letterSpacing: -0.18
  },
  paragraphSecondary: {
    fontSize: 16,
    fontWeight: 400,
    lineHeight: "30px",
    letterSpacing: -0.16
  },
  paragraphTertiary: {
    fontSize: 14,
    fontWeight: 400,
    lineHeight: "24px",
    letterSpacing: -0.14
  },
  paragraphQuaternary: {
    fontSize: 12,
    fontWeight: 400,
    lineHeight: "20px",
    letterSpacing: -0.12
  },
  paragraphSmallest: {
    fontSize: 10,
    fontWeight: 400,
    lineHeight: "16px",
    letterSpacing: -0.1
  }
};
