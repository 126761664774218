import { css } from "@emotion/react";
import styled from "@emotion/styled";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import {
  SnackbarContent,
  SnackbarMessage as NotistackMessage,
  VariantType
} from "notistack";
import { forwardRef, useCallback } from "react";

import Icon, { IconProps } from "~/components/core/Icon";
import IconButton from "~/components/core/IconButton";
import useSnackbar, {
  ButtonVariant,
  ExtraSnackbarOptions
} from "~/components/providers/SnackbarProvider/useSnackbar";
import { CustomTheme } from "~/declarations/theme";
import { hexToRgba } from "~/utils/style";

const ICON_VARIANTS: {
  [key in VariantType]: Pick<IconProps, "variant" | "color" | "colorSecondary">;
} = {
  default: {
    variant: "info",
    color: "additional.yellow.normal"
  },
  info: {
    variant: "info",
    color: "brandBlue.main"
  },
  warning: {
    variant: "info",
    color: "additional.yellow.normal"
  },
  success: {
    variant: "success",
    color: "success.main"
  },
  error: {
    variant: "info",
    color: "additional.red.normal"
  }
};

export type SnackbarMessageProps = {
  id: string | number;
  title?: NotistackMessage;
  variant?: VariantType;
  className?: string;
} & ExtraSnackbarOptions;

const SnackbarMessage = forwardRef<HTMLDivElement, SnackbarMessageProps>(
  (
    {
      id,
      title,
      description,
      className,
      paperClassName,
      variant = "default",
      actionVariant
    }: SnackbarMessageProps,
    ref
  ) => {
    const { closeSnackbar } = useSnackbar();

    const handleDismiss = useCallback(() => {
      closeSnackbar(id);
    }, [closeSnackbar, id]);

    return (
      <SnackbarContent className={className} ref={ref}>
        <Wrapper
          elevation={0}
          snackbarVariant={variant}
          className={paperClassName}
        >
          <SnackbarMessageIcon {...ICON_VARIANTS[variant]} size={24} />
          <CentralPart>
            {title && (
              <MessageText variant="titleTertiary">{title}</MessageText>
            )}
            {description && (
              <MessageDescription
                variant="paragraphQuaternary"
                gutterBottom={false}
              >
                {description}
              </MessageDescription>
            )}
            {actionVariant && (
              <StyledActionButton
                variant={
                  actionVariant.variant === ButtonVariant.button
                    ? "contained"
                    : "text"
                }
                size={
                  actionVariant.variant === ButtonVariant.button
                    ? "medium"
                    : "small"
                }
                color={
                  actionVariant.variant === ButtonVariant.button
                    ? "primary"
                    : "brandPurple"
                }
                onClick={actionVariant.onClick}
              >
                {actionVariant.text}
              </StyledActionButton>
            )}
          </CentralPart>
          <DismissButton
            onClick={handleDismiss}
            size={28}
            variant="rounded"
            color="transparent"
            icon="close"
            iconColor="background.tertiary"
          />
        </Wrapper>
      </SnackbarContent>
    );
  }
);

SnackbarMessage.displayName = "SnackbarMessage";

export default SnackbarMessage;

const getColorByVariant = (
  theme: CustomTheme,
  variant: VariantType
): string => {
  switch (variant) {
    case "error":
      return theme.palette.error.main;
    case "warning":
      return theme.palette.warning.main;
    case "success":
      return theme.palette.success.main;
    default:
      return theme.palette.background.senary;
  }
};

const Wrapper = styled(Paper)<{ snackbarVariant: VariantType }>`
  align-items: center;
  border-radius: 8px;
  box-shadow: 0 16px 136px 0
    ${({ theme }) => hexToRgba(theme.palette.background.quaternary, 0.8)};
  display: flex;
  min-width: 343px;
  padding: ${({ theme }) => theme.spacing(4)};
  position: relative;

  ${({ theme }) => theme.breakpoints.up("xs")} {
    max-width: 565px;
  }

  ${({ theme, snackbarVariant }) => {
    const color = getColorByVariant(theme, snackbarVariant);
    const isNotCommonVariant = !["default", "info"].includes(snackbarVariant);

    return css`
      border-color: ${color};

      ${isNotCommonVariant &&
      `
        color: ${color};
      `}
    `;
  }};
`;

const SnackbarMessageIcon = styled(Icon)`
  align-self: flex-start;
`;

const CentralPart = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  min-width: 0;
  padding: ${({ theme }) => theme.spacing(0, 3)};
`;

const MessageText = styled(Typography)`
  overflow-wrap: break-word;
  white-space: pre-wrap;
`;

const StyledActionButton = styled(Button)`
  margin-top: ${({ theme }) => theme.spacing(2)};
`;

const MessageDescription = styled(MessageText)`
  margin-top: ${({ theme }) => theme.spacing(1)};
`;

const DismissButton = styled(IconButton)`
  align-self: flex-start;
  margin-left: auto;
  padding: ${({ theme }) => theme.spacing(1)};
`;
