import { createBreakpoints } from "@mui/system";

const CUSTOM_BREAKPOINT_VALUES = {
  values: {
    xxs: 0,
    xs: 600,
    sm: 768,
    md: 1024,
    lg: 1280,
    xl: 1440,
    xxl: 1920,
    xxxl: 2560
  }
};

export default CUSTOM_BREAKPOINT_VALUES;

export const CUSTOM_BREAKPOINTS = createBreakpoints(CUSTOM_BREAKPOINT_VALUES);
