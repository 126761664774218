
    import __i18nConfig from '@next-translate-root/i18n'
    import __appWithI18n from 'next-translate/appWithI18n'
    import { EmotionCache } from "@emotion/react";
import { NextPage } from "next";
import type { AppLayoutProps, AppProps as NextAppProps } from "next/app";
import { SSRData } from "next-urql";
import { ReactNode } from "react";
import AppHead from "~/components/common/AppHead/AppHead";
import AppLayout from "~/components/layouts/AppLayout/AppLayout";
import AppStylesProvider from "~/components/providers/AppStylesProvider";
import AuthenticationContextProvider from "~/components/providers/AuthenticationContextProvider/AuthenticationContextProvider";
import DialogsProvider from "~/components/providers/DialogsProvider";
import SnackbarProvider from "~/components/providers/SnackbarProvider";
import UrqlProvider from "~/components/providers/UrqlProvider/UrqlContextProvider";
import { ThemeMode } from "~/theme/declarations";
import useInitializationVH from "~/utils/useInitializationVH";
export interface AppProps extends NextAppProps {
    emotionCache?: EmotionCache;
    themeModeCookieValue?: ThemeMode;
    urqlState?: SSRData;
}
type NextPageWithLayout = NextPage & AppLayoutProps;
type AppPropsWithLayout = AppProps & {
    Component: NextPageWithLayout;
};
const App = (appProps: AppPropsWithLayout): JSX.Element => {
    const { Component, emotionCache, pageProps } = appProps;
    const { themeModeCookie, themeOption } = pageProps;
    const getLayout = Component.getLayout ?? ((page: ReactNode) => <AppLayout>{page}</AppLayout>);
    useInitializationVH();
    return (<>
      <AppStylesProvider emotionCache={emotionCache} themeModeCookie={themeModeCookie} themeOption={themeOption}>
        <AppHead />
        <SnackbarProvider>
          <UrqlProvider ssrData={pageProps.urqlState}>
            <AuthenticationContextProvider authorized={pageProps.isAuthorize}>
              <DialogsProvider>
                {getLayout(<Component {...pageProps}/>)}
              </DialogsProvider>
            </AuthenticationContextProvider>
          </UrqlProvider>
        </SnackbarProvider>
      </AppStylesProvider>
    </>);
};
const __Next_Translate__Page__18c1550bfc5__ = App;

    export default __appWithI18n(__Next_Translate__Page__18c1550bfc5__, {
      ...__i18nConfig,
      isLoader: true,
      skipInitialProps: true,
      loadLocaleFrom: __i18nConfig.loadLocaleFrom || (() => Promise.resolve({})),
    });
  