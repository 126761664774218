import { css } from "@emotion/css";
import styled from "@emotion/styled";
import Menu, { menuClasses, MenuProps } from "@mui/material/Menu";
import { popoverClasses } from "@mui/material/Popover";
import useTranslation from "next-translate/useTranslation";

import useLogout from "~/components/auth/logout/useLogout/useLogout";
import useAppLayoutMenu from "~/components/layouts/AppLayout/AppLayoutHeader/AppLayoutHeaderActions/AppLayoutHeaderActionsMenu/useAppLayoutMenu";
import CurrentPersonMenuItem from "~/components/person/CurrentPersonMenu/CurrentPersonMenuItem";
import { PersonMenuOption } from "~/components/person/CurrentPersonMenu/declarations";
import { THEME_SPACING } from "~/theme/theme";

type Props = {
  open: boolean;
  anchorElement: MenuProps["anchorEl"];
  onClose?: () => void;
  className?: string;
};

const POPOVER_ROOT_CLASS = css`
  &.${popoverClasses.root} .${menuClasses.paper} {
    border: 0;
    border-radius: 24px;
    padding: ${2 * THEME_SPACING}px;
  }
`.toString();

const MENU_LIST_CLASS = css`
  display: flex;
  flex-direction: column;
  gap: ${THEME_SPACING}px;
`.toString();

const PAPER_CLASS = css`
  && {
    padding: ${2 * THEME_SPACING}px;
  }
`.toString();

const CurrentPersonMenuPopup = ({
  className,
  open,
  anchorElement,
  onClose
}: Props): JSX.Element => {
  const { t } = useTranslation("common");
  const { items, onClickItem } = useAppLayoutMenu();
  const logout = useLogout();

  const handleClickMenuOption = (): void => {
    onClose?.();
  };

  const handleClickLogOut = (): void => {
    handleClickMenuOption();
    logout();
  };

  const handleClickOption = (type: PersonMenuOption) => () => {
    handleClickMenuOption();
    onClickItem(type);
  };

  return (
    <Menu
      className={className}
      open={open}
      anchorEl={anchorElement}
      classes={{
        root: POPOVER_ROOT_CLASS,
        list: MENU_LIST_CLASS,
        paper: PAPER_CLASS
      }}
      marginThreshold={0}
      disableScrollLock
      MenuListProps={{
        disablePadding: true,
        disableListWrap: true
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right"
      }}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right"
      }}
      onClose={onClose}
    >
      {items.map(({ type, icon, labelTranslationKey, href }) => (
        <PersonMenuItem
          key={type}
          icon={icon}
          label={t(labelTranslationKey)}
          href={href}
          onClick={handleClickOption(type)}
        />
      ))}
      <PersonMenuItem
        icon="logOut"
        label={t("menu_log-out_item")}
        onClick={handleClickLogOut}
      />
    </Menu>
  );
};

export default CurrentPersonMenuPopup;

const PersonMenuItem = styled(CurrentPersonMenuItem)`
  width: 174px;
`;
