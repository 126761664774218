import styled from "@emotion/styled";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import Typography from "@mui/material/Typography";
import NextLink from "next/link";
import useTranslation from "next-translate/useTranslation";

import ROUTES from "~/constants/routes";

type Props = {
  className?: string;
  onClose?: () => void;
};

const AppLayoutHeaderActionsMenuLinks = ({
  className,
  onClose
}: Props): JSX.Element => {
  const { t } = useTranslation("common");

  return (
    <LinkWrapper className={className}>
      <MenuItemWrapper LinkComponent={NextLink} onClick={onClose}>
        <Link href={ROUTES.index}>
          <LinkText>{t("menu_campaigns_item")}</LinkText>
        </Link>
      </MenuItemWrapper>
      <MenuItemWrapper LinkComponent={NextLink} onClick={onClose}>
        <Link href={ROUTES.store}>
          <LinkText>{t("menu_store_item")}</LinkText>
        </Link>
      </MenuItemWrapper>
    </LinkWrapper>
  );
};

export default AppLayoutHeaderActionsMenuLinks;

const LinkWrapper = styled(MenuList)`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(8)};
  justify-content: center;
  padding: 0;
`;

const MenuItemWrapper = styled(MenuItem)`
  min-height: 0;
  padding: 0;
`;

const Link = styled(NextLink)`
  display: block;
`;

const LinkText = styled(Typography)``;
LinkText.defaultProps = {
  variant: "h4"
};
