/* eslint-disable cleo/no-deep-imports */
import gql from "graphql-tag";

import * as Types from "../../../../declarations/graphql/types";

export type DTOAttachmentImageFragment = {
  __typename: "AttachmentImage";
  id: string;
  url: string;
  blurhash: string;
  thumbnails: {
    __typename?: "AttachmentImageThumbnails";
    s295: { __typename?: "AttachmentImageThumbnail"; url: string };
    m600: { __typename?: "AttachmentImageThumbnail"; url: string };
    w1200: { __typename?: "AttachmentImageThumbnail"; url: string };
    f1920: { __typename?: "AttachmentImageThumbnail"; url: string };
  };
  sizes: {
    __typename?: "Sizes";
    height?: number | null;
    width?: number | null;
  };
};

export const AttachmentImageFragmentDoc = gql`
  fragment AttachmentImageFragment on AttachmentImage {
    __typename
    id
    url
    thumbnails {
      s295 {
        url
      }
      m600 {
        url
      }
      w1200 {
        url
      }
      f1920 {
        url
      }
    }
    blurhash
    sizes {
      height
      width
    }
  }
`;
