import {
  PersonMenuOption,
  PersonMenuOptionDescription,
  UserRole
} from "~/components/person/CurrentPersonMenu/declarations";
import ROUTES from "~/constants/routes";

export const PERSON_MENU_OPTIONS: PersonMenuOptionDescription[] = [
  {
    type: PersonMenuOption.wallet,
    icon: "wallet",
    labelTranslationKey: "common:menu_wallet_item",
    roles: [UserRole.casual]
  },
  {
    type: PersonMenuOption.myNft,
    icon: "myNFT",
    labelTranslationKey: "menu_my-nfts_item",
    href: ROUTES.items.index
  },
  {
    type: PersonMenuOption.myCollections,
    icon: "collections",
    labelTranslationKey: "menu_my-collections_item",
    href: ROUTES.collections.index,
    roles: [UserRole.creator]
  },
  {
    type: PersonMenuOption.settings,
    icon: "settings",
    labelTranslationKey: "menu_settings_item",
    href: ROUTES.settings.profile
  },
  {
    type: PersonMenuOption.rewardsList,
    labelTranslationKey: "menu_rewards-list",
    icon: "document",
    href: ROUTES.rewardsList,
    roles: [UserRole.admin]
  }
];
