import styled from "@emotion/styled";
import { buttonBaseClasses } from "@mui/material/ButtonBase";
import {
  default as MuiIconButton,
  iconButtonClasses
} from "@mui/material/IconButton";
import get from "lodash.get";
import { forwardRef } from "react";

import Icon from "~/components/core/Icon";

import { ICON_SIZE_MAP } from "./constants";
import {
  IconButtonColor,
  IconButtonProps,
  IconButtonSize,
  IconButtonVariant
} from "./declarations";
import { getIconButtonColors } from "./getIconButtonColors";

const IconButton = forwardRef<HTMLButtonElement, IconButtonProps>(
  (props, ref) => {
    const {
      children,
      icon,
      iconColor,
      iconColorSecondary,
      disabled,
      size = 56,
      color = "transparent",
      variant = "rounded",
      ...restProps
    } = props;

    const colors = getIconButtonColors(color);
    const defaultIconColor = disabled
      ? colors.icon.disabled
      : colors.icon.regular;
    const iconColorToApply = iconColor ?? defaultIconColor;

    return (
      <MuiIconButtonStyled
        {...restProps}
        ref={ref}
        disabled={disabled}
        iconButtonColor={color}
        variant={variant}
        customSize={size}
        disableRipple
      >
        {icon ? (
          <Icon
            variant={icon}
            size={ICON_SIZE_MAP[size]}
            color={iconColorToApply}
            colorSecondary={iconColorSecondary}
          />
        ) : (
          children
        )}
      </MuiIconButtonStyled>
    );
  }
);

const MuiIconButtonStyled = styled(MuiIconButton, {
  shouldForwardProp: prop =>
    !["customSize", "variant", "iconButtonColor"].includes(prop.toString())
})<{
  iconButtonColor: IconButtonColor;
  variant: IconButtonVariant;
  customSize: IconButtonSize;
}>`
  border-radius: ${({ variant }) => (variant === "rounded" ? "50%" : "4px")};
  height: ${({ customSize }) => customSize}px;
  width: ${({ customSize }) => customSize}px;

  ${({ theme, iconButtonColor }) => {
    const { active, hover, disabled, regular } =
      getIconButtonColors(iconButtonColor).background;

    return `
      background-color: ${get(theme.palette, regular)};

      &.${iconButtonClasses.disabled} {
        background-color: ${get(theme.palette, disabled)};
      }

      &:hover {
        background-color: ${get(theme.palette, hover)};
      }

      &.${buttonBaseClasses.focusVisible} {
        background-color: ${get(theme.palette, hover)};
      }

      &:active {
        background-color: ${get(theme.palette, active)};
      }
    `;
  }}
`;

export default IconButton;
