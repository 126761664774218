import styled from "@emotion/styled";
import Drawer from "@mui/material/Drawer";
import { paperClasses } from "@mui/material/Paper";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useEffect } from "react";

import IconButton from "~/components/core/IconButton/IconButton";
import AppLayoutHeaderActionsMenuAuthorized from "~/components/layouts/AppLayout/AppLayoutHeader/AppLayoutHeaderActions/AppLayoutHeaderActionsMenu/AppLayoutHeaderActionsMenuAuthorized";
import { useAuthenticationContext } from "~/components/providers/AuthenticationContextProvider/AuthenticationContext";
import { AuthenticationStatus } from "~/components/providers/AuthenticationContextProvider/declarations";
import useUnmountableDialog from "~/hooks/useUnmountableDialog";

import AppLayoutHeaderActionsMenuUnauthorized from "./AppLayoutHeaderActionsMenuUnauthorized";

const AppLayoutHeaderActionsMenu = (): JSX.Element => {
  const { authenticationStatus } = useAuthenticationContext();
  const menu = useUnmountableDialog<HTMLButtonElement>();
  const theme = useTheme();
  const downXS = useMediaQuery(theme.breakpoints.up("sm"));

  const handleClick = (): void => {
    if (menu.dialogProps.open) {
      menu.dialogProps.onClose();
    } else {
      menu.openDialog();
    }
  };

  useEffect(() => {
    if (downXS) {
      menu.dialogProps.onClose();
    }
  }, [downXS, menu.dialogProps]);

  return (
    <>
      <MenuButton
        disabled={authenticationStatus === AuthenticationStatus.notFetched}
        variant="rounded"
        size={48}
        color="contrast"
        icon={menu.dialogProps.open ? "close" : "menu"}
        onClick={handleClick}
      />
      {menu.mounted && (
        <MenuDrawer
          open={menu.dialogProps.open}
          anchor="bottom"
          onClose={menu.dialogProps.onClose}
          hideBackdrop
        >
          <MenuDrawerWrapper>
            {authenticationStatus === AuthenticationStatus.authenticated ? (
              <AppLayoutHeaderActionsMenuAuthorized
                onClose={menu.dialogProps.onClose}
              />
            ) : (
              <AppLayoutHeaderActionsMenuUnauthorized
                onClose={menu.dialogProps.onClose}
              />
            )}
          </MenuDrawerWrapper>
        </MenuDrawer>
      )}
    </>
  );
};

export default AppLayoutHeaderActionsMenu;

const MenuButton = styled(IconButton)`
  display: block;

  ${({ theme }) => theme.breakpoints.up("sm")} {
    display: none;
  }
`;

const MenuDrawer = styled(Drawer)`
  & .${paperClasses.root} {
    padding: 0;
    top: 0;
  }
`;

const MenuDrawerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: ${({ theme }) => theme.spacing(25, 4, 10)};
`;
