import { captureException as capture } from "@sentry/nextjs";
import { ScopeContext } from "@sentry/types";

import { LoggingTagFlow } from "~/constants/logging";

const captureException = (
  exception: unknown,
  flow?: LoggingTagFlow,
  captureContext?: Partial<ScopeContext>
): void => {
  capture(exception, {
    ...captureContext,
    tags: {
      ...captureContext?.tags,
      flow
    }
  });

  console.error(exception);
};

export default captureException;
