import { useCallback, useRef } from "react";

export type ObserverHandlerListModifier<T> = (handler: T) => void;

// should support arbitrary data types
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type ObserverHandler = (...args: any[]) => boolean;

export type ObserverNotifyFunction<T extends ObserverHandler> = (
  invertCallingOrder: boolean,
  ...args: Parameters<T>
) => {
  called: boolean;
  broken: boolean;
};

type UseObserverResult<T extends ObserverHandler> = {
  subscribe: ObserverHandlerListModifier<T>;
  unsubscribe: ObserverHandlerListModifier<T>;
  notify: ObserverNotifyFunction<T>;
};

const useObserver = <T extends ObserverHandler>(): UseObserverResult<T> => {
  const handlers = useRef<T[]>([]);

  const subscribe: ObserverHandlerListModifier<T> = useCallback(
    (handler: T) => {
      handlers.current.push(handler);
    },
    []
  );

  const unsubscribe: ObserverHandlerListModifier<T> = useCallback(handler => {
    handlers.current = handlers.current.filter(
      currentHandler => currentHandler !== handler
    );
  }, []);

  const notify: ObserverNotifyFunction<T> = useCallback(
    (invertCallingOrder: boolean, ...args) => {
      let broken = false;
      const called = handlers.current.length > 0;

      for (let i = 0; i < handlers.current.length; i++) {
        const handlerIndex = invertCallingOrder
          ? handlers.current.length - i - 1
          : i;

        const needToBreak = handlers.current[handlerIndex](...args);

        if (needToBreak) {
          broken = true;
          break;
        }
      }

      return { broken, called };
    },
    []
  );

  return {
    subscribe,
    unsubscribe,
    notify
  };
};

export default useObserver;
