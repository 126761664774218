import NextLink, { LinkProps as NextLinkProps } from "next/link";
import { forwardRef, HTMLAttributes, ReactNode, Ref } from "react";

export interface WithLinkProps
  extends HTMLAttributes<HTMLAnchorElement | HTMLDivElement> {
  href?: NextLinkProps["href"];
  as?: NextLinkProps["as"];
  scroll?: NextLinkProps["scroll"];
  replace?: NextLinkProps["replace"];
  target?: HTMLAnchorElement["target"];
  rel?: HTMLAnchorElement["rel"];
  children: ReactNode;
}

const WithLink = forwardRef<HTMLDivElement | HTMLAnchorElement, WithLinkProps>(
  (
    {
      href,
      as,
      rel,
      replace,
      scroll,
      children,
      className,
      onClick,
      ...restProps
    },
    ref
  ): JSX.Element => {
    if (!href) {
      return (
        <div
          onClick={onClick}
          className={className}
          ref={ref as Ref<HTMLDivElement>}
        >
          {children}
        </div>
      );
    }

    return (
      <NextLink
        href={href}
        as={as}
        scroll={scroll}
        passHref
        replace={replace}
        className={className}
        rel={rel}
        ref={ref as Ref<HTMLAnchorElement>}
        onClick={onClick}
        {...restProps}
      >
        {children}
      </NextLink>
    );
  }
);

export default WithLink;
