import styled from "@emotion/styled";
import Button, { ButtonProps } from "@mui/material/Button";
import NextLink from "next/link";
import useTranslation from "next-translate/useTranslation";

import ROUTES from "~/constants/routes";

type Props = {
  buttonSize: ButtonProps["size"];
  onClose?: () => void;
};

const AppLayoutHeaderActionsButtonsUnauthenticated = ({
  buttonSize,
  onClose
}: Props): JSX.Element => {
  const { t } = useTranslation("common");

  return (
    <>
      <LoginButton
        href={ROUTES.auth.login}
        LinkComponent={NextLink}
        size={buttonSize}
        onClick={onClose}
      >
        {t("login_action")}
      </LoginButton>
      <SignupButton
        href={ROUTES.auth.signup}
        LinkComponent={NextLink}
        size={buttonSize}
        onClick={onClose}
      >
        {t("signup_action")}
      </SignupButton>
    </>
  );
};

export default AppLayoutHeaderActionsButtonsUnauthenticated;

const LoginButton = styled(Button)`
  min-width: 118px;
`;
LoginButton.defaultProps = {
  variant: "contained",
  color: "brandGreen",
  size: "medium"
};

const SignupButton = styled(Button)`
  color: ${({ theme }) => theme.palette.text.primary};
  margin-left: ${({ theme }) => theme.spacing(1)};
  min-width: 118px;
`;
SignupButton.defaultProps = {
  variant: "contained",
  color: "brandYellow",
  size: "medium"
};
