/* eslint-disable cleo/no-deep-imports */
import gql from "graphql-tag";

import * as Types from "../../../../declarations/graphql/types";
import {
  DTOMoneyFragment,
  MoneyFragmentDoc
} from "../../../../graphql/moneyFragment.generated";
import {
  DTONftRewardFragment,
  NftRewardFragmentDoc
} from "./nftRewardFragment.generated";
import {
  DTOTokenRewardFragment,
  TokenRewardFragmentDoc
} from "./tokenRewardFragment.generated";

export type DTOAdvertisementRewardFragment = {
  __typename?: "Advertisement";
  rewardType?: Types.DTORewardType | null;
  rewardCount?: number | null;
  myReward?: { __typename?: "Reward"; status: Types.DTORewardStatus } | null;
  budget?: ({ __typename?: "Money" } & DTOMoneyFragment) | null;
  reward?:
    | ({ __typename: "NftPostReward" } & DTONftRewardFragment)
    | ({ __typename: "TokenPostReward" } & DTOTokenRewardFragment)
    | null;
  surveyReward?:
    | ({ __typename: "NftPostReward" } & DTONftRewardFragment)
    | ({ __typename: "TokenPostReward" } & DTOTokenRewardFragment)
    | null;
};

export const AdvertisementRewardFragmentDoc = gql`
  fragment AdvertisementRewardFragment on Advertisement {
    rewardType
    rewardCount
    myReward {
      status
    }
    budget {
      ...MoneyFragment
    }
    reward {
      __typename
      ...TokenRewardFragment
      ...NftRewardFragment
    }
    surveyReward {
      __typename
      ...TokenRewardFragment
      ...NftRewardFragment
    }
  }
`;
