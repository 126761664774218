import { useTheme } from "@emotion/react";
import { Breakpoint } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";

const useDownBreakpoint = (value: number | Breakpoint): boolean => {
  const theme = useTheme();

  return useMediaQuery(theme.breakpoints.down(value));
};

export default useDownBreakpoint;
