import styled from "@emotion/styled";
import Avatar from "@mui/material/Avatar";
import Button, { buttonClasses } from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { forwardRef, ReactNode } from "react";

import { useMe } from "~/components/person/useMe/useMe";
import { RequestState } from "~/constants/requestState";

type Props = {
  className?: string;
  children?: ReactNode;
  endIcon?: ReactNode;
  onClick?: () => void;
};

const CurrentPersonMenuTriggerWrapper = forwardRef<HTMLButtonElement, Props>(
  ({ className, children, endIcon, onClick }: Props, ref): JSX.Element => {
    const meQuery = useMe();
    const name =
      meQuery.type === RequestState.Success ? meQuery.data.nickname : undefined;

    return (
      <Wrapper
        ref={ref}
        className={className}
        onClick={onClick}
        startIcon={
          <PersonAvatar
            src={
              meQuery.type === RequestState.Success
                ? meQuery.data.avatar?.middle2x
                : undefined
            }
          />
        }
        endIcon={endIcon}
      >
        <Name title={name}>{name}</Name>
        {children}
      </Wrapper>
    );
  }
);

export default CurrentPersonMenuTriggerWrapper;

const Wrapper = styled(Button)`
  gap: ${({ theme }) => theme.spacing(2.5)};
  margin: 0;
  padding: ${({ theme }) => theme.spacing(0.5, 4, 0.5, 0.5)};

  .${buttonClasses.startIcon} {
    margin: 0;
  }

  & .${buttonClasses.endIcon} {
    margin: 0;

    svg {
      --color-primary: ${({ theme }) => theme.palette.text.primary};
      color: ${({ theme }) => theme.palette.text.primary};
      height: 24px;
      width: 24px;
    }
  }
`;
Wrapper.defaultProps = {
  variant: "contained",
  color: "brandYellow"
};

const PersonAvatar = styled(Avatar)`
  height: 44px;
  width: 44px;
`;

const Name = styled(Typography)`
  max-width: 100px;
`;
Name.defaultProps = {
  variant: "titleTertiary",
  color: "text.primary",
  noWrap: true
};
