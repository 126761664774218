/* eslint-disable cleo/no-deep-imports */
import gql from "graphql-tag";
import * as Urql from "urql";

import * as Types from "../../../../../declarations/graphql/types";

export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type DTOWaitingRewardsQueryVariables = Types.Exact<{
  [key: string]: never;
}>;

export type DTOWaitingRewardsQuery = {
  __typename?: "Query";
  me: {
    __typename?: "Person";
    id: string;
    waitingRewards?: Array<{
      __typename?: "MyWaitingReward";
      status: Types.DTOMyWaitingRewardStatus;
      post: { __typename?: "Advertisement"; id: string };
    }> | null;
  };
};

export const WaitingRewardsQueryDocument = gql`
  query WaitingRewardsQuery {
    me {
      id
      waitingRewards {
        status
        post {
          id
        }
      }
    }
  }
`;

export function useWaitingRewardsQuery(
  options?: Omit<Urql.UseQueryArgs<DTOWaitingRewardsQueryVariables>, "query">
) {
  return Urql.useQuery<DTOWaitingRewardsQuery, DTOWaitingRewardsQueryVariables>(
    { query: WaitingRewardsQueryDocument, ...options }
  );
}
