export const MILLISECONDS_IN_SECOND = 1000;
export const SECONDS_IN_MINUTE = 60;
export const MINUTES_IN_HOUR = 60;
export const HOURS_IN_DAY = 24;
export const DAYS_IN_WEEK = 7;
export const DAYS_IN_YEAR = 365;
export const MILLISECONDS_IN_DAY =
  HOURS_IN_DAY * MINUTES_IN_HOUR * SECONDS_IN_MINUTE * MILLISECONDS_IN_SECOND;
export const DATE_FORMAT_FULL = "D MMM, YYYY";
export const DATE_FULL_MONTH_FORMAT = "MMMM D, YYYY";

export const DATE_FULL_DAY_MONTH_YEAR_FORMAT = "DD MMM, YYYY";
export const DATE_WITH_TIME_FORMAT = "ddd, MMM D, h:mm A";
export const LOCALIZED_DATE_FORMAT = "LL";
