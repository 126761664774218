import { useCallback, useEffect, useState } from "react";

import { ThemeMode } from "~/theme/declarations";
import { getThemeModeFromBrowser } from "~/utils/theme/getThemeModeFromBrowser";

const CHANGE_EVENT_NAME = "change";
export const useSystemThemeDetector = (): ThemeMode | null => {
  const [systemTheme, setSystemTheme] = useState(getThemeModeFromBrowser());

  const listener = useCallback(() => {
    setSystemTheme(getThemeModeFromBrowser());
  }, []);

  useEffect(() => {
    const mediaQueryList = window.matchMedia("(prefers-color-scheme: dark)");

    try {
      mediaQueryList.addEventListener(CHANGE_EVENT_NAME, listener);
    } catch (error) {
      mediaQueryList.addListener(listener);
    }
    return () => {
      mediaQueryList.removeEventListener(CHANGE_EVENT_NAME, listener);
      mediaQueryList.removeListener(listener);
    };
  }, [listener]);

  return systemTheme;
};
