/* eslint-disable cleo/no-deep-imports */
import gql from "graphql-tag";
import * as Urql from "urql";

import * as Types from "../../../../declarations/graphql/types";
import {
  AvatarFragmentDoc,
  DTOAvatarFragment
} from "../../../../graphql/avatarFragment.generated";
import {
  DTOMoneyFragment,
  MoneyFragmentDoc
} from "../../../../graphql/moneyFragment.generated";
import {
  DTOPageInfoFragment,
  PageInfoFragmentDoc
} from "../../../../graphql/pageInfoFragment.generated";
import {
  DTORichTextFragment,
  RichTextFragmentDoc
} from "../../../../graphql/richTextFragment.generated";
import {
  DTOQuestionFragment,
  QuestionFragmentDoc
} from "../../../advertisement/graphql/questionFragment.generated";
import {
  AdvertisementRewardFragmentDoc,
  DTOAdvertisementRewardFragment
} from "./advertisementRewardFragment.generated";
import {
  AttachmentImageFragmentDoc,
  DTOAttachmentImageFragment
} from "./attachmentImageFragment.generated";
import {
  AttachmentPdfFragmentDoc,
  DTOAttachmentPdfFragment
} from "./attachmentPdfFragment.generated";
import {
  AttachmentVideoFragmentDoc,
  DTOAttachmentVideoFragment
} from "./attachmentVideoFragment.generated";
import {
  DTONftRewardFragment,
  NftRewardFragmentDoc
} from "./nftRewardFragment.generated";
import {
  DTOTokenRewardFragment,
  TokenRewardFragmentDoc
} from "./tokenRewardFragment.generated";

export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type DTOWaitApproveRewardQueryVariables = Types.Exact<{
  filter?: Types.InputMaybe<Types.DTOWaitApproveRewardFilter>;
  order?: Types.InputMaybe<Types.DTOWaitApproveRewardOrder>;
  first?: Types.InputMaybe<Types.Scalars["Int"]>;
  after?: Types.InputMaybe<Types.Scalars["String"]>;
}>;

export type DTOWaitApproveRewardQuery = {
  __typename?: "Query";
  waitApproveReward: {
    __typename?: "RewardRequestList";
    edges: Array<{
      __typename: "RewardRequestEdge";
      cursor: string;
      node: {
        __typename: "RewardRequest";
        id: string;
        createdAt: number;
        status: Types.DTORewardStatus;
        answers?: Array<{
          __typename?: "CompletedAnswer";
          text: string;
          id: string;
          questionId: string;
        }> | null;
        person: {
          __typename?: "Person";
          id: string;
          name: string;
          profileUrl: string;
          status: Types.DTOPersonStatus;
          avatar?: ({ __typename?: "Avatar" } & DTOAvatarFragment) | null;
        };
        post: {
          __typename?: "Advertisement";
          id: string;
          questions?: Array<
            { __typename?: "Question" } & DTOQuestionFragment
          > | null;
          files: Array<
            | ({ __typename?: "AttachmentImage" } & DTOAttachmentImageFragment)
            | ({ __typename?: "AttachmentPdf" } & DTOAttachmentPdfFragment)
            | ({ __typename?: "AttachmentVideo" } & DTOAttachmentVideoFragment)
          >;
          richText: { __typename?: "RichText" } & DTORichTextFragment;
        } & DTOAdvertisementRewardFragment;
      };
    }>;
    pageInfo: { __typename?: "PageInfo" } & DTOPageInfoFragment;
  };
};

export const WaitApproveRewardQueryDocument = gql`
  query WaitApproveRewardQuery(
    $filter: WaitApproveRewardFilter
    $order: WaitApproveRewardOrder
    $first: Int
    $after: String
  ) {
    waitApproveReward(
      filter: $filter
      order: $order
      first: $first
      after: $after
    ) {
      edges {
        __typename
        cursor
        node {
          __typename
          id
          createdAt
          status
          answers {
            text
            id
            questionId
          }
          person {
            id
            name
            profileUrl
            status
            avatar {
              ...AvatarFragment
            }
          }
          post {
            id
            questions {
              ...QuestionFragment
            }
            files {
              ...AttachmentImageFragment
              ...AttachmentVideoFragment
              ...AttachmentPdfFragment
            }
            richText {
              ...RichTextFragment
            }
            ...AdvertisementRewardFragment
          }
        }
      }
      pageInfo {
        ...PageInfoFragment
      }
    }
  }
  ${AvatarFragmentDoc}
  ${QuestionFragmentDoc}
  ${AttachmentImageFragmentDoc}
  ${AttachmentVideoFragmentDoc}
  ${AttachmentPdfFragmentDoc}
  ${RichTextFragmentDoc}
  ${AdvertisementRewardFragmentDoc}
  ${MoneyFragmentDoc}
  ${TokenRewardFragmentDoc}
  ${NftRewardFragmentDoc}
  ${PageInfoFragmentDoc}
`;

export function useWaitApproveRewardQuery(
  options?: Omit<Urql.UseQueryArgs<DTOWaitApproveRewardQueryVariables>, "query">
) {
  return Urql.useQuery<
    DTOWaitApproveRewardQuery,
    DTOWaitApproveRewardQueryVariables
  >({ query: WaitApproveRewardQueryDocument, ...options });
}
