import { GUID_REGEXP, IOS_DEVICE_REGEX } from "~/constants/common";
import { Events } from "~/constants/events";

export function isEmpty(value: unknown): boolean {
  return (
    value == null ||
    value == undefined || // From standard.js: Always use === - but obj == null is allowed to check null || undefined
    (typeof value === "object" &&
      value !== null &&
      Object.keys(value).length === 0) ||
    (typeof value === "string" && value.trim().length === 0)
  );
}

export function isNumber(value: unknown): value is number {
  return typeof value === "number";
}

export function isServerSide(): boolean {
  return typeof window === "undefined";
}

export function isSafari(): boolean {
  if (isServerSide()) {
    return false;
  }

  const userAgent = navigator.userAgent.toLowerCase();

  return (
    userAgent.indexOf("safari") !== -1 && userAgent.indexOf("chrome") === -1
  );
}

export function isString(str: unknown): str is string {
  return typeof str === "string";
}

export function isNotNullable<T>(value: T | null | undefined): value is T {
  return value != null;
}

export function isValidGuid(str?: string): boolean {
  if (!str) {
    return false;
  }

  return GUID_REGEXP.test(str);
}

export function isTouchDevice(): boolean {
  try {
    document.createEvent(Events.TouchEvent);
    return true;
  } catch (e) {
    return false;
  }
}

// eslint-disable-next-line @typescript-eslint/ban-types
export function isFunction(value: unknown): value is Function {
  return typeof value === "function";
}

export function isIOS(): boolean {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return IOS_DEVICE_REGEX.test(window.navigator.userAgent) && !window.MSStream;
}

export const isNumeric = (text: string): boolean =>
  /* isNaN can receive string as well */
  !isNaN(text as unknown as number) && !isNaN(parseFloat(text));
