/* eslint-disable cleo/no-deep-imports */
import gql from "graphql-tag";

import * as Types from "../../../../declarations/graphql/types";
import {
  DTOMoneyFragment,
  MoneyFragmentDoc
} from "../../../../graphql/moneyFragment.generated";

export type DTOTokenRewardFragment = {
  __typename?: "TokenPostReward";
  price: { __typename?: "Money" } & DTOMoneyFragment;
};

export const TokenRewardFragmentDoc = gql`
  fragment TokenRewardFragment on TokenPostReward {
    price {
      ...MoneyFragment
    }
  }
`;
