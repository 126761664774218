import styled from "@emotion/styled";
import { ReactNode } from "react";

import useWaitingApprovedReward from "~/components/advertisement/claim/useWaitingApproveReward";

import AppLayoutFooter from "./AppLayoutFooter";
import AppLayoutHeader from "./AppLayoutHeader";

interface Props {
  children: ReactNode;
}

const AppLayout = ({ children }: Props): JSX.Element => {
  useWaitingApprovedReward();

  return (
    <>
      <AppLayoutHeader />
      <MainWithPadding>{children}</MainWithPadding>
      <AppLayoutFooter />
    </>
  );
};

export default AppLayout;

const MainWithPadding = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding-top: ${({ theme }) => theme.spacing(22)};

  ${({ theme }) => theme.breakpoints.up("sm")} {
    padding-top: ${({ theme }) => theme.spacing(23)};
  }
`;
