export type SubscriptionsConnectionEventsHandler = (
  event: SubscriptionConnectionEvent
) => boolean;

export enum SubscriptionConnectionEvent {
  disconnection,
  reconnection
}

export type SubscriptionReconnectedHandler = () => void;
export type SubscriptionDisconnectedHandler = () => void;
