export enum SaveState {
  init = "initialize",
  error = "error",
  saving = "saving",
  saved = "saved"
}

export const REST_METHODS = {
  post: "POST",
  get: "GET",
  put: "PUT",
  delete: "DELETE"
};

export const NETWORK_CODES = {
  ok: 200,
  found: 302,
  temporaryRedirect: 307,
  badRequest: 400,
  unauthorized: 401,
  forbidden: 403,
  notFound: 404,
  unprocessableRequest: 422,
  internalServerError: 500
};

export enum NetworkProtocols {
  http = "http://",
  https = "https://"
}
