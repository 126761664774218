import { DTOCurrency } from "~/declarations/graphql/types";

export enum Currency {
  Cleo = "Cleo",
  Usd = "Usd"
}

export const ALL_CRYPTO_CURRENCIES = [Currency.Cleo] as const;

export type CryptoCurrency = typeof ALL_CRYPTO_CURRENCIES[number];

export const CURRENCY_TO_DTO_CURRENCY: {
  [key in Currency]: DTOCurrency;
} = {
  [Currency.Cleo]: DTOCurrency.Cleo,
  [Currency.Usd]: DTOCurrency.Usd
};

export const isCryptoCurrency = (
  currency: Currency
): currency is CryptoCurrency =>
  ALL_CRYPTO_CURRENCIES.includes(currency as CryptoCurrency);
