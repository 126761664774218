import { safeDynamicImport } from "~/utils/import/dynamic";

import { AppDialog } from "./constants";

export const COMPONENT_BY_DIALOG = {
  [AppDialog.ClaimSuccess]: safeDynamicImport(
    () => import("~/components/advertisement/claim/ClaimSuccessDialog"),
    {
      ssr: false
    }
  ),
  [AppDialog.ClaimWizard]: safeDynamicImport(
    () => import("~/components/advertisement/claim/ClaimWizardDialog"),
    { ssr: false }
  ),
  [AppDialog.WalletInfo]: safeDynamicImport(
    () => import("~/components/wallet/walletInfo/WalletInfoDialog"),
    {
      ssr: false
    }
  ),
  [AppDialog.NftPurchaseSuccess]: safeDynamicImport(
    () => import("~/components/nfts/NftPurchaseSuccessDialog"),
    {
      ssr: false
    }
  ),
  [AppDialog.ClaimWaitingReward]: safeDynamicImport(
    () => import("~/components/advertisement/claim/ClaimWaitingRewardDialog"),
    {
      ssr: false
    }
  ),
  [AppDialog.RewardsListApproveDialog]: safeDynamicImport(
    () => import("~/components/rewardsList/RewardsListApproveDialog"),
    {
      ssr: false
    }
  )
} as const;
