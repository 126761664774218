/* eslint-disable cleo/no-deep-imports */
import gql from "graphql-tag";

import * as Types from "../declarations/graphql/types";
import {
  AvatarFragmentDoc,
  DTOAvatarFragment
} from "./avatarFragment.generated";

export type DTOPersonBaseFragment = {
  __typename: "Person";
  id: string;
  name: string;
  nickname: string;
  avatar?: ({ __typename?: "Avatar" } & DTOAvatarFragment) | null;
};

export const PersonBaseFragmentDoc = gql`
  fragment PersonBaseFragment on Person {
    __typename
    id
    name
    nickname
    avatar {
      ...AvatarFragment
    }
  }
`;
