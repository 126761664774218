import { useTheme } from "@emotion/react";
import get from "lodash.get";
import { useCallback } from "react";

import { IconColor } from "./declarations";

type PickColorFunction = (color: IconColor) => string | undefined;

const usePickIconColor = (): PickColorFunction => {
  const theme = useTheme();

  const pickColor = useCallback(
    (color: IconColor): string | undefined => get(theme.palette, color),
    [theme.palette]
  );

  return pickColor;
};

export default usePickIconColor;
