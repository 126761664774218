import { createContext } from "react";

import { SubscriptionsConnectionEventsHandler } from "~/components/providers/UrqlProvider/declarations";
import createUseContext from "~/utils/createUseContext";
import { ObserverHandlerListModifier } from "~/utils/observer/useObserver";

export type UrqlContextState = {
  recreateClient: () => void;
  subscribeToConnectionEvents: ObserverHandlerListModifier<SubscriptionsConnectionEventsHandler>;
  unsubscribeFromConnectionEvents: ObserverHandlerListModifier<SubscriptionsConnectionEventsHandler>;
};

export const UrqlContext = createContext<UrqlContextState | undefined>(
  undefined
);

export const useUrqlContext = createUseContext(UrqlContext, "UrqlContext");
