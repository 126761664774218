import { useEffect, useRef, useState } from "react";

import { ThemeMode } from "~/theme/declarations";

const useChangeThemeAfterFirstRender = (
  initialTheme: ThemeMode,
  correctInitialTheme: ThemeMode
): boolean => {
  const [themeChanged, setThemeChanged] = useState(false);
  const initialThemeRef = useRef(initialTheme);

  useEffect(() => {
    if (correctInitialTheme !== initialThemeRef.current) {
      setThemeChanged(true);
    }
  }, [correctInitialTheme]);

  return themeChanged;
};

export default useChangeThemeAfterFirstRender;
