import { CacheProvider, EmotionCache, ThemeProvider } from "@emotion/react";
import styled from "@emotion/styled";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider as MuiThemeProvider } from "@mui/material/styles";
import { Poppins } from "next/font/google";

import { DTOTheme } from "~/declarations/graphql/types";
import { ThemeMode } from "~/theme/declarations";
import GlobalStyle from "~/theme/GlobalStyles";
import createEmotionCache from "~/utils/createEmotionCache";

import { AppThemeContext } from "./AppThemeContext";
import useAppTheme from "./useAppTheme";

const poppins = Poppins({
  subsets: ["latin"],
  weight: ["400", "500", "600", "700"],
  display: "swap",
  variable: "--font-poppins"
});

// Client-side cache, shared for the whole session of the user in the browser.
const clientSideEmotionCache = createEmotionCache();

interface Props {
  children: React.ReactNode;
  emotionCache?: EmotionCache;
  themeModeCookie?: ThemeMode;
  themeOption?: DTOTheme;
}

const AppStylesProvider = ({
  children,
  themeModeCookie,
  themeOption: initialThemeOption,
  emotionCache = clientSideEmotionCache
}: Props): JSX.Element => {
  const { theme, setThemeOption, themeOption } = useAppTheme({
    themeModeCookie,
    initialThemeOption
  });

  return (
    <CacheProvider value={emotionCache}>
      <AppThemeContext.Provider value={{ setThemeOption, themeOption }}>
        <MuiThemeProvider theme={theme}>
          <ThemeProvider theme={theme}>
            <GlobalStyle fontFamily={poppins.style.fontFamily} />
            <CssBaseline />
            <Main className={poppins.className}>{children}</Main>
          </ThemeProvider>
        </MuiThemeProvider>
      </AppThemeContext.Provider>
    </CacheProvider>
  );
};
export default AppStylesProvider;

const Main = styled.main`
  display: flex;
  flex-direction: column;
`;
