import styled from "@emotion/styled";

import { IconVariant } from "~/components/core/Icon/declarations";
import IconButton from "~/components/core/IconButton";
import useDownBreakpoint from "~/utils/useDownBreakpoint";

const SOCIAL_LINKS_MAP: { icon: IconVariant; href?: string }[] = [
  { icon: "twitter", href: process.env.NEXT_PUBLIC_SOCIAL_LINK_TWITTER },
  { icon: "linkedIn", href: process.env.NEXT_PUBLIC_SOCIAL_LINK_LINKEDIN },
  { icon: "youtube", href: process.env.NEXT_PUBLIC_SOCIAL_LINK_YOUTUBE },
  { icon: "discord", href: process.env.NEXT_PUBLIC_SOCIAL_LINK_DISCORD }
];

const AppLayoutFooterSocialLinks = (): JSX.Element => {
  const downSM = useDownBreakpoint("sm");

  const handleOpenLink = (href: string) => () => {
    window.open(href, "_blank");
  };

  return (
    <Wrapper>
      {SOCIAL_LINKS_MAP.map(
        ({ href, icon }) =>
          href && (
            <IconButton
              key={href}
              size={downSM ? 48 : 56}
              color="brandGreen.light"
              iconColor="common.black"
              icon={icon}
              onClick={handleOpenLink(href)}
            />
          )
      )}
    </Wrapper>
  );
};

export default AppLayoutFooterSocialLinks;

const Wrapper = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing(1)};
  width: 308px;

  ${({ theme }) => theme.breakpoints.up("sm")} {
    width: 356px;
  }

  ${({ theme }) => theme.breakpoints.down("xs")} {
    width: auto;
  }
`;
