import { useAuthenticationContext } from "~/components/providers/AuthenticationContextProvider/AuthenticationContext";
import { AuthenticationStatus } from "~/components/providers/AuthenticationContextProvider/declarations";
import { GraphQLQueryState } from "~/declarations/graphql/queryState";
import useGraphQLQuery from "~/utils/graphql/useGraphQLQuery/useGraphQLQuery";

import {
  DTOMeQuery,
  DTOMeQueryVariables,
  useMeQuery
} from "./graphql/meQuery.generated";

export type DTOMePerson = DTOMeQuery["me"];

export type UseMeResult = GraphQLQueryState<DTOMeQuery["me"], undefined>;

const useMe = (): UseMeResult => {
  const { authenticationStatus } = useAuthenticationContext();

  return useGraphQLQuery<DTOMeQuery, "me", undefined, DTOMeQueryVariables>({
    queryKey: "me",
    useQuery: useMeQuery,
    options: {
      pause: authenticationStatus !== AuthenticationStatus.authenticated
    }
  });
};

export default useMe;
