import {
  AdditionalColorSet,
  BrandColorSet,
  CustomPaletteOptions,
  FullColorSet,
  HalfColorSet
} from "~/declarations/theme";

import { ThemeMode } from "./declarations";
import { generateShadesPalette } from "./utils";

export const SHADES_GRADE = [0, 4, 8, 16, 24, 48, 64, 72, 84, 92] as const;

export const BRAND_PREFIX = "brand";
export const BLACK_COLOR = "#000000";
export const WHITE_COLOR = "#FFFFFF";

export const ADDITIONAL_COLORS: AdditionalColorSet = {
  green: {
    normal: "#22C55E",
    light: "#F4FCF9"
  },
  red: {
    normal: "#F64D4D",
    light: "#FDF4F5"
  },
  yellow: {
    normal: "#FFC738",
    light: "#FFFCF0"
  }
};

export const BRAND_COLOR_SET: BrandColorSet = {
  brandBlue: {
    dark: "#0B26F2",
    main: "#0D29FF",
    light: "#BFE8F4",
    lightMax: "#F1FAFC"
  },
  brandGreen: {
    dark: "#2CB779",
    main: "#3CC186",
    light: "#C5F7D1",
    lightMax: "#EDFDF9"
  },
  brandPurple: {
    dark: "#9226D7",
    main: "#9629DB",
    light: "#9F35E2",
    lightMax: "#F6F3FD"
  },
  brandYellow: {
    dark: "#F1B301",
    main: "#FFBD00",
    light: "#FFC214",
    lightMax: "#FEFCF1"
  },
  brandRed: {
    dark: "#F44441",
    main: "#FA5754",
    light: "#FF8FA6",
    lightMax: "#FEF3F1"
  }
};

/* TODO: clarify about error/warning/success colors */
export const ERROR_COLORS = {
  main: "#F64D4D"
};

export const WARNING_COLORS = {
  main: "#FFC831"
};

export const SUCCESS_COLORS = {
  main: "#4ED25C"
};

export const TEXT_COLORS: Record<ThemeMode, HalfColorSet> = {
  [ThemeMode.Dark]: {
    primary: "#FFF",
    secondary: "#A6A6AC",
    tertiary: "#75757A",
    quaternary: "#000"
  },
  [ThemeMode.Light]: {
    primary: "#000",
    secondary: "#68686D",
    tertiary: "#939499",
    quaternary: "#FFF"
  }
};

export const BACKGROUND_COLORS: Record<ThemeMode, FullColorSet> = {
  [ThemeMode.Dark]: {
    primary: "#FFFFFF",
    secondary: "#F5F5F5",
    tertiary: "#888888",
    quaternary: "#454545",
    quinary: "#333333",
    senary: "#202020",
    septenary: "#131313",
    octonary: "#0E0E0E"
  },
  [ThemeMode.Light]: {
    primary: "#000000",
    secondary: "#131313",
    tertiary: "#8B8B8B",
    quaternary: "#E3E3E3",
    quinary: "#EBEBEB",
    senary: "#F5F5F5",
    septenary: "#FBFBFB",
    octonary: "#FFFFFF"
  }
};

export const TRANSPARENCY_WHITE_COLOR = generateShadesPalette(WHITE_COLOR);
export const TRANSPARENCY_BLACK_COLOR = generateShadesPalette(BLACK_COLOR);

export default (mode: ThemeMode): CustomPaletteOptions => ({
  mode,
  additional: ADDITIONAL_COLORS,
  ...BRAND_COLOR_SET,
  common: {
    black: BLACK_COLOR,
    white: WHITE_COLOR
  },
  text: {
    ...TEXT_COLORS[mode]
  },
  background: {
    ...BACKGROUND_COLORS[mode]
  },
  error: { ...ERROR_COLORS },
  warning: { ...WARNING_COLORS },
  success: { ...SUCCESS_COLORS },
  transparent: {
    direct:
      mode === ThemeMode.Dark
        ? TRANSPARENCY_BLACK_COLOR
        : TRANSPARENCY_WHITE_COLOR,
    inverse:
      mode === ThemeMode.Dark
        ? TRANSPARENCY_WHITE_COLOR
        : TRANSPARENCY_BLACK_COLOR
  },
  static: {
    background: {
      dark: BACKGROUND_COLORS[ThemeMode.Dark],
      light: BACKGROUND_COLORS[ThemeMode.Light]
    },
    text: {
      dark: TEXT_COLORS[ThemeMode.Dark],
      light: TEXT_COLORS[ThemeMode.Light]
    },
    transparent: {
      dark: TRANSPARENCY_BLACK_COLOR,
      light: TRANSPARENCY_WHITE_COLOR
    }
  }
});
