import { CSSInterpolation } from "@emotion/css";
import styled from "@emotion/styled";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import Link from "next/link";
import useTranslation from "next-translate/useTranslation";

import Logo from "~/components/common/Logo";
import Icon from "~/components/core/Icon";
import { APP_LAYOUT_GEOMETRY } from "~/components/layouts/AppLayout/constants";
import { COPYRIGHT_SYMBOL } from "~/constants/common";
import ROUTES from "~/constants/routes";

import AppLayoutFooterSocialLinks from "./AppLayoutFooterSocialLinks";

const RELEASED_YEAR = 2023;

const AppLayoutFooter = (): JSX.Element => {
  const { t } = useTranslation("common");

  return (
    <Background>
      <Wrapper>
        <AppLayoutFooterRow>
          <Logo size="xl" variant="monochromeLight" />
          <AppLayoutFooterAppNavigationLinksContainer>
            <StyledButton href={ROUTES.store} variant="text">
              {t("footer-nav_store")}
            </StyledButton>
            <StyledButton href={ROUTES.index} variant="text">
              {t("footer-nav_marketing-campaigns")}
            </StyledButton>
          </AppLayoutFooterAppNavigationLinksContainer>
        </AppLayoutFooterRow>
        <AppLayoutFooterDivider />
        <AppLayoutFooterEmailAndSocials>
          <AppLayoutFooterEmail>
            <Icon variant="email" color="brandPurple.main" />
            <Link href={`mailto:${process.env.NEXT_PUBLIC_SUPPORT_EMAIL}`}>
              <Typography variant="h5">
                {process.env.NEXT_PUBLIC_SUPPORT_EMAIL}
              </Typography>
            </Link>
          </AppLayoutFooterEmail>
          <AppLayoutFooterSocialLinks />
        </AppLayoutFooterEmailAndSocials>
        <AppLayoutFooterDivider />
        <AppLayoutFooterRow>
          <AppLayoutFooterCopyright>
            {t("app-name").toUpperCase()} | {t("all-rights-reserved")}{" "}
            {COPYRIGHT_SYMBOL} {RELEASED_YEAR}
          </AppLayoutFooterCopyright>
          {process.env.NEXT_PUBLIC_CLEO_PRIVACY_DOCUMENT_LINK && (
            <Link
              href={process.env.NEXT_PUBLIC_CLEO_PRIVACY_DOCUMENT_LINK}
              target="_blank"
            >
              <AppLayoutFooterDocumentLink>
                {t("privacy-policy")}
              </AppLayoutFooterDocumentLink>
            </Link>
          )}
        </AppLayoutFooterRow>
      </Wrapper>
    </Background>
  );
};

export default AppLayoutFooter;

const Background = styled.footer`
  background: ${({ theme }) => theme.palette.brandGreen.main};
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(6)};
  margin: 0 auto;
  max-width: ${APP_LAYOUT_GEOMETRY.maxWidth}px;
  padding: ${({ theme }) => theme.spacing(10, 4, 8)};

  ${({ theme }) => theme.breakpoints.up("xs")} {
    padding: ${({ theme }) => theme.spacing(10, 6, 8)};
  }

  ${({ theme }) => theme.breakpoints.up("md")} {
    padding: ${({ theme }) => theme.spacing(10, 10, 8)};
  }
`;

const AppLayoutFooterRow = styled.div`
  display: flex;
  justify-content: space-between;

  ${({ theme }) => theme.breakpoints.down("xs")} {
    align-items: center;
    flex-direction: column;
  }
`;

const AppLayoutFooterAppNavigationLinksContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(8)};
  width: 308px;

  ${({ theme }) => theme.breakpoints.up("sm")} {
    width: 356px;
  }

  ${({ theme }) => theme.breakpoints.down("xs")} {
    align-items: center;
    margin-top: ${({ theme }) => theme.spacing(6)};
  }
`;

const AppLayoutFooterAppNavigationLink = styled(Typography)`
  color: ${({ theme }) => theme.palette.static.text.light.quaternary};
`;
AppLayoutFooterAppNavigationLink.defaultProps = {
  variant: "h5"
};

const AppLayoutFooterEmailAndSocials = styled(AppLayoutFooterRow)`
  align-items: center;
`;

const AppLayoutFooterEmail = styled.div`
  color: ${({ theme }) => theme.palette.brandPurple.main};
  display: flex;
  gap: ${({ theme }) => theme.spacing(4)};

  ${({ theme }) => theme.breakpoints.down("xs")} {
    margin-bottom: ${({ theme }) => theme.spacing(6)};
  }
`;

const AppLayoutFooterCopyright = styled(Typography)`
  color: ${({ theme }) => theme.palette.text.quaternary};

  ${({ theme }) => theme.breakpoints.down("xs")} {
    margin-bottom: ${({ theme }) => theme.spacing(6)};
  }
`;
AppLayoutFooterCopyright.defaultProps = {
  variant: "buttonTertiary"
};

const AppLayoutFooterDocumentLink = styled(Typography)`
  color: ${({ theme }) => theme.palette.brandPurple.main};
`;
AppLayoutFooterDocumentLink.defaultProps = {
  variant: "buttonTertiary"
};

const AppLayoutFooterDivider = styled(Divider)`
  border-color: ${({ theme }) => theme.palette.brandGreen.dark};
`;

const StyledButton = styled(Button)`
  ${({ theme }) => theme.typography.h5 as CSSInterpolation};
  color: ${({ theme }) => theme.palette.common.white};
  justify-content: flex-start;
` as typeof Button;
