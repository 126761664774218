import styled from "@emotion/styled";
import Button, { buttonClasses } from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import useTranslation from "next-translate/useTranslation";
import { useState } from "react";

import Icon from "~/components/core/Icon";
import { IconSize } from "~/components/core/Icon/declarations";
import { Currency } from "~/components/payments/constants/money";
import formatMoney from "~/components/payments/formatters/formatMoney";
import { useMe } from "~/components/person/useMe/useMe";
import { RequestState } from "~/constants/requestState";

type Props = {
  className?: string;
};

const BalanceButton = ({ className }: Props): JSX.Element => {
  const { t } = useTranslation("common");
  const meQuery = useMe();
  const [open, setOpen] = useState(false);

  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  return (
    <Tooltip
      open={open}
      onOpen={handleOpen}
      onClose={handleClose}
      title={
        <TooltipWrapper>
          <Icon
            variant="info"
            color="background.octonary"
            colorSecondary="background.primary"
          />
          <TooltipText>{t("balance_tooltip")}</TooltipText>
        </TooltipWrapper>
      }
    >
      <Wrapper
        open={open}
        className={className}
        startIconSize={44}
        startIcon={<Icon variant="currenciesCleo" size={44} />}
      >
        <Value variant="buttonTertiary">
          {meQuery.type === RequestState.Success &&
            formatMoney({
              amount: meQuery.data.multiBalance.cleo,
              currency: Currency.Cleo,
              currencySignAsPrefix: false
            })}
        </Value>
      </Wrapper>
    </Tooltip>
  );
};

export default BalanceButton;

const Wrapper = styled(Button)<{
  open: boolean;
  startIconSize: IconSize;
}>`
  background-color: ${({ theme }) => theme.palette.brandGreen.lightMax};

  &:hover {
    background-color: ${({ theme }) => theme.palette.brandGreen.main};
  }

  & .${buttonClasses.startIcon} svg {
    width: ${({ startIconSize }) => startIconSize}px;
    height: ${({ startIconSize }) => startIconSize}px;
  }

  ${({ open, theme }) =>
    open &&
    `
    & .${buttonClasses.startIcon} * {

      svg {
        --color-primary: ${theme.palette.brandGreen.main};
        transition: fill 0.3s ease-in-out;
      }
    }
  `}

  .${buttonClasses.startIcon} {
    margin: ${({ theme }) => theme.spacing(0, 2.5, 0, 0)};
  }

  padding: ${({ theme }) => theme.spacing(0.5, 4, 0.5, 0.5)};
`;
Wrapper.defaultProps = {
  variant: "contained",
  size: "medium"
};

const Value = styled(Typography)`
  color: ${({ theme }) => theme.palette.text.primary};
`;
Value.defaultProps = {
  variant: "buttonTertiary"
};

const TooltipWrapper = styled.div`
  align-items: center;
  display: flex;
  gap: ${({ theme }) => theme.spacing(2)};
`;

const TooltipText = styled(Typography)``;
TooltipText.defaultProps = {
  variant: "titleQuaternary",
  noWrap: true
};
