import styled from "@emotion/styled";
import NextLink from "next/link";

import IconButton from "~/components/core/IconButton/IconButton";
import { useAuthenticationContext } from "~/components/providers/AuthenticationContextProvider/AuthenticationContext";
import { AuthenticationStatus } from "~/components/providers/AuthenticationContextProvider/declarations";
import ROUTES from "~/constants/routes";

import AppLayoutHeaderActionsButtonsAuthenticated from "./AppLayoutHeaderActionsButtonsAuthenticated";
import AppLayoutHeaderActionsButtonsUnauthenticated from "./AppLayoutHeaderActionsButtonsUnauthenticated";

const AppLayoutHeaderActionsButtons = (): JSX.Element => {
  const { authenticationStatus } = useAuthenticationContext();

  return (
    <Wrapper>
      <NextLink href={ROUTES.store} passHref>
        <ShopIcon color="contrast" />
      </NextLink>
      {authenticationStatus === AuthenticationStatus.authenticated && (
        <AppLayoutHeaderActionsButtonsAuthenticated />
      )}
      {authenticationStatus === AuthenticationStatus.unauthenticated && (
        <AppLayoutHeaderActionsButtonsUnauthenticated buttonSize="medium" />
      )}
    </Wrapper>
  );
};

export default AppLayoutHeaderActionsButtons;

const Wrapper = styled.div`
  display: none;

  ${({ theme }) => theme.breakpoints.up("sm")} {
    display: flex;
  }
`;

const ShopIcon = styled(IconButton)`
  margin-right: ${({ theme }) => theme.spacing(3)};
`;
ShopIcon.defaultProps = {
  variant: "rounded",
  icon: "shop",
  size: 48
};
