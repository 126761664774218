import { css } from "@emotion/react";
import get from "lodash.get";

import { StyledMixin } from "~/declarations/styled";
import { ColorsFromPalette } from "~/theme/colorsFromPalette";

import { isNotNullable } from "./common";

type WithIconColor = {
  iconColor: ColorsFromPalette;
};

export const iconColorFromPaletteMixin: StyledMixin<WithIconColor> = ({
  theme,
  iconColor
}) => css`
  --color-primary: ${get(theme.palette, iconColor)};
  color: ${get(theme.palette, iconColor)};
`;

type WithColor = {
  textColor: ColorsFromPalette;
};

export const colorFromPaletteMixin: StyledMixin<WithColor> = ({
  theme,
  textColor
}) => css`
  color: ${get(theme.palette, textColor)};
`;

type WithBackground = {
  background: ColorsFromPalette;
};

export const backgroundFromPaletteMixin: StyledMixin<WithBackground> = ({
  theme,
  background
}) => css`
  background: ${get(theme.palette, background)};
`;

export function hexToRgba(hex: string, alpha: number): string {
  const r = parseInt(hex.slice(1, 3), 16),
    g = parseInt(hex.slice(3, 5), 16),
    b = parseInt(hex.slice(5, 7), 16);

  if (isNotNullable(alpha)) {
    return `rgba(${r},${g},${b},${alpha})`;
  } else {
    return `rgb(${r},${g},${b})`;
  }
}
