import styled from "@emotion/styled";
import { buttonClasses } from "@mui/material";
import MenuList from "@mui/material/MenuList";
import useTranslation from "next-translate/useTranslation";

import useLogout from "~/components/auth/logout/useLogout/useLogout";
import Icon from "~/components/core/Icon";
import BalanceButton from "~/components/payments/balance/BalanceButton/BalanceButton";
import CurrentPersonMenuItem from "~/components/person/CurrentPersonMenu/CurrentPersonMenuItem";
import CurrentPersonMenuTriggerWrapper from "~/components/person/CurrentPersonMenu/CurrentPersonMenuTriggerWrapper";
import { PersonMenuOption } from "~/components/person/CurrentPersonMenu/declarations";

import AppLayoutHeaderActionsMenuLinks from "./AppLayoutHeaderActionsMenuLinks";
import useAppLayoutMenu from "./useAppLayoutMenu";

type Props = {
  onClose: () => void;
};

const AppLayoutHeaderActionsMenuAuthorized = ({
  onClose
}: Props): JSX.Element => {
  const { t } = useTranslation("common");
  const logout = useLogout();
  const { items, onClickItem } = useAppLayoutMenu();

  const handleClickLogout = (): void => {
    onClose();
    logout();
  };

  const handleClickOption = (type: PersonMenuOption) => () => {
    onClose();
    onClickItem(type);
  };

  return (
    <>
      <LogOutButton onClick={handleClickLogout} />
      <Balance />
      <PersonMenuList>
        {items.map(({ type, icon, labelTranslationKey, href }) => (
          <PersonMenuListItem
            key={type}
            onClick={handleClickOption(type)}
            href={href}
            icon={icon}
            label={t(labelTranslationKey)}
          />
        ))}
      </PersonMenuList>
      <MenuLinks onClose={onClose} />
    </>
  );
};

export default AppLayoutHeaderActionsMenuAuthorized;

const MenuLinks = styled(AppLayoutHeaderActionsMenuLinks)`
  margin-top: ${({ theme }) => theme.spacing(17)};
`;

const PersonMenuList = styled(MenuList)`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(3)};
  margin-top: ${({ theme }) => theme.spacing(3)};
  padding: 0;
`;

const PersonMenuListItem = styled(CurrentPersonMenuItem)`
  min-height: unset;
  padding: 0;
  width: 100%;
`;

const Balance = styled(BalanceButton)`
  justify-content: flex-start;
  margin-top: ${({ theme }) => theme.spacing(2)};
`;

const LogOutButton = styled(CurrentPersonMenuTriggerWrapper)`
  .${buttonClasses.endIcon} {
    margin-left: auto;
  }
`;
LogOutButton.defaultProps = {
  endIcon: <Icon variant="logOut" size={24} />
};
