import styled from "@emotion/styled";
import Box from "@mui/material/Box";
import { forwardRef, MouseEvent, SVGProps, useMemo } from "react";

import { ICON_VARIANT_TO_FILE_MAP } from "~/components/core/Icon/constants";
import { safeDynamicImport } from "~/utils/import/dynamic";

import { IconColor, IconSize, IconVariant } from "./declarations";
import usePickIconColor from "./usePickIconColor";

export interface IconProps {
  variant: IconVariant;
  className?: string;
  color?: IconColor;
  colorSecondary?: IconColor;
  size?: IconSize;
  onClick?: (event: MouseEvent<SVGElement>) => void;
  onMouseDown?: (event: MouseEvent<SVGElement>) => void;
  tabIndex?: number;
}

const Icon = forwardRef<HTMLDivElement, IconProps>(
  (
    {
      variant,
      onClick,
      onMouseDown,
      color = "background.primary",
      colorSecondary = "text.primary",
      className,
      size = 24,
      tabIndex,
      ...rest
    },
    ref
  ): JSX.Element => {
    const pickColor = usePickIconColor();

    const IconComponent = useMemo(() => {
      const primaryColor = pickColor(color);
      const secondaryColor = pickColor(colorSecondary);
      const iconVariantFileName = ICON_VARIANT_TO_FILE_MAP[variant];

      const Component = safeDynamicImport<SVGProps<SVGElement>>(
        () => import(`~/public/assets/icons/${iconVariantFileName}.svg`),
        {
          loading: function LoadingPlaceholder() {
            return <Box width={size} height={size} />;
          }
        }
      );

      return styled(Component)`
        --color-primary: ${primaryColor};
        --color-secondary: ${secondaryColor};
        color: ${primaryColor};
      `;
    }, [pickColor, color, colorSecondary, variant, size]);

    return (
      <IconWrapper
        {...rest}
        tabIndex={tabIndex}
        ref={ref}
        className={className}
        clickable={Boolean(onClick)}
      >
        <IconComponent
          onClick={onClick}
          onMouseDown={onMouseDown}
          width={size}
          height={size}
        />
      </IconWrapper>
    );
  }
);

Icon.displayName = "Icon";

export default Icon;

export const IconWrapper = styled.div<{ clickable: boolean }>`
  cursor: ${({ clickable }) => clickable && "pointer"};
  line-height: 0;
`;
