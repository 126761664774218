import { Cache } from "@urql/exchange-graphcache";

import {
  DTOClaimRewardMutation,
  DTOClaimRewardMutationVariables
} from "~/components/advertisement/claim/useClaimFlow/graphql/claimRewardMutation.generated";

const claimReward = (
  result: DTOClaimRewardMutation,
  variables: DTOClaimRewardMutationVariables,
  cache: Cache
): void => {
  cache.invalidate("Query", "myNfts");
};

export default claimReward;
