import { createContext } from "react";

import createUseContext from "~/utils/createUseContext";

import { AppDialog } from "./constants";
import { DialogOptions } from "./declarations";

export type DialogWithOptions = {
  dialog: AppDialog;
  options?: DialogOptions<object>;
};

export type DialogContextValue = {
  currentDialog: DialogWithOptions | null;
  setCurrentDialog: (dialog: DialogWithOptions | null) => void;
};

export const DialogsContext = createContext<DialogContextValue | undefined>({
  currentDialog: null,
  setCurrentDialog: () => void 0
});

export const useDialogsContext = createUseContext(
  DialogsContext,
  "DialogsContext"
);
