import { CurrencyMark } from "~/constants/common";

import { Currency } from "./money";

export const CURRENCY_SIGN: {
  [key in Currency]: CurrencyMark;
} = {
  [Currency.Cleo]: CurrencyMark.Cleo,
  [Currency.Usd]: CurrencyMark.Usd
};
