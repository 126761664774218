const ROUTES = {
  index: "/",
  page404: "/404",
  error: "/_error",
  auth: {
    signup: "/signup",
    login: "/login",
    resetPassword: "/reset-password"
  },
  restricted: {
    sentry: {
      client: {
        test1: "/restricted/sentry/client/test1",
        test2: "/restricted/sentry/client/test2",
        test3: "/restricted/sentry/client/test3",
        test4: "/restricted/sentry/client/test4"
      },
      ssr: {
        test1: "/restricted/sentry/ssr/test1",
        test2: "/restricted/sentry/ssr/test2",
        test3: "/restricted/sentry/ssr/test3",
        test4: "/restricted/sentry/ssr/test4"
      },
      index: "/restricted/sentry"
    }
  },
  wallet: "/wallet",
  rewardsList: "/rewards-list",
  settings: {
    index: "/settings",
    account: "/settings/account",
    security: "/settings/security",
    profile: "/settings/profile",
    personalization: "/settings/personalization"
  },
  store: "/store",
  landing: {
    Cleo: "/landing/Cleo"
  },
  items: {
    index: "/items",
    instance: "/items/[id]"
  },
  collections: {
    index: "/collections",
    instance: "/collections/[id]",
    create: "/collections/create"
  }
};

module.exports = ROUTES;
