import { CURRENCY_SIGN } from "~/components/payments/constants/currency";
import { Currency } from "~/components/payments/constants/money";
import {
  BREAK_SPACE_CHARACTER,
  NO_BREAK_SPACE_CHARACTER
} from "~/constants/string";

const MAX_CRYPTO_FRACTION_DIGITS = 6;
const CRYPTO_CURRENCY_FORMAT: Intl.NumberFormatOptions["currency"] = "USD";
const CRYPTO_LOCALE_FORMAT = "en-US";

const CRYPTO_FORMATTER = new Intl.NumberFormat(CRYPTO_LOCALE_FORMAT, {
  style: "decimal",
  currency: CRYPTO_CURRENCY_FORMAT,
  maximumFractionDigits: MAX_CRYPTO_FRACTION_DIGITS
});

const FIAT_CURRENCY_FORMAT: Intl.NumberFormatOptions["currency"] = "USD";
const MAX_FIAT_FRACTION_DIGITS = 2;

const FIAT_FORMATTER = new Intl.NumberFormat("en-US", {
  style: "decimal",
  currency: FIAT_CURRENCY_FORMAT,
  maximumFractionDigits: MAX_FIAT_FRACTION_DIGITS
});

const FORMATTER_BY_CURRENCY: Record<Currency, Intl.NumberFormat> = {
  [Currency.Cleo]: CRYPTO_FORMATTER,
  [Currency.Usd]: FIAT_FORMATTER
};

const MAX_FRACTION_DIGITS_BY_CURRENCY: Record<Currency, number> = {
  [Currency.Cleo]: MAX_CRYPTO_FRACTION_DIGITS,
  [Currency.Usd]: MAX_FIAT_FRACTION_DIGITS
};

export type FormatMoneyOptions = {
  amount: number;
  currency: Currency;
  currencySignAsPrefix: boolean;
  joint?: boolean;
  approximate?: boolean;
  omitCurrencySign?: boolean;
  useSimpleSpaceDivider?: boolean;
};

const APPROXIMATE_SIGN = "≈";

const formatMoney = ({
  amount,
  currency,
  currencySignAsPrefix,
  omitCurrencySign = false,
  joint = false,
  approximate = false,
  useSimpleSpaceDivider = false
}: FormatMoneyOptions): string => {
  const maximumFractionDigits = MAX_FRACTION_DIGITS_BY_CURRENCY[currency];
  const shiftToMakeInteger = 10 ** maximumFractionDigits;
  const numberWithCutFractionDigits =
    Math.trunc(amount * shiftToMakeInteger) / shiftToMakeInteger;
  const numberToFormat = Number(numberWithCutFractionDigits);
  const formatter = FORMATTER_BY_CURRENCY[currency];
  const formattedValue = formatter.format(numberToFormat);
  const sign = CURRENCY_SIGN[currency];
  const parts = [
    formattedValue,
    getDivider(joint, useSimpleSpaceDivider),
    omitCurrencySign ? null : sign
  ];
  const partsInRightDirection = currencySignAsPrefix ? parts.reverse() : parts;

  if (approximate) {
    partsInRightDirection.unshift(APPROXIMATE_SIGN);
  }

  return partsInRightDirection.filter(Boolean).join("");
};

export default formatMoney;

const getDivider = (
  joint: boolean,
  useSimpleSpaceDivider: boolean
): string | null => {
  if (joint) {
    return null;
  }

  return useSimpleSpaceDivider
    ? BREAK_SPACE_CHARACTER
    : NO_BREAK_SPACE_CHARACTER;
};
