/* eslint-disable cleo/no-deep-imports */
import gql from "graphql-tag";
import * as Urql from "urql";

import * as Types from "../../../../../declarations/graphql/types";

export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type DTOLogoutMutationVariables = Types.Exact<{ [key: string]: never }>;

export type DTOLogoutMutation = {
  __typename?: "Mutation";
  logout?: boolean | null;
};

export const LogoutMutationDocument = gql`
  mutation LogoutMutation {
    logout
  }
`;

export function useLogoutMutation() {
  return Urql.useMutation<DTOLogoutMutation, DTOLogoutMutationVariables>(
    LogoutMutationDocument
  );
}
