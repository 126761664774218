export const DATE_FORMAT = "DD/MM/YYYY";
export const DATE_FORMAT_DOTTED = "DD.MM.YYYY";
export const DATE_FORMAT_MASK = "__.__.____";
export const DATE_FORMAT_REQUEST = "YYYY-MM-DD";

export const GUID_REGEXP_BASE =
  "[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}";
export const GUID_REGEXP = new RegExp(`^${GUID_REGEXP_BASE}$`, "i");

export const ELLIPSIS_STUB = "...";
export const COMMA_SPACED_SEPARATOR = ", ";
export const POINT_SEPARATOR = ".";
export const DASH_SEPARATOR = "-";
export const COLON_SYMBOL = ":";

export const IOS_DEVICE_REGEX = /iPad|iPhone|iPod/;

export const USER_NICKNAME_PREFIX = "@";

export const COPYRIGHT_SYMBOL = "©";

export const BULLET_POINT_SYMBOL = "•";

export const HASH_TAG_SYMBOL = "#";

export const PERCENTAGE_SYMBOL = "%";

export const MAX_PERCENTAGE = 100;

export enum CurrencyMark {
  Usd = "$",
  Cleo = "CLEO"
}

export const BYTE_DEGREE_STEP_BIN = 1024;
export const BYTE_DEGREE_STEP_DEC = 1000;

export const THEME_MODE_COOKIE = "theme-mode";
