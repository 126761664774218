import {
  OptionsObject,
  SnackbarKey,
  SnackbarMessage,
  useSnackbar as useNotistackSnackbar,
  VariantType
} from "notistack";
import { ReactNode, useCallback } from "react";

export enum ButtonVariant {
  button,
  link
}

export type CustomSnackbarAction = {
  text: ReactNode;
  onClick: () => void;
  variant?: ButtonVariant;
};

export type ExtraSnackbarOptions = {
  description?: SnackbarMessage;
  actionVariant?: CustomSnackbarAction;
  paperClassName?: string;
};

export type MessageConfig = {
  title: SnackbarMessage;
  variant?: VariantType;
} & ExtraSnackbarOptions;

export type CustomSnackbarOptions = OptionsObject & ExtraSnackbarOptions;

export interface CustomSnackbarContext {
  enqueueSnackbar: (
    config: SnackbarMessage,
    options?: CustomSnackbarOptions
  ) => SnackbarKey;
  closeSnackbar: (key?: SnackbarKey) => void;
}

const useSnackbar = (): CustomSnackbarContext => {
  const { enqueueSnackbar, ...otherProps } = useNotistackSnackbar();

  const enqueue = useCallback(
    (title: SnackbarMessage, options?: CustomSnackbarOptions): SnackbarKey => {
      const config: MessageConfig = {
        title,
        description: options?.description,
        actionVariant: options?.actionVariant,
        variant: options?.variant,
        paperClassName: options?.paperClassName
      };

      return enqueueSnackbar(config, options);
    },
    [enqueueSnackbar]
  );

  return { enqueueSnackbar: enqueue, ...otherProps };
};

export default useSnackbar;
