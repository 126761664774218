/* eslint-disable cleo/no-deep-imports */
import gql from "graphql-tag";

import * as Types from "../declarations/graphql/types";

export type DTORichTextFragment = {
  __typename?: "RichText";
  text: string;
  parts: Array<
    | { __typename: "HashtagPart"; hashtag: string; text: string }
    | { __typename: "MentionPart"; id: string; nickname: string; text: string }
    | { __typename: "PlainPart"; text: string }
    | {
        __typename: "UrlPart";
        path: string;
        urlType: Types.DTOUrlPartType;
        text: string;
      }
  >;
};

export const RichTextFragmentDoc = gql`
  fragment RichTextFragment on RichText {
    parts {
      __typename
      ... on PlainPart {
        text
      }
      ... on MentionPart {
        id
        nickname
        text
      }
      ... on UrlPart {
        path
        urlType
        text
      }
      ... on HashtagPart {
        hashtag
        text
      }
    }
    text
  }
`;
