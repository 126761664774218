import { ReactNode, useCallback, useState } from "react";

import { AuthenticationContext } from "~/components/providers/AuthenticationContextProvider/AuthenticationContext";
import { AuthenticationStatus } from "~/components/providers/AuthenticationContextProvider/declarations";
import AuthService from "~/services/AuthService";
import { isServerSide } from "~/utils/common";

import { useUrqlContext } from "../UrqlProvider/UrqlContext";

type Props = {
  authorized?: boolean;
  children: ReactNode;
};

const AuthenticationContextProvider = ({
  children,
  authorized
}: Props): JSX.Element => {
  const { recreateClient } = useUrqlContext();
  const [status, setStatus] = useState<AuthenticationStatus>(() => {
    if (isServerSide() && authorized === undefined) {
      return AuthenticationStatus.notFetched;
    }

    return authorized ?? AuthService.isAuthorizedClient()
      ? AuthenticationStatus.authenticated
      : AuthenticationStatus.unauthenticated;
  });

  const setAuthenticated = useCallback(
    (accessToken: string): void => {
      AuthService.setAccessToken(accessToken);
      AuthService.setLoginMark();
      recreateClient();
      setStatus(AuthenticationStatus.authenticated);
    },
    [recreateClient]
  );

  return (
    <AuthenticationContext.Provider
      value={{
        authenticationStatus: status,
        setAuthenticated
      }}
    >
      {children}
    </AuthenticationContext.Provider>
  );
};

export default AuthenticationContextProvider;
