/* eslint-disable cleo/no-deep-imports */
import gql from "graphql-tag";

import * as Types from "../declarations/graphql/types";

export type DTOMoneyFragment = {
  __typename?: "Money";
  cleoValue: number;
  fiatValue: number;
  rateUpdatedAt: number;
};

export const MoneyFragmentDoc = gql`
  fragment MoneyFragment on Money {
    cleoValue
    fiatValue
    rateUpdatedAt
  }
`;
