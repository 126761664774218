export const ICON_VARIANT_TO_FILE_MAP = {
  flagGB: "flags/GB",
  flagJP: "flags/JP",
  flagSP: "flags/SP",
  flagTH: "flags/TH",
  flagUA: "flags/UA",

  budget: "other/Budget",
  calendarBicolor: "other/CalendarBicolor",
  category: "other/CategoryBicolor",
  people: "other/People",
  person: "other/Person",
  personWithEye: "other/PersonWithEye",
  email: "other/Email",
  eye: "other/Eye",
  eyeClosed: "other/EyeClosed",
  lock: "other/Lock",
  logOut: "other/LogOut",
  mail: "other/Mail",
  myNFT: "other/MyNFT",
  reward: "other/Reward",
  settings: "other/Settings",
  share: "other/Share",
  shop: "other/Shop",
  star: "other/Star",
  starFourPointed: "other/StarFourPointed",
  shield: "other/Shield",
  key: "other/Key",
  wallet: "other/Wallet",
  walletBicolor: "other/WalletBicolor",
  location: "other/Location",
  send: "other/Send",
  "3dcube": "other/3dcube",
  speedometer: "other/Speedometer",
  stopwatch: "other/Stopwatch",
  chart: "other/Chart",
  profile: "other/Profile",
  account: "other/Account",
  security: "other/Security",
  personalizationBicolor: "other/PersonalizationBicolor",
  edit: "other/Edit",
  business: "other/Business",
  job: "other/Job",
  bonus: "other/Bonus",
  delete: "other/Delete",
  userWithEye: "other/UserWithEye",
  sort: "other/Sort",
  document: "other/Document",
  goblet: "other/Goblet",
  price: "other/Price",
  textBicolor: "other/TextBicolor",
  rocketBicolor: "other/RocketBicolor",
  collections: "other/Collections",

  discordColorful: "socials/colorful/Discord",
  facebookColorful: "socials/colorful/Facebook",
  googleColorful: "socials/colorful/Google",
  linkedinColorful: "socials/colorful/Linkedin",
  linkedinSquareColorful: "socials/colorful/LinkedinSquare",
  metamaskColorful: "socials/colorful/Metamask",
  telegramColorful: "socials/colorful/Telegram",
  telegramRoundedColorful: "socials/colorful/TelegramRounded",
  twitterColorful: "socials/colorful/Twitter",
  viberColorful: "socials/colorful/Viber",
  walletConnectColorful: "socials/colorful/WalletConnect",
  whatsappConnectColorful: "socials/colorful/Whatsapp",

  apple: "socials/monochrome/Apple",
  discord: "socials/monochrome/Discord",
  facebook: "socials/monochrome/Facebook",
  instagram: "socials/monochrome/Instagram",
  linkedIn: "socials/monochrome/LinkedIn",
  twitter: "socials/monochrome/Twitter",
  youtube: "socials/monochrome/Youtube",

  arrowDown: "system/arrow/ArrowDown",
  arrowLeft: "system/arrow/ArrowLeft",
  arrowRight: "system/arrow/ArrowRight",
  arrowUp: "system/arrow/ArrowUp",

  chevronDown: "system/chevron/ChevronDown",
  chevronUp: "system/chevron/ChevronUp",
  chevronRight: "system/chevron/ChevronRight",
  chevronLeft: "system/chevron/ChevronLeft",

  back: "system/other/Back",
  check: "system/other/Check",
  clear: "system/other/Clear",
  close: "system/other/Close",
  copy: "system/other/Copy",
  danger: "system/other/Danger",
  info: "system/other/Info",
  maximizeArrows: "system/other/MaximizeArrows",
  menu: "system/other/Menu",
  menuMinimized: "system/other/MenuMinimized",
  more: "system/other/More",
  placeholder: "system/other/Placeholder",
  search: "system/other/Search",
  success: "system/other/Success",
  minus: "system/other/Minus",
  plus: "system/other/Plus",
  pending: "system/other/Pending",

  currenciesCleo: "currencies/Cleo",

  volume: "player/Volume",
  volumeOff: "player/VolumeOff",
  maximize: "player/Maximize",
  minimize: "player/Minimize",
  play: "player/Play",
  pause: "player/Pause",
  backwardFive: "player/BackwardFive",
  forwardFive: "player/ForwardFive"
};
