import { useMemo } from "react";

import { PERSON_MENU_OPTIONS } from "~/components/person/CurrentPersonMenu/constants";
import {
  PersonMenuOption,
  PersonMenuOptionDescription,
  UserRole
} from "~/components/person/CurrentPersonMenu/declarations";
import { AppDialog } from "~/components/providers/DialogsProvider/constants";
import { useAppDialog } from "~/components/providers/DialogsProvider/useAppDialog";
import { RequestState } from "~/constants/requestState";
import useMe from "~/utils/graphql/useMe";

const ENABLE_COLLECTION_CREATION =
  process.env.NEXT_PUBLIC_ENABLE_COLLECTION_CREATION == "true";

type UseAppLayoutMenuResult = {
  items: PersonMenuOptionDescription[];
  onClickItem: (action: PersonMenuOption) => void;
};

export default function useAppLayoutMenu(): UseAppLayoutMenuResult {
  const me = useMe();
  const { open: openWalletDialog } = useAppDialog(AppDialog.WalletInfo);

  const items = useMemo(
    () =>
      PERSON_MENU_OPTIONS.reduce(
        (
          result: PersonMenuOptionDescription[],
          item
        ): PersonMenuOptionDescription[] => {
          const iAmCreator =
            me.type === RequestState.Success && me.data.isCreator;
          const iAmAdmin = me.type === RequestState.Success && me.data.isAdmin;
          const iAmCasual = !iAmCreator && !iAmAdmin;
          const roles = item?.roles;

          if (
            item.type == PersonMenuOption.myCollections &&
            !ENABLE_COLLECTION_CREATION
          ) {
            return result;
          }

          if (roles?.length) {
            if (
              (iAmAdmin && roles.includes(UserRole.admin)) ||
              (iAmCreator && roles.includes(UserRole.creator)) ||
              (iAmCasual && roles.includes(UserRole.casual))
            ) {
              result.push(item);
            }
          } else {
            result.push(item);
          }

          return result;
        },
        []
      ),
    [me]
  );

  const handleClickItem = (type: PersonMenuOption): void => {
    switch (type) {
      case PersonMenuOption.wallet:
        openWalletDialog();
    }
  };

  return {
    items,
    onClickItem: handleClickItem
  };
}
