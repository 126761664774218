import styled from "@emotion/styled";
import Typography from "@mui/material/Typography";

import Logo from "~/components/common/Logo/Logo";
import { isNotNullable } from "~/utils/common";

const JOIN_SIGN = "+";

const AppLayoutHeaderLogo = (): JSX.Element => {
  /* TODO: add campaign logo if necessary */
  const companyLogo = null;

  return (
    <Wrapper>
      <ResponsiveLogo size="l" />
      {isNotNullable(companyLogo) && (
        <>
          <Typography variant="buttonTertiary">{JOIN_SIGN}</Typography>
          {companyLogo}
        </>
      )}
    </Wrapper>
  );
};

export default AppLayoutHeaderLogo;

const Wrapper = styled.div`
  align-items: center;
  display: flex;
  gap: ${({ theme }) => theme.spacing(2)};
  min-width: 0;
`;

const ResponsiveLogo = styled(Logo)`
  min-width: 0;
`;
