/* eslint-disable cleo/no-deep-imports */
import gql from "graphql-tag";

import * as Types from "../declarations/graphql/types";

export type DTOAvatarFragment = {
  __typename?: "Avatar";
  little: string;
  little2x: string;
  middle: string;
  middle2x: string;
  big: string;
  big2x: string;
  blurhash: string;
};

export const AvatarFragmentDoc = gql`
  fragment AvatarFragment on Avatar {
    little
    little2x
    middle
    middle2x
    big
    big2x
    blurhash
  }
`;
