/* eslint-disable cleo/no-deep-imports */
import gql from "graphql-tag";

import * as Types from "../../../../declarations/graphql/types";
import {
  AttachmentImageFragmentDoc,
  DTOAttachmentImageFragment
} from "./attachmentImageFragment.generated";

export type DTOAttachmentPdfFragment = {
  __typename: "AttachmentPdf";
  id: string;
  publicUrl: string;
  fileSize: number;
  cover?:
    | ({ __typename?: "AttachmentImage" } & DTOAttachmentImageFragment)
    | null;
};

export const AttachmentPdfFragmentDoc = gql`
  fragment AttachmentPdfFragment on AttachmentPdf {
    __typename
    id
    publicUrl
    fileSize
    cover {
      ...AttachmentImageFragment
    }
  }
`;
