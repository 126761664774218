import { css } from "@emotion/css";
import { useTheme } from "@emotion/react";
import {
  SnackbarProvider as NotistackSnackbarProvider,
  SnackbarProviderProps
} from "notistack";

import SnackbarMessage from "~/components/core/SnackbarMessage";
import { CustomTheme } from "~/declarations/theme";

import { MessageConfig } from "./useSnackbar";

export type Props = {
  children: React.ReactNode;
};

const MAX_SNACK_PER_PAGE = 2;

const getClassNames = (
  theme: CustomTheme
): SnackbarProviderProps["classes"] => ({
  containerRoot: css`
    ${theme.breakpoints.down("sm")} {
      align-items: center;
      width: 100%;
    }
  `
});

const SnackbarProvider = ({ children }: Props): JSX.Element => {
  const theme = useTheme();

  return (
    <NotistackSnackbarProvider
      maxSnack={MAX_SNACK_PER_PAGE}
      content={(key, config: MessageConfig) => (
        <SnackbarMessage id={key} {...config} />
      )}
      anchorOrigin={{
        horizontal: "center",
        vertical: "bottom"
      }}
      classes={getClassNames(theme)}
    >
      {children}
    </NotistackSnackbarProvider>
  );
};

export default SnackbarProvider;
