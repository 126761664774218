/* eslint-disable cleo/no-deep-imports */
import gql from "graphql-tag";

import * as Types from "../../../../declarations/graphql/types";

export type DTONftRewardFragment = {
  __typename?: "NftPostReward";
  nft: { __typename: "Nft"; id: number; name: string };
};

export const NftRewardFragmentDoc = gql`
  fragment NftRewardFragment on NftPostReward {
    nft {
      __typename
      id
      name
    }
  }
`;
